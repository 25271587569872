import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useTindakanLanjutSettings } from "hooks/setting/general/tindakanLanjut/useTindakanLanjuts";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { statusLawatanOps } = useOptions();
  const { tindakanLanjuts, isLoading } = useTindakanLanjutSettings();

  const [searchValues, setSearchValues] = useState({
    nama_usahawan: searchParams.get("nama_usahawan") || "",
    no_kad_pengenalan: searchParams.get("no_kad_pengenalan") || "",
    status: searchParams.get("status") || "",
    tarikh_lawatan_from: searchParams.get("tarikh_lawatan_from") || "",
    tarikh_lawatan_end: searchParams.get("tarikh_lawatan_end") || "",
    tindakan_lanjut: searchParams.get("tindakan_lanjut") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama_usahawan: "",
      no_kad_pengenalan: "",
      status: "",
      tarikh_lawatan_from: "",
      tarikh_lawatan_end: "",
      tindakan_lanjut: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Usahawan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_usahawan}
            onChange={(e: any) => handleOnTextChange(e, "nama_usahawan")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No. Kad Pengenalan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.no_kad_pengenalan}
            onChange={(e: any) => handleOnTextChange(e, "no_kad_pengenalan")}
          />
        </FormControl>

        <SelectField
          label={"Status"}
          value={searchValues.status}
          options={statusLawatanOps}
          onChange={(e: any) => handleOnChange(e, "status")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Lawatan Mula
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_lawatan_from}
            onChange={(e: any) => handleOnTextChange(e, "tarikh_lawatan_from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Lawatan Akhir
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_lawatan_end}
            onChange={(e: any) => handleOnTextChange(e, "tarikh_lawatan_end")}
          />
        </FormControl>

        <SelectField
          label={"Tindakan Lanjut"}
          value={searchValues.tindakan_lanjut}
          options={tindakanLanjuts?.data?.items}
          onChange={(e: any) => handleOnChange(e, "tindakan_lanjut")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
