import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getNotification } from "services/setting/notifikasiService";

export function useNotification() {
  const { notificationId } = useParams<{ notificationId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: notifikasi,
    error,
  } = useQuery({
    queryKey: ["notifikasi", notificationId],
    queryFn: () => getNotification(notificationId),
    enabled: !!notificationId,
  });

  return { isLoading, isInitialLoading, error, notifikasi };
}