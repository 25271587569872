import React from "react";
import FormBuletin from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useBuletin } from "hooks/admin/buletin/useBuletin";

export default function ShowBuletin() {
  const { buletin, isLoading, isInitialLoading } = useBuletin();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormBuletin action="show" initialData={buletin?.data} />;
}
