import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getCatalogue } from "services/admin/catalogue/catalogueService";

export function useCatalogue() {
  const { katalogId } = useParams<{ katalogId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: catalogue,
    error,
  } = useQuery({
    queryKey: ["catalogue", katalogId],
    queryFn: () => getCatalogue(katalogId),
    enabled: !!katalogId,
  });

  return { isLoading, isInitialLoading, error, catalogue };
}

