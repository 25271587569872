import axios from "utils/axiosInstance";
import { ICatalogueFormValues } from "entities/admin/catalogue";

export async function getCatalogue(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/katalog-produk/show/${id}`,
  });
  return data;
}

export async function getCatalogues(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/katalog-produk",
    params,
  });
  return data;
}

export async function createEditCatalogue(
  values: ICatalogueFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/katalog-produk/update` : "/katalog-produk";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteCatalogue(
  values: ICatalogueFormValues,
  id: string | number | null
) {
  const apiUrl = `/katalog-produk/delete`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function approveCatalogue(
  values: ICatalogueFormValues,
  id: string | number | null
) {
  const apiUrl = `/katalog-produk/approve`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function getPublicCatalogues(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/katalog-produk/active",
    params,
    public: true,
  });
  return data;
}