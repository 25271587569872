import React from 'react';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Text } from '@chakra-ui/react';

interface BreadcrumbComponentProps {
  items: {
    label: string;
    href?: string;
  }[];
  mb?: string;  
}

const BreadcrumbComponent = ({ items, mb }: BreadcrumbComponentProps) => {
  return (
    <Breadcrumb color={"brand.500"} mb={mb} spacing="8px" separator=">">
      {items.map((item, index) => (
        <BreadcrumbItem key={`${item.label}-${index}`} isCurrentPage={index === items.length - 1}>
          <Text fontSize={"sm"}>{item.label}</Text>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default BreadcrumbComponent;