import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { jenisLatihanOps } = useOptions();
  const { negeris, isLoading } = useNegeriSettings({
    is_restricted: 0,
  });

  const [searchValues, setSearchValues] = useState({
    nama_kursus: searchParams.get("nama_kursus") || "",
    nama_usahawan: searchParams.get("nama_usahawan") || "",
    penganjur: searchParams.get("penganjur") || "",
    negeri_id: searchParams.get("negeri_id") || "",
    tarikh_mula_from: searchParams.get("tarikh_mula_from") || "",
    tarikh_mula_end: searchParams.get("tarikh_mula_end") || "",
    is_elatihan: searchParams.get("is_elatihan") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama_kursus: "",
      nama_usahawan: "",
      penganjur: "",
      negeri_id: "",
      tarikh_mula_from: "",
      tarikh_mula_end: "",
      is_elatihan: "",
    });

    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Kursus
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_kursus}
            onChange={(e: any) => handleOnTextChange(e, "nama_kursus")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Usahawan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_usahawan}
            onChange={(e: any) => handleOnTextChange(e, "nama_usahawan")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Penganjur
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.penganjur}
            onChange={(e: any) => handleOnTextChange(e, "penganjur")}
          />
        </FormControl>

        <SelectField
          label={"Negeri Tempat Kursus"}
          value={searchValues.negeri_id}
          options={negeris?.data?.items}
          onChange={(e: any) => handleOnChange(e, "negeri_id")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Mula
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_mula_from}
            onChange={(e: any) => handleOnTextChange(e, "tarikh_mula_from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Tamat
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_mula_end}
            onChange={(e: any) => handleOnTextChange(e, "tarikh_mula_end")}
          />
        </FormControl>

        <SelectField
          label={"E-Latihan/ Bukan E-Latihan"}
          value={searchValues.is_elatihan}
          options={jenisLatihanOps}
          onChange={(e: any) => handleOnChange(e, "is_elatihan")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
