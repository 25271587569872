import axios from "utils/axiosInstance";
import { IUserFormValues } from "entities/setting/user";

export async function getCurrentUser() {
  const { data } = await axios.call({
    method: "get",
    url: `/user/self`,
  });
  return data;
}

export async function getUser(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/user/show/${id}`,
  });
  return data;
}

export async function getUsers(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/user/v2",
    params,
  });
  return data;
}

export async function createEditUser(
  values: IUserFormValues,
  id: string | null
) {
  const apiUrl = id ? `/user/update` : "/user";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}