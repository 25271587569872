import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getBuletinActive } from "services/admin/buletin/buletinService";

export function useBuletinActive(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit") ? 10 : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: buletinActive,
    error,
  } = useQuery({
    queryKey: ["buletinActive", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getBuletinActive({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
  });

  return { isLoading, error, buletinActive };
}