import React from "react";
import FormAgamaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";

export default function EditAgamaSetting() {
  if (false) {
    return <FullScreenLoader />;
  }

  return <FormAgamaSetting action="edit" initialData={null} />;
}
