import axios from "utils/axiosInstance";
import { ICourseFormValues } from "entities/admin/kursus";

export async function getCourse(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/kursus/show/${id}`,
  });
  return data;
}

export async function getCourses(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/kursus",
    params,
  });
  return data;
}

export async function createEditCourse(
  values: ICourseFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/kursus/update` : "/kursus";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteCourse(
  values: ICourseFormValues,
  id: string | number | null
) {
  const apiUrl = `/kursus/delete`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}
