import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

import { IUserFormValues } from "entities/setting/user";
import { useCreateUser } from "hooks/setting/user/useCreateUser";
import { useEditUser } from "hooks/setting/user/useEditUser";

interface FormUserProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormUser: React.FC<FormUserProps> = ({ action, initialData }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { createUser, isCreating } = useCreateUser();
  const { editUser, isEditing } = useEditUser();

  const history = useHistory();

  const { pusatTanggungjawabOps, negeriOps, daerahOps, roleOps } = useOptions();

  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      pusat_tanggungjawap_id: yup.number().notRequired(),
      nickname: yup.string().notRequired(),
      nric: yup.string().required(),
      phone_no: yup.string().notRequired(),
      position: yup.string().notRequired(),
      is_active: yup.number().required(),
      role_id: yup.number().required(),
      department_id: yup.string().notRequired(),
      password:
        action === "create"
          ? yup.string().required()
          : yup.string().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUserFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createUser({
        newUser: data,
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editUser({
        newUser: data,
        id: `${data.id}`,
      });
    }
  });

  if (false) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Pengguna">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="name"
                  label="Nama Pengguna"
                  placeholder="Ramli Saad"
                  isInvalid={!!errors.name}
                  errorMessage={errors.name?.message}
                  readOnly={action !== "create"}
                  autoComplete="nope"
                  {...register("name")}
                />

                <TextField
                  id="nickname"
                  label="Nama Panggilan"
                  isInvalid={!!errors.nickname}
                  errorMessage={errors.nickname?.message}
                  readOnly={action === "show"}
                  {...register("nickname")}
                />

                <TextField
                  id="email"
                  label="Emel"
                  isInvalid={!!errors.email}
                  errorMessage={errors.email?.message}
                  readOnly={action !== "create"}
                  autoComplete="nope"
                  {...register("email")}
                />

                <TextField
                  id="nric"
                  label="No. Kad Pengenalan"
                  isInvalid={!!errors.nric}
                  errorMessage={errors.nric?.message}
                  readOnly={action !== "create"}
                  {...register("nric")}
                />

                {action === "create" && (
                  <TextField
                    id="phone_no"
                    label="No. Telefon"
                    isInvalid={!!errors.phone_no}
                    errorMessage={errors.phone_no?.message}
                    {...register("phone_no")}
                  />
                )}

                <TextField
                  id="position"
                  label="Jawatan"
                  isInvalid={!!errors.position}
                  errorMessage={errors.position?.message}
                  readOnly={action === "show"}
                  {...register("position")}
                />

                <SelectField
                  label={"Bahagian"}
                  options={[]}
                  isInvalid={!!errors.department_id}
                  errorMessage={errors.department_id?.message}
                  readOnly={action === "show"}
                  {...register("department_id")}
                />

                {action === "create" && (
                  <SelectField
                    label={"Pusat Tanggungjawab"}
                    options={pusatTanggungjawabOps}
                    isInvalid={!!errors.pusat_tanggungjawap_id}
                    errorMessage={errors.pusat_tanggungjawap_id?.message}
                    {...register("pusat_tanggungjawap_id")}
                  />
                )}

                <SelectField
                  label={"Status"}
                  options={[
                    {
                      value: "0",
                      label: "Aktif",
                    },
                    {
                      value: "1",
                      label: "Tidak Aktif",
                    },
                  ]}
                  isInvalid={!!errors.is_active}
                  errorMessage={errors.is_active?.message}
                  readOnly={action === "show"}
                  {...register("is_active")}
                />

                {action !== "create" && (
                  <>
                    <SelectField
                      label={"Status Espek"}
                      options={[
                        {
                          value: "0",
                          label: "Aktif",
                        },
                        {
                          value: "1",
                          label: "Tidak Aktif",
                        },
                      ]}
                      value={initialData?.profile?.status_espek}
                      readOnly
                    />

                    <SelectField
                      label={"Negeri Espek"}
                      options={negeriOps}
                      value={initialData?.profile?.id_negeri_espek}
                      readOnly
                    />

                    <SelectField
                      label={"Pusat Tanggungjawapan Espek"}
                      options={pusatTanggungjawabOps}
                      value={initialData?.profile?.id_pt_espek}
                      readOnly
                    />

                    <SelectField
                      label={"Daerah Espek"}
                      options={daerahOps}
                      value={initialData?.profile?.id_daerah_espek}
                      readOnly
                    />
                  </>
                )}

                <SelectField
                  label={"Peranan"}
                  options={roleOps}
                  isInvalid={!!errors.role_id}
                  errorMessage={errors.role_id?.message}
                  readOnly={action === "show"}
                  {...register("role_id")}
                />
              </SimpleGrid>
            </Section>

            {action === "create" && (
              <Section title="Maklumat Kata Laluan">
                <SimpleGrid
                  columns={{ base: 2 }}
                  gap={{ sm: "6px", md: "18px" }}
                  mb={10}
                >
                  <TextField
                    id="password"
                    label="Kata Laluan"
                    placeholder="Min. 5 characters"
                    type="password"
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    isInvalid={!!errors.password}
                    errorMessage={errors.password?.message}
                    autoComplete="new-password"
                    {...register("password")}
                  />

                  <FormControl>
                    <FormLabel
                      variant="main"
                      mb="8px"
                      htmlFor={"password_confirm"}
                    >
                      Pengesahan Kata Laluan
                    </FormLabel>

                    <InputGroup size="md">
                      <Input
                        id={"password_confirm"}
                        variant={"auth"}
                        fontSize={"sm"}
                        ms={{ base: "0px", md: "0px" }}
                        type={"text"}
                        placeholder={"Sahkan Kata Laluan"}
                        fontWeight={"500"}
                        size={"md"}
                      />
                    </InputGroup>

                    {false && (
                      <FormErrorMessage>
                        Kata laluan tidak sama
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </SimpleGrid>
              </Section>
            )}

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/users");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Pengguna"
                    : "Daftar Pengguna Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormUser;
