import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getProjekBpks } from "services/admin/insentif/bpk/projekBpkService";

export function useProjekBpks(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit") ? 10 : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: projekBpks,
    error,
  } = useQuery({
    queryKey: ["projekBpks", currentPage, currentLimit, currentSearchParams],
    queryFn: () => getProjekBpks({
      ...params,
      ...currentSearchParams,
      page: currentPage,
      itemsPerPage: currentLimit,
    }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, projekBpks };
}
