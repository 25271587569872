import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

interface FormProjekBpkProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormProjekBpk: React.FC<FormProjekBpkProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();
  const { negeriOps, pusatTanggungjawabOps, daerahOps } = useOptions();

  const schema = yup
    .object({
      nama: yup.string().notRequired(),
    })
    .required();

  const {
    register,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  if (false) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form autoComplete="off">
            <Section title="Maklumat Pengguna">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Nama"
                  readOnly
                  {...register("nama")}
                />

                <TextField
                  id="no_kad_pengenalan"
                  label="No. Kad Pengenalan"
                  readOnly
                  {...register("no_kad_pengenalan")}
                />

                <TextField
                  id="emel"
                  label="Emel"
                  readOnly
                  {...register("emel")}
                />

                <TextField
                  id="no_telefon"
                  label="No. Telefon"
                  readOnly
                  {...register("no_telefon")}
                />

                <SelectField
                  label={"Jenis Bantuan"}
                  options={[]}
                  readOnly
                  {...register("jenis_bantuan")}
                />

                <SelectField
                  label={"Kategori Projek"}
                  options={[]}
                  readOnly
                  {...register("kategori_projek")}
                />

                <SelectField
                  label={"Sub Kategori"}
                  options={[]}
                  readOnly
                  {...register("sub_kategori")}
                />

                <SelectField
                  label={"Nama Projek"}
                  options={[]}
                  readOnly
                  {...register("nama_projek")}
                />

                <TextField
                  id="teknologi_projek"
                  label="Teknologi Projek"
                  readOnly
                  {...register("teknologi_projek")}
                />

                <TextField
                  id="jenis_projek"
                  label="Jenis Projek"
                  readOnly
                  {...register("jenis_projek")}
                />

                <TextField
                  id="kategori_pelaksana"
                  label="Kategori Pelaksana"
                  readOnly
                  {...register("kategori_pelaksana")}
                />

                <TextField
                  id="kaedah_pelaksana"
                  label="Kaedah Pelaksana"
                  readOnly
                  {...register("kaedah_pelaksana")}
                />

                <TextField
                  id="kumpulan_pelaksana"
                  label="Kumpulan Pelaksana"
                  readOnly
                  {...register("kumpulan_pelaksana")}
                />

                <SelectField
                  label={"Negeri Kebenaran"}
                  options={negeriOps}
                  readOnly
                  {...register("negeri_kebenaran")}
                />

                <SelectField
                  label={"PT Kebenaran"}
                  options={pusatTanggungjawabOps}
                  readOnly
                  {...register("pt_kebenaran")}
                />

                <SelectField
                  label={"Daerah Kebenaran"}
                  options={daerahOps}
                  readOnly
                  {...register("daerah_kebenaran")}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.goBack();
                }}
              >
                Kembali
              </Button>
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormProjekBpk;
