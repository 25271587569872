import axios from "utils/axiosInstance";
import { IAktivitiFormValues } from "entities/admin/aktiviti";

export async function getAktiviti(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/aktiviti/show/${id}`,
  });
  return data;
}

export async function getAktivitis(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/aktiviti",
    params,
  });
  return data;
}

export async function createEditAktiviti(
  values: IAktivitiFormValues,
  id?: string | number | null
) {
  const apiUrl = id ? `/aktiviti/update` : "/aktiviti";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteAktiviti(
  values: IAktivitiFormValues,
  id: string | number | null
) {
  const apiUrl = `/aktiviti/delete`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}
