import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getInsentifBPU } from "services/admin/insentif/bpu/insentifBPUService";

export function useInsentifBPU() {
  const { insentifBPUId } = useParams<{ insentifBPUId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: insentifBPU,
    error,
  } = useQuery({
    queryKey: ["insentifBPU", insentifBPUId],
    queryFn: () => getInsentifBPU(insentifBPUId),
    enabled: !!insentifBPUId,
  });

  return { isLoading, isInitialLoading, error, insentifBPU };
}

