import React, { ChangeEvent } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Flex, Text, Select } from "@chakra-ui/react";
import { PAGE_SIZE } from "utils/constants";

interface ResourceLimitProps {
  totalPage: number;
  pageSize: number;
}

const limitOptions = [
  { value: 2, label: "2" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
];

export default function ResourceLimit({ totalPage, pageSize }: ResourceLimitProps) {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const onSetPageSize = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLimit = Number(e.target.value);
    searchParams.set("limit", newLimit.toString());
    searchParams.set("page", "1");
    history.push({ search: searchParams.toString() });
  };

  return (
    <Flex align={"center"}>
      <Text
        color={"gray.500"}
        fontSize={"sm"}
        fontWeight={"normal"}
        me={"10px"}
        minW={"max-content"}
      >
        Show rows per page
      </Text>

      <Select
        variant="main"
        fontSize={"sm"}
        fontWeight={"400"}
        value={pageSize || PAGE_SIZE}
        onChange={(e) => onSetPageSize(e)}
      >
        {limitOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Flex>
  );
}
