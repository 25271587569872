import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Box, Flex, SimpleGrid, Button, Text, Link } from "@chakra-ui/react";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Card from "components/card/Card";
import Section from "components/section/Section";

import { formatDate } from "utils/helpers";

import { useCourse } from "hooks/admin/kursus/useCourse";

export default function ShowCourse() {
  const { kursus, isLoading, isInitialLoading } = useCourse();

  const history = useHistory();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <Section title="Maklumat Kursus">
            <SimpleGrid
              columns={{ base: 2 }}
              gap={{ sm: "6px", md: "18px" }}
              mb={10}
            >
              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Nama Usahawan</Text>
                <Text>{kursus?.data?.user?.name ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>No. Kad Pengenalan</Text>
                <Text>{kursus?.data?.user?.nric ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Nama Kursus</Text>
                <Text>{kursus?.data?.nama_kursus ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Negeri</Text>
                <Text>{kursus?.data?.negeri?.negeri ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Penganjur</Text>
                <Text>{kursus?.data?.penganjur ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Tarikh Mula</Text>
                <Text>
                  {kursus?.data?.tarikh_mula
                    ? formatDate(kursus?.data?.tarikh_mula, "dd MMMM yyyy")
                    : "-"}
                </Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Catatan</Text>
                <Text>{kursus?.data?.deskripsi ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Lampiran</Text>
                {kursus?.data?.file_path ? (
                  <Link
                    href={`https://${kursus?.data?.file_path}`}
                    maxWidth={"200px"}
                    color={"brand.700"}
                    textDecoration={"underline"}
                    isExternal
                  >
                    {kursus?.data?.file_path}
                  </Link>
                ) : (
                  <Text>Tiada lampiran</Text>
                )}
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>ID Usahawan E-Latihan</Text>
                <Text>{kursus?.data?.id_usahawan_e_latihan ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>ID E-Latihan</Text>
                <Text>{kursus?.data?.id_e_latihan ?? "-"}</Text>
              </Flex>

              <Flex direction={"column"} gap={"2"}>
                <Text fontWeight={"semibold"}>Tarikh Kemaskini E-Latihan</Text>
                <Text>
                  {kursus?.data?.last_update_e_latihan
                    ? formatDate(
                        kursus?.data?.last_update_e_latihan,
                        "dd MMMM yyyy"
                      )
                    : "-"}
                </Text>
              </Flex>
            </SimpleGrid>
          </Section>

          <Flex gap="20px" justifyContent={"flex-end"}>
            <Button
              variant="outline"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              colorScheme="gray"
              type={"button"}
              onClick={() => {
                history.push("/admin/kursus");
              }}
            >
              Kembali
            </Button>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}
