import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Grid, GridItem, Text, Link, Image, Flex } from "@chakra-ui/react";

import logoRISDA from "assets/img/layout/logoRISDA.png";
import appleStore from "assets/img/store/applestore.png";
import playStore from "assets/img/store/playstore.png";

const Footer = () => {
  return (
    <Box as="footer" pt={10} bg="gray.900" color="white">
      <Flex
        direction={{ base: "column", md: "row" }}
        gap={10}
        px={{ base: 4, md: 14 }}
      >
        <Flex flexDirection="column" width={{ base: "100%", md: 800 }}>
          <Box display="flex" alignItems="center" gap="10px" mb={2}>
            <Image src={logoRISDA} alt="Risda Logo" width={10} />
            <Text
              color="brand.500"
              fontSize={{ base: "2xl", md: "3xl" }}
              fontWeight="800"
            >
              REDS 3.0
            </Text>
          </Box>

          <Text maxW="400px">
            REDS 3.0 salah satu inisiatif untuk membantu usahawan dalam
            menguruskan hal-hal berkaitan perniagaan/ syarikat dan aliran
            kewangan keluar masuk secara mudah serta membantu usahawan
            menganalisis prestasi pendapatan/ untung & rugi syarikat secara
            bulanan.
          </Text>
        </Flex>

        <Flex width={{ base: "100%", md: "50%" }} justifyContent="space-between" gap={10} flexWrap="wrap">
          <Flex flexDirection="column" mb={{ base: 4, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg" mb={3}>
              Menu Utama
            </Text>
            <NavLink to="/guest/katalog">
              <Text mb={2}>Katalog</Text>
            </NavLink>
            <NavLink to="/guest/directory">
              <Text>Direktori</Text>
            </NavLink>
          </Flex>

          <Flex flexDirection="column" mb={{ base: 4, md: 0 }}>
            <Text fontWeight="bold" fontSize="lg" mb={3}>
              Ikuti Kami
            </Text>
            <Link href="#" display="block" mb={2}>
              Facebook
            </Link>
            <Link href="#" display="block">
              Youtube
            </Link>
          </Flex>

          <Flex flexDirection="column">
            <Text fontWeight="bold" fontSize="lg" mb={4}>
              Muat Turun Aplikasi Mobile
            </Text>

            <Flex gap={4}>
              <Link href="#" display="block">
                <Image
                  borderRadius="4px"
                  src={appleStore}
                  alt="App Store"
                  width={140}
                />
              </Link>
              <Link href="#" display="block">
                <Image
                  borderRadius="4px"
                  src={playStore}
                  alt="Google Play"
                  width={140}
                />
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Text
        textAlign="center"
        mt={8}
        borderTop="1px"
        borderColor="gray.700"
        py={4}
      >
        REDS 3.0 © 2024. All Rights Reserved
      </Text>
    </Box>
  );
};

export default Footer;