import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Text, List, Icon, Button } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdFileOpen } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { formatDate } from "utils/helpers";

const testingData = {
  data: {
    current_item_position_start: 1,
    current_item_position_end: 10,
    current_page_item_count: 10,
    items_per_page: 10,
    total_item_count: 11,
    total_filtered_item_count: 11,
    current_page: 1,
    total_pages: 2,
    items: [
      {
        id_integrator: 1,
        usahawan_integrator: "KOPERASI PEKEBUN KECIL DAERAH KINTA BERHAD",
        no_kp_ssm: "IP 5373/1",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
      {
        id_integrator: 2,
        usahawan_integrator: "KOPERASI PEKEBUN KECIL DAERAH KINTA BERHAD",
        no_kp_ssm: "IP 5373/1",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 40,
        pt_name: "PEJABAT PRD - BATANG PADANG/ PERAK HILIR",
        daerah_id: 64,
        daerah_name: "BATANG PADANG",
      },
      {
        id_integrator: 3,
        usahawan_integrator:
          "KOPERASI PEKEBUN KECIL DAERAH HULU TERENGGANU BERHAD (KOPU...",
        no_kp_ssm: "IP 5889/2",
        id_negeri: 11,
        negeri_name: "TERENGGANU",
        id_pt: 66,
        pt_name: "PEJABAT PRD - HULU TERENGGANU",
        daerah_id: 87,
        daerah_name: "HULU TERENGGANU",
      },
      {
        id_integrator: 4,
        usahawan_integrator: "Nor Azura Binti Rosli",
        no_kp_ssm: "901124-08-5372",
        id_negeri: 3,
        negeri_name: "KELANTAN",
        id_pt: 74,
        pt_name: "PEJABAT PRJ - JELI",
        daerah_id: 25,
        daerah_name: "JELI",
      },
      {
        id_integrator: 5,
        usahawan_integrator: "Nik Mohd Shukri Bin Nik Mohamed",
        no_kp_ssm: "901124-08-5376",
        id_negeri: 3,
        negeri_name: "KELANTAN",
        id_pt: 72,
        pt_name: "PEJABAT PRJ - KELANTAN UTARA",
        daerah_id: 24,
        daerah_name: "KELANTAN UTARA",
      },
      {
        id_integrator: 6,
        usahawan_integrator: "FIRDAUS BIN NAZARUDIN",
        no_kp_ssm: "830713-18-5371",
        id_negeri: 3,
        negeri_name: "KELANTAN",
        id_pt: 73,
        pt_name: "PEJABAT PRJ - MACHANG/ PASIR PUTEH",
        daerah_id: 29,
        daerah_name: "MACHANG",
      },
      {
        id_integrator: 7,
        usahawan_integrator: "PANIZAM BIN ABDUL HAMID",
        no_kp_ssm: "580804-08-5841",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
      {
        id_integrator: 8,
        usahawan_integrator: "NORHANI BINTI MAT",
        no_kp_ssm: "610812-08-5382",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
      {
        id_integrator: 9,
        usahawan_integrator: "KAMARUDDIN BIN MOHAMAD",
        no_kp_ssm: "611025-08-5375",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
      {
        id_integrator: 10,
        usahawan_integrator: "ZARIAH BINTI MOKHTAR",
        no_kp_ssm: "511128-08-5598",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
      {
        id_integrator: 11,
        usahawan_integrator: "AHMAD ZAMRI BIN BAHARUM",
        no_kp_ssm: "610520-08-5879",
        id_negeri: 8,
        negeri_name: "PERAK",
        id_pt: 39,
        pt_name: "PEJABAT PRD - PERAK TENGAH/KINTA",
        daerah_id: 73,
        daerah_name: "PERAK TENGAH",
      },
    ],
  },
};

const columnHelper = createColumnHelper<any>();

const BukuTunai = () => {
  const columns = [
    columnHelper.accessor("usahawan_integrator", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_ssm", {
      header: () => <Text variant="tableHeading">No. KP/ SSM</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <NavLink
          to={{
            pathname: "/admin/kewangan/buku-tunai/janaan",
            state: {
              nama: "usahawan",
              no_kp_ssm: "123",
            },
          }}
        >
          <Button
            colorScheme="green"
            variant="outline"
            fontSize="sm"
            leftIcon={
              <Icon w="20px" h="20px" color={"green.500"} as={MdFileOpen} />
            }
          >
            Jana Buku Tunai
          </Button>
        </NavLink>
      ),
    }),
  ];

  if (false) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={testingData}
        title={"Senarai Usahawan Dan Integrator"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
};

export default BukuTunai;
