const tableData: any = {
  data: [
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 163,
      dun: "SERKAM",
      u_dun_id: "04139026",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 162,
      dun: "RIM",
      u_dun_id: "04139025",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 161,
      dun: "BEMBAN",
      u_dun_id: "04139024",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 160,
      dun: "TELOK MAS",
      u_dun_id: "04138023",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 159,
      dun: "BANDAR HILIR",
      u_dun_id: "04138022",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 158,
      dun: "DUYONG",
      u_dun_id: "04138021",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 157,
      dun: "KOTA LAKSAMANA",
      u_dun_id: "04138020",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 156,
      dun: "KESIDANG",
      u_dun_id: "04138019",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 155,
      dun: "AYER MOLEK",
      u_dun_id: "04137018",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 154,
      dun: "BUKIT BARU",
      u_dun_id: "04137017",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 153,
      dun: "AYER KEROH",
      u_dun_id: "04137016",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 152,
      dun: "BACHANG",
      u_dun_id: "04137015",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 151,
      dun: "KELEBANG",
      u_dun_id: "04136014",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 150,
      dun: "PAYA RUMPUT",
      u_dun_id: "04136013",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 149,
      dun: "PANTAI KUNDOR",
      u_dun_id: "04136012",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 148,
      dun: "SUNGAI UDANG",
      u_dun_id: "04136011",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 147,
      dun: "ASAHAN",
      u_dun_id: "04135010",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 146,
      dun: "DURIAN TUNGGAL",
      u_dun_id: "04135009",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 145,
      dun: "MACHAP",
      u_dun_id: "04135008",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 144,
      dun: "GADEK",
      u_dun_id: "04135007",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 143,
      dun: "REMBIA",
      u_dun_id: "04135006",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 142,
      dun: "TABOH NANING",
      u_dun_id: "04134005",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 141,
      dun: "LENDU",
      u_dun_id: "04134004",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 140,
      dun: "AYER LIMAU",
      u_dun_id: "04134003",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 139,
      dun: "TANJUNG BIDARA",
      u_dun_id: "04134002",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 773,
      bandar: "MELAKA",
      poskod: "75540",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 138,
      dun: "KUALA LINGGI",
      u_dun_id: "04134001",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 165,
      dun: "SUNGAI RAMBAI",
      u_dun_id: "04139028",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 164,
      dun: "MERLIMAU",
      u_dun_id: "04139027",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 163,
      dun: "SERKAM",
      u_dun_id: "04139026",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 162,
      dun: "RIM",
      u_dun_id: "04139025",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 161,
      dun: "BEMBAN",
      u_dun_id: "04139024",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 160,
      dun: "TELOK MAS",
      u_dun_id: "04138023",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 159,
      dun: "BANDAR HILIR",
      u_dun_id: "04138022",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 158,
      dun: "DUYONG",
      u_dun_id: "04138021",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 157,
      dun: "KOTA LAKSAMANA",
      u_dun_id: "04138020",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 156,
      dun: "KESIDANG",
      u_dun_id: "04138019",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 155,
      dun: "AYER MOLEK",
      u_dun_id: "04137018",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 154,
      dun: "BUKIT BARU",
      u_dun_id: "04137017",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 153,
      dun: "AYER KEROH",
      u_dun_id: "04137016",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 152,
      dun: "BACHANG",
      u_dun_id: "04137015",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 151,
      dun: "KELEBANG",
      u_dun_id: "04136014",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 150,
      dun: "PAYA RUMPUT",
      u_dun_id: "04136013",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 149,
      dun: "PANTAI KUNDOR",
      u_dun_id: "04136012",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 148,
      dun: "SUNGAI UDANG",
      u_dun_id: "04136011",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 147,
      dun: "ASAHAN",
      u_dun_id: "04135010",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 146,
      dun: "DURIAN TUNGGAL",
      u_dun_id: "04135009",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 145,
      dun: "MACHAP",
      u_dun_id: "04135008",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 144,
      dun: "GADEK",
      u_dun_id: "04135007",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 143,
      dun: "REMBIA",
      u_dun_id: "04135006",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 142,
      dun: "TABOH NANING",
      u_dun_id: "04134005",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 141,
      dun: "LENDU",
      u_dun_id: "04134004",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 140,
      dun: "AYER LIMAU",
      u_dun_id: "04134003",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 139,
      dun: "TANJUNG BIDARA",
      u_dun_id: "04134002",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 774,
      bandar: "MELAKA",
      poskod: "75542",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 138,
      dun: "KUALA LINGGI",
      u_dun_id: "04134001",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 165,
      dun: "SUNGAI RAMBAI",
      u_dun_id: "04139028",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 164,
      dun: "MERLIMAU",
      u_dun_id: "04139027",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 163,
      dun: "SERKAM",
      u_dun_id: "04139026",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 162,
      dun: "RIM",
      u_dun_id: "04139025",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 161,
      dun: "BEMBAN",
      u_dun_id: "04139024",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 160,
      dun: "TELOK MAS",
      u_dun_id: "04138023",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 159,
      dun: "BANDAR HILIR",
      u_dun_id: "04138022",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 158,
      dun: "DUYONG",
      u_dun_id: "04138021",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 157,
      dun: "KOTA LAKSAMANA",
      u_dun_id: "04138020",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 156,
      dun: "KESIDANG",
      u_dun_id: "04138019",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 155,
      dun: "AYER MOLEK",
      u_dun_id: "04137018",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 154,
      dun: "BUKIT BARU",
      u_dun_id: "04137017",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 153,
      dun: "AYER KEROH",
      u_dun_id: "04137016",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 152,
      dun: "BACHANG",
      u_dun_id: "04137015",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 151,
      dun: "KELEBANG",
      u_dun_id: "04136014",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 150,
      dun: "PAYA RUMPUT",
      u_dun_id: "04136013",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 149,
      dun: "PANTAI KUNDOR",
      u_dun_id: "04136012",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 148,
      dun: "SUNGAI UDANG",
      u_dun_id: "04136011",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 147,
      dun: "ASAHAN",
      u_dun_id: "04135010",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 146,
      dun: "DURIAN TUNGGAL",
      u_dun_id: "04135009",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 145,
      dun: "MACHAP",
      u_dun_id: "04135008",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 144,
      dun: "GADEK",
      u_dun_id: "04135007",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 57,
      parlimen: "ALOR GAJAH",
      u_parlimen_id: "04135",
      id_dun: 143,
      dun: "REMBIA",
      u_dun_id: "04135006",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 142,
      dun: "TABOH NANING",
      u_dun_id: "04134005",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 141,
      dun: "LENDU",
      u_dun_id: "04134004",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 140,
      dun: "AYER LIMAU",
      u_dun_id: "04134003",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 139,
      dun: "TANJUNG BIDARA",
      u_dun_id: "04134002",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 775,
      bandar: "MELAKA",
      poskod: "75546",
      id_parlimen: 56,
      parlimen: "MASJID TANAH",
      u_parlimen_id: "04134",
      id_dun: 138,
      dun: "KUALA LINGGI",
      u_dun_id: "04134001",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 165,
      dun: "SUNGAI RAMBAI",
      u_dun_id: "04139028",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 164,
      dun: "MERLIMAU",
      u_dun_id: "04139027",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 163,
      dun: "SERKAM",
      u_dun_id: "04139026",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 162,
      dun: "RIM",
      u_dun_id: "04139025",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 61,
      parlimen: "JASIN",
      u_parlimen_id: "04139",
      id_dun: 161,
      dun: "BEMBAN",
      u_dun_id: "04139024",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 160,
      dun: "TELOK MAS",
      u_dun_id: "04138023",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 159,
      dun: "BANDAR HILIR",
      u_dun_id: "04138022",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 158,
      dun: "DUYONG",
      u_dun_id: "04138021",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 157,
      dun: "KOTA LAKSAMANA",
      u_dun_id: "04138020",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 60,
      parlimen: "KOTA MELAKA",
      u_parlimen_id: "04138",
      id_dun: 156,
      dun: "KESIDANG",
      u_dun_id: "04138019",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 155,
      dun: "AYER MOLEK",
      u_dun_id: "04137018",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 154,
      dun: "BUKIT BARU",
      u_dun_id: "04137017",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 153,
      dun: "AYER KEROH",
      u_dun_id: "04137016",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 59,
      parlimen: "BUKIT KATIL",
      u_parlimen_id: "04137",
      id_dun: 152,
      dun: "BACHANG",
      u_dun_id: "04137015",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 151,
      dun: "KELEBANG",
      u_dun_id: "04136014",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 150,
      dun: "PAYA RUMPUT",
      u_dun_id: "04136013",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 149,
      dun: "PANTAI KUNDOR",
      u_dun_id: "04136012",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
    {
      id_kampung: 2793,
      kampung: "ALAI",
      u_kampung_id: "40101001",
      id_seksyen: 1043,
      seksyen: "Tiada Seksyen",
      u_seksyen_id: "40114000",
      id_mukim: 687,
      mukim: "MUKIM ALAI",
      u_mukim_id: "40101",
      id_stesen: 63,
      stesen: "PRS - Jasin / Melaka Tengah",
      stesen_kod: "826103",
      id_daerah: 36,
      daerah: "MELAKA TENGAH",
      u_daerah_id: "401",
      id_pt: 24,
      pt: "PEJABAT PRN - MELAKA",
      kod_pt: "226199",
      id_bandar: 776,
      bandar: "MELAKA",
      poskod: "75550",
      id_parlimen: 58,
      parlimen: "TANGGA BATU",
      u_parlimen_id: "04136",
      id_dun: 148,
      dun: "SUNGAI UDANG",
      u_dun_id: "04136011",
      id_negeri: 4,
      negeri: "MELAKA",
      u_negeri_id: "04",
    },
  ],
};

export default tableData;
