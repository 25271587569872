import React from "react";
import FormPertanianTersedia from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { usePertanianTersedia } from "hooks/admin/pertanian-tersedia/usePertanianTersedia";

export default function EditPertanianTersedia() {
  const { pertanianTersedia, isLoading, isInitialLoading } = usePertanianTersedia();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormPertanianTersedia action="edit" initialData={pertanianTersedia?.data} />;
}
