import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, FormControl, SimpleGrid, FormLabel, Input } from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

import { useJenisBantuanDataSettings } from "hooks/setting/general/jenisBantuan/useJenisBantuanData";
import { useKategoriProjekDataSettings } from "hooks/setting/general/kategoriProjek/useKategoriProjekData";
import { useNamaProjekDataSettings } from "hooks/setting/general/namaProjek/useNamaProjekData";
import { useNamaHasilDataSettings } from "hooks/setting/general/namaHasil/useNamaProjekData";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { jenisBantuanData, isLoading: jenisBantuanLoading } =
    useJenisBantuanDataSettings();
  const { kategoriProjekData, isLoading: kategoriProjekLoading } =
    useKategoriProjekDataSettings();
  const { namaProjekData, isLoading: namaProjekLoading } =
    useNamaProjekDataSettings();
  const { namaHasilData, isLoading: namaHasilLoading } =
    useNamaHasilDataSettings();

  const [searchValues, setSearchValues] = useState({
    nama: "",
    nric: "",
    jenis_bantuan: "",
    kategori_projek: "",
    kod_projek: "",
    nama_projek: "",
    nama_hasil: "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const search = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama: "",
      nric: "",
      jenis_bantuan: "",
      kategori_projek: "",
      kod_projek: "",
      nama_projek: "",
      nama_hasil: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  const getSearchParams = (key: string) => {
    return searchParams.get(key);
  };

  if (
    jenisBantuanLoading ||
    kategoriProjekLoading ||
    namaProjekLoading ||
    namaHasilLoading
  ) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama ?? getSearchParams("nama")}
            onChange={(e: any) => handleOnTextChange(e, "nama")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No. Kad Pengenalan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nric ?? getSearchParams("nric")}
            onChange={(e: any) => handleOnTextChange(e, "nric")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Jenis Bantuan"}
            options={jenisBantuanData?.data}
            onChange={(e: any) => handleOnChange(e, "jenis_bantuan")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Kategori"}
            options={kategoriProjekData?.data}
            onChange={(e: any) => handleOnChange(e, "kategori_projek")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Kod Projek
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.kod_projek ?? getSearchParams("kod_projek")}
            onChange={(e: any) => handleOnTextChange(e, "kod_projek")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Nama Projek"}
            options={namaProjekData?.data}
            onChange={(e: any) => handleOnChange(e, "nama_projek")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Nama Hasil"}
            options={namaHasilData?.data}
            onChange={(e: any) => handleOnChange(e, "nama_hasil")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
