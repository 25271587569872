import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getPublicUsahawans } from "services/admin/usahawan/usahawanService";
import { PAGE_SIZE } from "utils/constants";

export function usePublicUsahawans(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const toast = useToast();

  let currentSearchParams: Record<string, string | string[]> = {};
  
  for (const [key, value] of searchParams.entries()) {
    if (key === 'negeri_id') {
      if (key in currentSearchParams) {
        (currentSearchParams[key] as string[]).push(value);
      } else {
        currentSearchParams[key] = [value];
      }
    } else {
      currentSearchParams[key] = value;
    }
  }

  const currentPage = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const currentLimit = searchParams.get("limit") ? Number(searchParams.get("limit")) : PAGE_SIZE;

  const queryKey = ["public-usahawans", currentSearchParams];

  const {
    isLoading,
    data: usahawans,
    error,
  } = useQuery({
    queryKey,
    queryFn: () =>
      getPublicUsahawans({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, usahawans };
}