import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getDaerahOptions } from "services/setting/general/daerahService";
import { PAGE_SIZE } from "utils/constants";

export function useDaerahSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage") ? 50 : Number(searchParams.get("itemsPerPage"));

  const { isLoading, data: daerahs, error } = useQuery({
    queryKey: ["daerahs", currentPage, currentLimit, params],
    queryFn: () => getDaerahOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
  });

  // OUTPUT
  return {
    daerahs,
    isLoading,
    error,
  };
}
