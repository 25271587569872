import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getBuletin } from "services/admin/buletin/buletinService";

export function useBuletin() {
  const { buletinId } = useParams<{ buletinId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: buletin,
    error,
  } = useQuery({
    queryKey: ["buletin", buletinId],
    queryFn: () => getBuletin(buletinId),
    enabled: !!buletinId,
  });

  return { isLoading, isInitialLoading, error, buletin };
}

