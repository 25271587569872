import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLock,
  MdPersonSearch,
  MdCalendarToday,
} from "react-icons/md";
import {
  FaCalendarDay,
  FaList,
  FaListAlt,
  FaRegMoneyBillAlt,
  FaWallet,
} from "react-icons/fa";
import { IoNewspaperOutline, IoPersonCircle } from "react-icons/io5";
import { RiMoneyDollarCircleFill, RiPlantFill } from "react-icons/ri";

import MainDashboard from "views/admin/dashboard";

import User from "views/setting/user";
import CreateUser from "views/setting/user/Create";
import EditUser from "views/setting/user/Edit";
import ShowUser from "views/setting/user/Show";

import Aktiviti from "views/admin/aktiviti";
import ShowAktiviti from "views/admin/aktiviti/Show";
import EditAktiviti from "views/admin/aktiviti/Edit";

import Profile from "views/setting/profile";

import SignInCentered from "views/auth/signIn";

import Carian from "views/admin/carian/usahawan";
import CarianPelaksana from "views/admin/carian/pelaksana";
import ShowPelaksana from "views/admin/carian/pelaksana/Show";
import InsentifBPU from "views/admin/insentif/bpu";
import ShowInsentifBPU from "views/admin/insentif/bpu/Show";
import InsentifBPK from "views/admin/insentif/bpk";
import ShowProjekBpk from "views/admin/insentif/bpk/Show";
import Katalog from "views/admin/katalog";
import ShowKatalog from "views/admin/katalog/Show";
import CreateKatalog from "views/admin/katalog/Create";
import EditKatalog from "views/admin/katalog/Edit";

import PertanianTersedia from "views/admin/pertanian-tersedia";
import ShowPertanianTersedia from "views/admin/pertanian-tersedia/Show";
import CreatePertanianTersedia from "views/admin/pertanian-tersedia/Create";
import EditPertanianTersedia from "views/admin/pertanian-tersedia/Edit";

import RekodHasil from "views/admin/rekod-hasil";
import Pendapatan from "views/admin/pendapatan";

import Lawatan from "views/admin/lawatan";
import EditLawatan from "views/admin/lawatan/Edit";
import ShowLawatan from "views/admin/lawatan/Show";

import Kursus from "views/admin/kursus";
import ShowKursus from "views/admin/kursus/Show"

import Buletin from "views/admin/buletin";
import CreateBuletin from "views/admin/buletin/Create";
import EditBuletin from "views/admin/buletin/Edit";
import ShowBuletin from "views/admin/buletin/Show";

import ForgotPassword from "views/auth/forgotPassword";

import TunaiMasuk from "views/admin/kewangan/tunaiMasuk";
import BukuTunai from "views/admin/kewangan/bukuTunai";
import JanaanBukuTunai from "views/admin/kewangan/bukuTunai/JanaanBukuTunai";
import RingkasanLejar from "views/admin/kewangan/ringkasanLejar";
import JanaanRingkasanLejar from "views/admin/kewangan/ringkasanLejar/JanaanRingkasanLejar";
import UntungRugi from "views/admin/kewangan/untungRugi";
import JanaanUntungRugi from "views/admin/kewangan/untungRugi/JanaanUntungRugi";
import DokumenPerniagaan from "views/admin/kewangan/dokumenPerniagaan";

import UsahawanPanel from "views/admin/carian/usahawan/UsahawanPanel";

const mainRoutes = [
  {
    name: "Utama",
    layout: "/admin",
    path: "/dashboards",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Pengguna",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon as={IoPersonCircle} width="20px" height="20px" color="inherit" />
    ),
    component: User,
  },
  {
    name: "Carian",
    path: "/carian",
    icon: (
      <Icon as={MdPersonSearch} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "Usahawan",
        layout: "/admin",
        path: "/usahawan",
        component: Carian,
      },
      {
        name: "Pelaksana",
        layout: "/admin",
        path: "/pelaksana",
        component: CarianPelaksana,
      },
    ],
  },
  {
    name: "Maklumat Pelaksana",
    layout: "/admin",
    path: "/pelaksana/show/:userId",
    component: ShowPelaksana,
    secondary: true,
  },
  {
    name: "Insentif",
    path: "/insentif",
    icon: (
      <Icon as={RiMoneyDollarCircleFill} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "BPU",
        layout: "/admin",
        path: "/bpu",
        component: InsentifBPU,
      },
      {
        name: "BPK",
        layout: "/admin",
        path: "/bpk",
        component: InsentifBPK,
      },
    ],
  },
  {
    name: "Katalog",
    layout: "/admin",
    path: "/katalog",
    icon: (
      <Icon
        as={IoNewspaperOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Katalog,
  },
  {
    name: "Pertanian Tersedia",
    layout: "/admin",
    path: "/pertanian-tersedia",
    icon: <Icon as={RiPlantFill} width="20px" height="20px" color="inherit" />,
    component: PertanianTersedia,
  },
  {
    name: "Kewangan",
    path: "/kewangan",
    icon: (
      <Icon as={FaRegMoneyBillAlt} width="20px" height="20px" color="inherit" />
    ),
    collapse: true,
    items: [
      {
        name: "Tunai Masuk Keluar",
        layout: "/admin",
        path: "/kewangan/tunai-masuk-keluar",
        component: TunaiMasuk,
      },
      {
        name: "Janaan Buku Tunai",
        layout: "/admin",
        path: "/kewangan/buku-tunai",
        component: BukuTunai,
      },
      {
        name: "Janaan Ringkasan Lejar",
        layout: "/admin",
        path: "/kewangan/ringkasan-lejar",
        component: RingkasanLejar,
      },
      {
        name: "Janaan Penyata Untung Rugi",
        layout: "/admin",
        path: "/kewangan/untung-rugi",
        component: UntungRugi,
      },
      {
        name: "Janaan Dokumen Perniagaan",
        layout: "/admin",
        path: "/kewangan/dokumen-perniagaan",
        component: DokumenPerniagaan,
      },
    ],
  },
  {
    name: "Maklumat Insentif BPU",
    layout: "/admin",
    path: "/insentif/bpu/show/:insentifBPUId",
    component: ShowInsentifBPU,
    secondary: true,
  },
  {
    name: "Maklumat Insentif BPK",
    layout: "/admin",
    path: "/insentif/bpk/show/:projekBpkId",
    component: ShowProjekBpk,
    secondary: true,
  },
  {
    name: "Janaan Buku Tunai",
    layout: "/admin",
    path: "/kewangan/buku-tunai/janaan",
    component: JanaanBukuTunai,
    secondary: true
  },
  {
    name: "Janaan Ringkasan Lejar",
    layout: "/admin",
    path: "/kewangan/ringkasan-lejar/janaan",
    component: JanaanRingkasanLejar,
    secondary: true
  },
  {
    name: "Janaan Ringkasan Lejar",
    layout: "/admin",
    path: "/kewangan/untung-rugi/janaan",
    component: JanaanUntungRugi,
    secondary: true
  },
  {
    name: "Rekod Hasil",
    layout: "/admin",
    path: "/rekod-hasil",
    icon: (
      <Icon as={MdCalendarToday} width="20px" height="20px" color="inherit" />
    ),
    component: RekodHasil,
  },
  {
    name: "Pendapatan",
    layout: "/admin",
    path: "/pendapatan",
    icon: <Icon as={FaWallet} width="20px" height="20px" color="inherit" />,
    component: Pendapatan,
  },
  {
    name: "Aktiviti",
    layout: "/admin",
    path: "/aktiviti",
    icon: (
      <Icon as={FaCalendarDay} width="20px" height="20px" color="inherit" />
    ),
    component: Aktiviti,
  },
  {
    name: "Lawatan",
    layout: "/admin",
    path: "/lawatan",
    icon: <Icon as={FaListAlt} width="20px" height="20px" color="inherit" />,
    component: Lawatan,
  },
  {
    name: "Kursus",
    layout: "/admin",
    path: "/kursus",
    icon: <Icon as={FaList} width="20px" height="20px" color="inherit" />,
    component: Kursus,
  },
  {
    name: "Buletin",
    layout: "/admin",
    path: "/buletin",
    icon: (
      <Icon
        as={IoNewspaperOutline}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: Buletin,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
  },
  {
    name: "Profil",
    layout: "/admin",
    path: "/profile",
    component: Profile,
    secondary: true,
  },

  {
    name: "Daftar Pengguna",
    layout: "/admin",
    path: "/settings/users/baru",
    component: CreateUser,
    secondary: true,
  },
  {
    name: "Kemaskini Pengguna",
    layout: "/admin",
    path: "/settings/users/kemaskini/:userId",
    component: EditUser,
    secondary: true,
  },
  {
    name: "Maklumat Pengguna",
    layout: "/admin",
    path: "/settings/users/show/:userId",
    component: ShowUser,
    secondary: true,
  },
  {
    name: "Kemaskini Lawatan",
    layout: "/admin",
    path: "/lawatan/kemaskini/:lawatanId",
    component: EditLawatan,
    secondary: true,
  },
  {
    name: "Maklumat Lawatan",
    layout: "/admin",
    path: "/lawatan/show/:lawatanId",
    component: ShowLawatan,
    secondary: true,
  },
  {
    name: "Tambah Buletin",
    layout: "/admin",
    path: "/buletin/baru",
    component: CreateBuletin,
    secondary: true,
  },
  {
    name: "Kemaskini Buletin",
    layout: "/admin",
    path: "/buletin/kemaskini/:buletinId",
    component: EditBuletin,
    secondary: true,
  },
  {
    name: "Maklumat Buletin",
    layout: "/admin",
    path: "/buletin/show/:buletinId",
    component: ShowBuletin,
    secondary: true,
  },
  {
    name: "Maklumat Kursus",
    layout: "/admin",
    path: "/kursus/show/:kursusId",
    component: ShowKursus,
    secondary: true,
  },
  {
    name: "Tambah Katalog",
    layout: "/admin",
    path: "/katalog/baru",
    component: CreateKatalog,
    secondary: true,
  },
  {
    name: "Kemaskini Katalog",
    layout: "/admin",
    path: "/katalog/kemaskini/:katalogId",
    component: EditKatalog,
    secondary: true,
  },
  {
    name: "Maklumat Katalog",
    layout: "/admin",
    path: "/katalog/show/:katalogId",
    component: ShowKatalog,
    secondary: true,
  },
  {
    name: "Kemaskini Aktiviti",
    layout: "/admin",
    path: "/aktiviti/kemaskini/:aktivitiId",
    component: EditAktiviti,
    secondary: true,
  },
  {
    name: "Maklumat Aktiviti",
    layout: "/admin",
    path: "/aktiviti/show/:aktivitiId",
    component: ShowAktiviti,
    secondary: true,
  },
  {
    name: "Maklumat Usahawan",
    layout: "/admin",
    path: "/usahawan/show/:usahawanId",
    component: UsahawanPanel,
    secondary: true,
  },
  {
    name: "Kemaskini Usahawan",
    layout: "/admin",
    path: "/usahawan/kemaskini/:usahawanId",
    component: UsahawanPanel,
    secondary: true,
  },
  {
    name: "Tambah PertanianTersedia",
    layout: "/admin",
    path: "/pertanian-tersedia/baru",
    component: CreatePertanianTersedia,
    secondary: true,
  },
  {
    name: "Kemaskini PertanianTersedia",
    layout: "/admin",
    path: "/pertanian-tersedia/kemaskini/:pertanianTersediaId",
    component: EditPertanianTersedia,
    secondary: true,
  },
  {
    name: "Maklumat PertanianTersedia",
    layout: "/admin",
    path: "/pertanian-tersedia/show/:pertanianTersediaId",
    component: ShowPertanianTersedia,
    secondary: true,
  },
];

export default mainRoutes;
