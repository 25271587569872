import { IForgotPasswordFormValues, IResetPasswordFormValues, ISignInFormValues } from "entities/auth/auth";
import axios from "utils/axiosInstance";
import "./authMockup";

export async function login({ email, password }: ISignInFormValues) {
  const { data } = await axios.call({
    method: "post",
    url: "/login",
    data: { email, password },
  });
  return data;
}

export async function forgotPassword({ email }: IForgotPasswordFormValues) {
  const { data } = await axios.call({
    method: "post",
    url: "/forgot-password",
    data: { email },
  });

  return data;
}

export async function resetPassword({ email, token, password, password_confirmation }: IResetPasswordFormValues) {
  const { data } = await axios.call({
    method: "post",
    url: "/reset-password",
    data: { email, token, password, password_confirmation },
  });
  
  return data;
}
