import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getProjekBpk } from "services/admin/insentif/bpk/projekBpkService";

export function useProjekBpk() {
  const { projekBpkId } = useParams<{ projekBpkId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: projekBpk,
    error,
  } = useQuery({
    queryKey: ["projekBpk", projekBpkId],
    queryFn: () => getProjekBpk(projekBpkId),
    enabled: !!projekBpkId,
  });

  return { isLoading, isInitialLoading, error, projekBpk };
}

