import axios from "utils/axiosInstance";

export async function getNegeriOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/negeri",
    params,
  });
  return data;
}

export async function getNegeriOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/negeri/${id}`,
  });
  return data;
}

export async function getNegeriDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/negeri/all",
    params,
  });
  return data;
}
