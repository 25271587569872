import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getRoles } from "services/setting/roleService";

export function useRoles(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentitemsPerPage = !searchParams.get("itemsPerPage") ? 10 : Number(searchParams.get("itemsPerPage"));

  const {
    isLoading,
    data: roles,
    error,
  } = useQuery({
    queryKey: ["roles", currentPage, currentitemsPerPage, currentSearchParams],
    queryFn: () =>
      getRoles({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentitemsPerPage,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, roles };
}
