import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  Textarea,
} from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

interface TextFieldProps {
  id: string;
  label: string;
  marginTopForm?: string;
  marginBottomForm?: string;
  placeholder?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  variant?: string;
  fontSize?: string;
  ms?: any;
  type?: string;
  fontWeight?: string;
  size?: string;
  showPassword?: boolean;
  toggleShowPassword?: () => void;
  readOnly?: boolean;
  autoComplete?: string;
  step?: string;
}

const TextField = React.forwardRef<any, TextFieldProps>(
  (
    {
      id,
      label,
      marginTopForm,
      marginBottomForm,
      placeholder = "",
      isInvalid = false,
      errorMessage = "",
      variant = "auth",
      fontSize = "sm",
      ms = { base: "0px", md: "0px" },
      type = "text",
      fontWeight = "500",
      size = "md",
      showPassword = false,
      toggleShowPassword,
      step,
      readOnly = false,
      autoComplete="off",
      ...rest
    },
    ref
  ) => {
    return (
      <FormControl
        isInvalid={isInvalid}
        mt={marginTopForm}
        mb={marginBottomForm}
      >
        <FormLabel variant="main" mb="8px" htmlFor={id}>
          {label}
        </FormLabel>

        <InputGroup size="md">
          {type === "textarea" ? (
            <Textarea
              id={id}
              variant={variant}
              fontSize={fontSize}
              ms={ms}
              placeholder={placeholder}
              fontWeight={fontWeight}
              size={size}
              readOnly={readOnly}
              ref={ref}
              autoComplete={autoComplete}
              {...rest}
            />
          ) : (
            <Input
              id={id}
              variant={variant}
              fontSize={fontSize}
              ms={ms}
              type={
                type === "password"
                  ? showPassword
                    ? "text"
                    : "password"
                  : type
              }
              step={step}
              placeholder={placeholder}
              fontWeight={fontWeight}
              size={size}
              readOnly={readOnly}
              autoComplete={autoComplete}
              ref={ref}
              {...rest}
            />
          )}
          {type === "password" && (
            <InputRightElement display="flex" alignItems="center">
              <Icon
                color={"gray.400"}
                _hover={{ cursor: "pointer" }}
                as={showPassword ? MdOutlineRemoveRedEye : RiEyeCloseLine}
                onClick={toggleShowPassword}
                type="button"
              />
            </InputRightElement>
          )}
        </InputGroup>
        {isInvalid && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
      </FormControl>
    );
  }
);

export default TextField;
