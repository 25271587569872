import React, { useState } from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";

import { useUsers } from "hooks/setting/user/useUsers";
import useOptions from "hooks/setting/general/useOptions";
import { fetchLabel } from "utils/helpers";
import { IUserTable } from "entities/setting/user";

const columnHelper = createColumnHelper<IUserTable>();

export default function Pendapatan() {
  
  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("name", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => <Text variant="tableHeading">Emel</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink
            to={`/admin/settings/users/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/settings/users/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>

          {/* <Link>
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link> */}
        </List>
      ),
    }),
  ];

 

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={[]}
        title={"Senarai Pendapatan"}
      />
    </Box>
  );
}
