import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Text,
  Icon,
  Link,
  Input,
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import Dropzone from "views/setting/profile/components/Dropzone";
import QrDisplay from "components/qrCode/QrDisplay";
import FullScreenLoader from "components/loader/FullScreenLoader";
import BreadcrumbComponent from "components/breadcrumb/Breadcrumb";

import useOptions from "hooks/setting/general/useOptions";
import { useCreateCatalogue } from "hooks/admin/catalogue/useCreateCatalogue";
import { useEditCatalogue } from "hooks/admin/catalogue/useEditCatalogue";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

import { ICatalogueFormValues } from "entities/admin/catalogue";

import { fetchLabel, formatDate } from "utils/helpers";

interface FormCatalogueProps {
  action: "create" | "edit" | "show";
  initialData?: ICatalogueFormValues;
}

const FormCatalogue: React.FC<FormCatalogueProps> = ({
  action,
  initialData,
}) => {
  const [photo, setPhoto] = useState(null);

  const { createCatalogue, isCreating } = useCreateCatalogue();
  const { editCatalogue, isEditing } = useEditCatalogue();
  const { currentUser, isLoading } = useCurrentUser();

  const {
    statusPaparanKatalogOps,
    statusPengesahanOps,
    negeriOps,
    pusatTanggungjawabOps,
    daerahOps,
  } = useOptions();

  const history = useHistory();

  const schema = yup
    .object({
      nama_produk: yup.string().required(),
      keterangan_harga: yup.string().required(),
      deskripsi_produk: yup.string().required(),
      is_approved: yup.number().required(),
      is_published: yup.number().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICatalogueFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      nric: initialData?.user?.nric,
      name: initialData?.user?.name,
      published_by: initialData?.published_by?.name,
      approved_by: initialData?.approved_by?.name,
      tarikh_pengesahan: formatDate(initialData?.tarikh_pengesahan),
      date_published: formatDate(initialData?.date_published),
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createCatalogue({
        newCatalogue: data,
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editCatalogue({
        newCatalogue: {
          ...data,
          file: photo,
        },
        id: `${data.id}`,
      });
    }
  });

  const isApprovalAllowed = useMemo(() => {
    if (currentUser?.data?.roles && Array.isArray(currentUser.data.roles) && currentUser.data.roles.length > 0) {
      const permissions = currentUser.data.roles[0].permissions.map((p: any) => p.name);
      return permissions.includes("approve katalog");
    }
    
    return false;
  }, [currentUser]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Butiran Usahawan">
              {action !== "create" && (
                <BreadcrumbComponent
                  mb="10px"
                  items={[
                    { label: fetchLabel(initialData?.negeri_id, negeriOps) },
                    {
                      label: fetchLabel(
                        initialData?.pusat_tanggungjawap_id,
                        pusatTanggungjawabOps
                      ),
                    },
                    { label: fetchLabel(initialData?.daerah_id, daerahOps) },
                  ]}
                />
              )}

              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="tajuk"
                  label="Nama Usahawan"
                  readOnly={action !== "create"}
                  {...register("name")}
                />

                <TextField
                  id="tajuk"
                  label="No. Kad Pengenalan"
                  readOnly={action !== "create"}
                  {...register("nric")}
                />
              </SimpleGrid>
            </Section>

            <Section title="Butiran Produk">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama_produk"
                  label="Nama Produk"
                  placeholder="Masukkan nama produk"
                  isInvalid={!!errors.nama_produk}
                  errorMessage={errors.nama_produk?.message}
                  readOnly={action === "show"}
                  {...register("nama_produk")}
                />

                <TextField
                  id="deskripsi_produk"
                  label="Keterangan"
                  type="textarea"
                  isInvalid={!!errors.deskripsi_produk}
                  errorMessage={errors.deskripsi_produk?.message}
                  readOnly={action === "show"}
                  {...register("deskripsi_produk")}
                />

                <TextField
                  id="keterangan_harga"
                  label="Keterangan Harga"
                  isInvalid={!!errors.keterangan_harga}
                  errorMessage={errors.keterangan_harga?.message}
                  readOnly={action === "show"}
                  {...register("keterangan_harga")}
                />

                {isApprovalAllowed && (
                  <>
                    {/* show status draf if draft */}
                    {initialData?.is_approved == 2 ? (
                      <FormControl>
                        <FormLabel variant="main" mb="8px">
                          Status Pengesahan
                        </FormLabel>
                        <Input
                          id="is_approved"
                          type="text"
                          variant="main"
                          value={"Draf"}
                          readOnly
                        />
                      </FormControl>
                    ) : (
                      <SelectField
                        label={"Status Pengesahan"}
                        options={statusPengesahanOps}
                        isInvalid={!!errors.is_approved}
                        errorMessage={errors.is_approved?.message}
                        readOnly={action === "show"}
                        {...register("is_approved")}
                      />
                    )}

                    <TextField
                      id="deskripsi_pengesahan"
                      label="Deskripsi Pengesahan"
                      type="textarea"
                      isInvalid={!!errors.deskripsi_pengesahan}
                      errorMessage={errors.deskripsi_pengesahan?.message}
                      readOnly={
                        action === "show" || initialData?.is_approved == 2
                      }
                      {...register("deskripsi_pengesahan")}
                    />
                  </>
                )}

                <TextField
                  id="tarikh_pengesahan"
                  label="Tarikh Pengesahan"
                  type="date"
                  isInvalid={!!errors.tarikh_pengesahan}
                  errorMessage={errors.tarikh_pengesahan?.message}
                  readOnly={action !== "create"}
                  {...register("tarikh_pengesahan")}
                />

                <TextField
                  id="approved_by"
                  label="Disahkan oleh"
                  readOnly={action !== "create"}
                  {...register("approved_by")}
                />

                <TextField
                  id="published_by"
                  label="Dipapar oleh"
                  readOnly={action !== "create"}
                  {...register("published_by")}
                />

                <TextField
                  id="date_published"
                  label="Dipapar pada"
                  type="date"
                  isInvalid={!!errors.date_published}
                  errorMessage={errors.date_published?.message}
                  readOnly={action !== "create"}
                  {...register("date_published")}
                />

                {initialData?.is_approved == 1 && (
                  <SelectField
                    label={"Status Paparan Direktori"}
                    options={statusPaparanKatalogOps}
                    isInvalid={!!errors.is_published}
                    errorMessage={errors.is_published?.message}
                    readOnly={action === "show" || initialData?.is_approved < 1}
                    {...register("is_published")}
                  />
                )}

                <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Gambar Produk
                  </FormLabel>

                  <Dropzone
                    w={{ base: "100%" }}
                    maxH={{ base: "60%", lg: "50%", "2xl": "268px" }}
                    minH={{ base: "268px", lg: "268px", "2xl": "268px" }}
                    setPhoto={setPhoto}
                    photo={photo}
                    photoUrl={initialData?.gambar_produk_path}
                    content={
                      <Box>
                        <Icon as={MdUpload} w="80px" h="80px" />
                        <Flex justify="center" mx="auto" mb="12px">
                          <Text fontSize="xl" fontWeight="700">
                            {action === "edit"
                              ? "Kemaskini Gambar"
                              : "Muat Naik Gambar"}
                          </Text>
                        </Flex>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Saiz yang disyorkan: 1280px X 960px
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Jenis Fail: JPG, PNG
                        </Text>
                        {photo && (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color="brand.500"
                          >
                            {photo?.name ?? photo}
                          </Text>
                        )}
                      </Box>
                    }
                  />
                </FormControl>
              </SimpleGrid>
            </Section>

            {action !== "create" && (
              <Section title="Pautan">
                <SimpleGrid
                  columns={{ base: 2 }}
                  gap={{ sm: "6px", md: "18px" }}
                  mb={10}
                >
                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Pautan
                    </FormLabel>

                    {initialData?.external_link ? (
                      <Text
                        pl="4px"
                        variant="link"
                        fontSize="sm"
                        fontWeight="500"
                      >
                        <Link
                          href={`${initialData?.external_link}`}
                          maxWidth={"200px"}
                          textDecoration={"underline"}
                          isExternal
                        >
                          {initialData?.external_link}
                        </Link>
                      </Text>
                    ) : (
                      <Text pl="4px" fontSize="sm" fontWeight="500">
                        Belum Tersedia
                      </Text>
                    )}
                  </FormControl>

                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      QR Code
                    </FormLabel>

                    <QrDisplay qrCode={initialData?.qr_code} />
                  </FormControl>
                </SimpleGrid>
              </Section>
            )}

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/katalog");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Katalog"
                    : "Daftar Katalog Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormCatalogue;