import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useLogin } from "hooks/auth/useLogin";

import DefaultAuth from "components/layouts/auth/Default";
import TextField from "components/fields/TextField";
import illustration from "assets/img/auth/auth.png";

import { ISignInFormValues } from "entities/auth/auth";

const schema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required(),
    rememberMe: yup.boolean().optional(),
  })
  .required();

const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login, isLoading } = useLogin();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISignInFormValues>({
    resolver: yupResolver(schema) as any,
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = handleSubmit((data) => {
    login(data);
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <form onSubmit={onSubmit}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "16vh" }}
          float="right"
          flexDirection="column"
        >
          <Box me="auto">
            <Heading color={"navy.700"} fontSize="36px" mb="10px">
              Log Masuk
            </Heading>
            <Text mb="72px" ms="4px" variant="subtitle" fontSize="md">
              Masukkan emel dan kata laluan anda untuk log masuk!
            </Text>
          </Box>

          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <TextField
              id="email"
              label="Emel"
              placeholder="Masukkan Emel"
              isInvalid={!!errors.email}
              errorMessage={errors.email?.message}
              {...register("email")}
            />

            <TextField
              id="password"
              label="Kata laluan"
              placeholder="Min. 5 characters"
              type="password"
              marginTopForm="24px"
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              isInvalid={!!errors.password}
              errorMessage={errors.password?.message}
              {...register("password")}
            />

            <Flex
              mt="52px"
              justifyContent="space-between"
              align="center"
              mb="24px"
            >
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="rememberMe"
                  colorScheme="brandScheme"
                  me="10px"
                  {...register("rememberMe")}
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={"navy.700"}
                  fontSize="sm"
                >
                  Biarkan saya kekal log masuk
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text fontSize="sm" w="124px" variant="link">
                  Lupa kata laluan?
                </Text>
              </NavLink>
            </Flex>

            <Button
              type="submit"
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              disabled={isLoading}
            >
              {!isLoading ? "Log Masuk" : <Spinner />}
            </Button>
          </Flex>
        </Flex>
      </form>
    </DefaultAuth>
  );
};

export default SignIn;
