import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditCatalogue } from "services/admin/catalogue/catalogueService";
import { ICatalogueFormValues } from "entities/admin/catalogue";

export function useCreateCatalogue() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createCatalogue, isLoading: isCreating } = useMutation({
    mutationFn: ({newCatalogue, id}: {newCatalogue: ICatalogueFormValues, id: string | null}) => createEditCatalogue(newCatalogue, id),
    onSuccess: () => {
      toast({
        title: "New catalogue successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["catalogues"] });
      history.push("/admin/katalog");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createCatalogue };
}

