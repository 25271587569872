import axios from "utils/axiosInstance";

export async function getJenisInsentifOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-insentif",
    params,
  });
  return data;
}

export async function getJenisInsentifDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-insentif/all",
    params,
  });
  return data;
}


