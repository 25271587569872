import React from "react";
import FormLawatan from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useLawatan } from "hooks/admin/lawatan/useLawatan";

export default function EditUser() {
  const { lawatan, isLoading, isInitialLoading } = useLawatan();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormLawatan action="edit" initialData={lawatan?.data} />;
}
