import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getKategoriPelaksanaDataOptions } from "services/setting/general/kategoriPelaksanaService";

export function useKategoriPelaksanaDataSettings(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage") ? 50 : Number(searchParams.get("itemsPerPage"));

  const { isLoading, data: kategoriPelaksanaData, error } = useQuery({
    queryKey: ["kategoriPelaksanaData", currentPage, currentLimit, params],
    queryFn: () => getKategoriPelaksanaDataOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
  });

  // OUTPUT
  return {
    kategoriPelaksanaData,
    isLoading,
    error,
  };
}
