import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { createEditRole } from "services/setting/roleService";
import { IRoleFormValues } from "entities/setting/role";

export function useCreateRole() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: createRole, isLoading: isCreating } = useMutation({
    mutationFn: ({newRole, id}: {newRole: IRoleFormValues, id: string | null}) => createEditRole(newRole, id),
    onSuccess: () => {
      toast({
        title: "New role successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["roles"] });
      history.push("/admin/settings/roles");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isCreating, createRole };
}