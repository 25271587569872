import React from "react";
import { Flex, Stat, StatLabel, StatNumber, Icon } from "@chakra-ui/react";
import Card from 'components/card/Card';
import IconBox from "components/icons/IconBox";

interface DashboardCardProps {
  icon: any;
  label: string;
  value: string | number;
}

const DashboardCard = ({ icon, label, value }: DashboardCardProps) => {
  return (
    <Card boxShadow="md" p={{base: 4, md: 5, lg: 6}}>
      <Flex
        my="auto"
        h="100%"
        align="center"
        justify="center"
      >
        <IconBox
          w="66px"
          h="66px"
          bg={"secondaryGray.300"}
          icon={<Icon w="30px" h="30px" as={icon} color={"brand.500"} />}
        />
        <Stat my="auto" ms={"20px"}>
          <StatLabel
            lineHeight="100%"
            color={'secondaryGray.700'}
            fontSize={{ base: "lg" }}
          >
            {label}
          </StatLabel>
          <StatNumber
            color={'secondaryGray.900'}
            fontSize={{ base: "2xl" }}
          >
            {value}
          </StatNumber>
        </Stat>
      </Flex>
    </Card>
  );
};

export default DashboardCard;