import React from "react";
import FormInsentifBPU from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useInsentifBPU } from "hooks/admin/insentif/bpu/useInsentifBPU";

export default function ShowBp() {
  const { insentifBPU, isLoading, isInitialLoading, error  } = useInsentifBPU();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormInsentifBPU action="show" initialData={insentifBPU?.data} />;
}
