import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";

const Sorting = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { jenisDokumenOps } = useOptions();

  const [searchValues, setSearchValues] = useState({
    nama_usahawan: searchParams.get("nama_usahawan") || "",
    jenis_dokumen: searchParams.get("jenis_dokumen") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    history.push({ search: searchParams.toString() });
  };


  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama_usahawan: "",
      jenis_dokumen: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Usahawan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_usahawan}
            onChange={(e: any) => handleOnTextChange(e, "nama_usahawan")}
          />
        </FormControl>

        <SelectField
          label={"Jenis Dokumen"}
          value={searchValues.jenis_dokumen}
          options={jenisDokumenOps}
          onChange={(e: any) => handleOnChange(e, "jenis_dokumen")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}

export default Sorting;