import React from "react";
import { Flex } from "@chakra-ui/react";

import Header from "./components/layout/Header";
import Hero from "./components/layout/Hero";
import Footer from "./components/layout/Footer";
import Container from "./components/shared/Container";
import FullScreenLoader from "components/loader/FullScreenLoader";
import DirectoryList from "./components/directory/DirectoryList";
import DirectoryFilter from "./components/directory/DirectoryFilter";
import PaginationApi from "./components/shared/PaginationApi";

import { usePublicUsahawans } from "hooks/admin/usahawan/usePublicUsahawans";

import heroDirectory from "assets/img/hero/hero-directory.png";

const Directory = () => {
  const { usahawans, isLoading } = usePublicUsahawans();

  if (isLoading) return <FullScreenLoader />;

  return (
    <Flex flexDirection="column" minH="100vh">
      <Header />
      <Hero image={heroDirectory} />
      <Container flexDirection={{ base: "column", md: "row" }}>
        <DirectoryFilter />
        <Flex width={"100%"} flexDirection={"column"} gap={10}>
          <DirectoryList entrepreneurs={usahawans?.data?.items} />

          {usahawans?.data?.current_page && usahawans?.data?.total_pages && (
            <PaginationApi
              currentPage={usahawans?.data?.current_page}
              lastPage={usahawans?.data?.total_pages}
            />
          )}
        </Flex>
      </Container>
      <Footer />
    </Flex>
  );
};

export default Directory;
