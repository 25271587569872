import { BASICAUTH_EMAIL, BASICAUTH_PASS } from "config";
import axios from "utils/axiosInstance";

axios.mock?.onPost("/login").reply((config) => {
  const { email, password } = JSON.parse(config.data);
  if (email === BASICAUTH_EMAIL && password === BASICAUTH_PASS) {
    return [
      200,
      {
        status: "success",
        message: "Logged in successfully",
        data: { token: "6|KNz1WPW7zhe8v4QbGugtHPmrBQkbGw5ljfIbTLo60b8bca9a" },
      },
    ];
  } else {
    return [401, { message: "Invalid email or password" }];
  }
});
