import axios from "utils/axiosInstance";
import { IRekodHasilFormValues } from "entities/admin/rekod-hasil";

export async function getRekodHasil(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/rekod-hasil/show/${id}`,
  });
  return data;
}

export async function getRekodHasils(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/rekod-hasil",
    params,
  });
  return data;
}

export async function createEditRekodHasil(
  values: IRekodHasilFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/rekod-hasil/update` : "/rekod-hasil";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteRekodHasil(
  values: IRekodHasilFormValues,
  id: string | number | null
) {
  const apiUrl = `/rekod-hasil/delete`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}
