import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Flex, Text, Icon, Button } from "@chakra-ui/react";

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

interface ResourcePaginationProps {
  totalPage: number;
}

export default function ResourcePagination({
  totalPage,
}: ResourcePaginationProps) {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));

  const nextPage = () => {
    const next = currentPage === totalPage ? currentPage : currentPage + 1;
    searchParams.set("page", next.toString());
    history.push({ search: searchParams.toString() });
  };

  const prevPage = () => {
    const prev = currentPage === 1 ? currentPage : currentPage - 1;
    searchParams.set("page", prev.toString());
    history.push({ search: searchParams.toString() });
  };

  if (totalPage <= 1) return null;

  return (
    <Flex align={"center"} gap={"14px"} pr={"20px"}>
      <Button
        bg={"transparent"}
        border={"1px solid"}
        borderColor={"gray.200"}
        borderRadius={"50%"}
        h={"40px"}
        variant="no-effects"
        w={"40px"}
        onClick={prevPage}
      >
        <Icon
          h="16px"
          w="16px"
          color={currentPage === 1 ? "secondaryGray.600" : "secondaryGray.900"}
          as={MdKeyboardArrowLeft}
        />
      </Button>

      <Flex>
        <Text
          color={"gray.500"}
          fontSize={"sm"}
          fontWeight={"normal"}
          me={"10px"}
          minW={"max-content"}
        >
          Page
        </Text>

        <Text color={"gray.00"} fontSize={"sm"} fontWeight={"normal"}>
          {currentPage} of {totalPage}
        </Text>
      </Flex>

      <Button
        bg={"transparent"}
        border={"1px solid"}
        borderColor={"gray.200"}
        borderRadius={"50%"}
        h={"40px"}
        variant="no-effects"
        w={"40px"}
        onClick={nextPage}
        disabled={currentPage === totalPage}
      >
        <Icon
          h="16px"
          w="16px"
          color={currentPage === totalPage ? "secondaryGray.600" : "secondaryGray.900"}
          as={MdKeyboardArrowRight}
        />
      </Button>
    </Flex>
  );
}
