import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Image, Text, Link, Flex } from "@chakra-ui/react";

interface ImageDropzoneProps {
  onImagesSelected: (files: any) => void;
  imageUrl?: string | string[];
  isMultiple: boolean;
  isDisabled?: boolean;
}

const ImageDropzone: React.FC<ImageDropzoneProps> = ({
  onImagesSelected,
  isMultiple,
  imageUrl = [],
  isDisabled = false,
}) => {
  const [previews, setPreviews] = useState<string[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const filePreviews = acceptedFiles.map((file) =>
        URL.createObjectURL(file)
      );
      setPreviews(filePreviews);

      if (isMultiple) {
        onImagesSelected(acceptedFiles);
      } else {
        onImagesSelected(acceptedFiles[0]);
      }
    },
    [onImagesSelected, isMultiple]
  );

  useEffect(() => {
    // Revoke the data uris to avoid memory leaks
    return () => previews.forEach((url) => URL.revokeObjectURL(url));
  }, [previews]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: isMultiple,
    disabled: isDisabled,
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        border="1px dashed #cccccc"
        borderRadius="16px"
        padding="20px"
        textAlign="center"
        cursor={isDisabled ? "not-allowed" : "pointer"}
      >
        <input {...getInputProps()} />

        <Text fontSize="xl" fontWeight="700" mb="12px">
          Muat Naik Gambar
        </Text>

        <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
          Saiz yang disyorkan: 1280px X 960px
          <br />
          Jenis fail: jpg, png
          <br />
          <br />
          Letak atau klik untuk pilih gambar yang dikehendaki
        </Text>

        <Box display="flex" flexWrap="wrap" marginTop="20px" gap={2}>
          {previews.map((url, index) => (
            <Box key={index} margin="10px">
              <Image
                src={url}
                alt={`preview-${index}`}
                boxSize="100px"
                objectFit="cover"
              />
            </Box>
          ))}
        </Box>
      </Box>

      <Box>
        {imageUrl?.length > 0 && (
          <Text pt={"12px"} pb={"8px"} pl={"8px"} fontSize="sm">
            Gambar dimuat naik:
          </Text>
        )}

        <Flex flexWrap={"wrap"} gap={2}>
          {isMultiple &&
            Array.isArray(imageUrl) &&
            imageUrl.map((image: any, index: number) => (
              <Link key={index} href={"https://" + image.file_path} isExternal>
                <Image
                  src={"https://" + image.file_path}
                  alt={`uploaded-${index}`}
                  boxSize="140px"
                  objectFit="cover"
                />
              </Link>
            ))}
        </Flex>

        {!isMultiple && typeof imageUrl === "string" && (
          <Link href={"https://" + imageUrl} isExternal>
            <Image
              src={"https://" + imageUrl}
              alt="Uploaded Image"
              boxSize="200px"
              objectFit="cover"
            />
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default ImageDropzone;