import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

interface FormTahapPendidikanSettingProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormTahapPendidikanSetting: React.FC<FormTahapPendidikanSettingProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();

  const schema = yup.object({}).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {});

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Tahap Pendidikan">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="tahap_pendidikan"
                  label="Tahap Pendidikan"
                  // isInvalid={!!errors.no_fail_ptg}
                  // errorMessage={errors.no_fail_ptg?.message}
                  readOnly={action === "show"}
                  {...register("tahap_pendidikan")}
                />
              </SimpleGrid>

              <Flex gap="20px" justifyContent={"flex-end"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                  >
                    {action === "edit"
                      ? "Kemaskini Tahap Pendidikan"
                      : "Daftar Tahap Pendidikan Baru"}
                  </Button>
                )}
              </Flex>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormTahapPendidikanSetting;
