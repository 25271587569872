import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";

export function useLogout() {
  const history = useHistory();
  const auth = useAuth();

  const logout = () => {
    auth.clearAuthToken();
    history.push("/auth/sign-in");
  };

  return { logout };
}
