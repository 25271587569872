import { Icon } from "@chakra-ui/react";
import { MdSettings } from "react-icons/md";

import AuditTrail from "views/setting/auditTrail";

import Role from "views/setting/role";
import ShowRole from "views/setting/role/Show";
import EditRole from "views/setting/role/Edit";
import CreateRole from "views/setting/role/Create";

import Notification from "views/setting/notification";
import CreateNotification from "views/setting/notification/Create";
import EditNotification from "views/setting/notification/Edit";
import ShowNotification from "views/setting/notification/Show";

import Localities from "views/setting/localities";

import DataSetting from "views/setting/dataSetting";
import BangsaSetting from "views/setting/dataSetting/components/BangsaSetting";
import CreateBangsaSetting from "views/setting/dataSetting/components/BangsaSetting/Create";
import EditBangsaSetting from "views/setting/dataSetting/components/BangsaSetting/Edit";
import HubunganSetting from "views/setting/dataSetting/components/HubunganSetting";
import CreateHubunganSetting from "views/setting/dataSetting/components/HubunganSetting/Create";
import EditHubunganSetting from "views/setting/dataSetting/components/HubunganSetting/Edit";
import AgamaSetting from "views/setting/dataSetting/components/AgamaSetting";
import CreateAgamaSetting from "views/setting/dataSetting/components/AgamaSetting/Create";
import EditAgamaSetting from "views/setting/dataSetting/components/AgamaSetting/Edit";
import EtnikSetting from "views/setting/dataSetting/components/EtnikSetting";
import CreateEtnikSetting from "views/setting/dataSetting/components/EtnikSetting/Create";
import EditEtnikSetting from "views/setting/dataSetting/components/EtnikSetting/Edit";
import StatusKahwinSetting from "views/setting/dataSetting/components/StatusKahwinSetting";
import CreateStatusKahwinSetting from "views/setting/dataSetting/components/StatusKahwinSetting/Create";
import EditStatusKahwinSetting from "views/setting/dataSetting/components/StatusKahwinSetting/Edit";
import TahapPendidikanSetting from "views/setting/dataSetting/components/TahapPendidikanSetting";
import CreateTahapPendidikanSetting from "views/setting/dataSetting/components/TahapPendidikanSetting/Create";
import EditTahapPendidikanSetting from "views/setting/dataSetting/components/TahapPendidikanSetting/Edit";
import SijilTertinggiSetting from "views/setting/dataSetting/components/SijilTertinggiSetting";
import CreateSijilTertinggiSetting from "views/setting/dataSetting/components/SijilTertinggiSetting/Create";
import EditSijilTertinggiSetting from "views/setting/dataSetting/components/SijilTertinggiSetting/Edit";
import JenisMilikanSetting from "views/setting/dataSetting/components/JenisMilikanSetting";
import CreateJenisMilikanSetting from "views/setting/dataSetting/components/JenisMilikanSetting/Create";
import EditJenisMilikanSetting from "views/setting/dataSetting/components/JenisMilikanSetting/Edit";
import StatusSyarikatSetting from "views/setting/dataSetting/components/StatusSyarikatSetting";
import CreateStatusSyarikatSetting from "views/setting/dataSetting/components/StatusSyarikatSetting/Create";
import EditStatusSyarikatSetting from "views/setting/dataSetting/components/StatusSyarikatSetting/Edit";

const settingRoutes = [
  {
    name: "Konfigurasi Sistem",
    path: "/settings",
    icon: <Icon as={MdSettings} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Tetapan Peranan",
        layout: "/admin",
        path: "/settings/roles",
        component: Role,
      },
      {
        name: "Notifikasi",
        layout: "/admin",
        path: "/settings/notifications",
        component: Notification,
      },
      {
        name: "Audit Trail Sistem",
        layout: "/admin",
        path: "/settings/logs",
        component: AuditTrail,
      },
      {
        name: "Lokaliti",
        layout: "/admin",
        path: "/settings/localities",
        component: Localities,
      },
      {
        name: "Jenis Aktiviti",
        layout: "/admin",
        path: "/settings/aktiviti",
        component: AuditTrail,
      },
      {
        name: "Aliran Kewangan",
        layout: "/admin",
        path: "/settings/kewangan",
        component: AuditTrail,
      },
      {
        name: "Jenis Perniagaan",
        layout: "/admin",
        path: "/settings/perniagaan",
        component: AuditTrail,
      },
      {
        name: "Kategori Usahawan",
        layout: "/admin",
        path: "/settings/usahawan",
        component: AuditTrail,
      },
      {
        name: "Agromakanan",
        layout: "/admin",
        path: "/settings/agromakanan",
        component: AuditTrail,
      },
      {
        name: "Tetapan Pembolehubah",
        layout: "/admin",
        path: "/settings/pembolehubah",
        component: DataSetting,
      },
    ],
  },
  {
    name: "Daftar Peranan",
    layout: "/admin",
    path: "/settings/roles/baru",
    component: CreateRole,
    secondary: true,
  },
  {
    name: "Kemaskini Peranan",
    layout: "/admin",
    path: "/settings/roles/kemaskini/:roleId",
    component: EditRole,
    secondary: true,
  },
  {
    name: "Maklumat Peranan",
    layout: "/admin",
    path: "/settings/roles/show/:roleId",
    component: ShowRole,
    secondary: true,
  },
  {
    name: "Tambah Notifikasi",
    layout: "/admin",
    path: "/settings/notification/baru",
    component: CreateNotification,
    secondary: true,
  },
  {
    name: "Kemaskini Notifikasi",
    layout: "/admin",
    path: "/settings/notification/kemaskini/:notificationId",
    component: EditNotification,
    secondary: true,
  },
  {
    name: "Maklumat Notifikasi",
    layout: "/admin",
    path: "/settings/notification/show/:notificationId",
    component: ShowNotification,
    secondary: true,
  },
  {
    name: "Tetapan Bangsa",
    layout: "/admin",
    path: "/settings/data/bangsa",
    component: BangsaSetting,
    secondary: true,
  },
  {
    name: "Tambah Bangsa",
    layout: "/admin",
    path: "/settings/data/bangsa/baru",
    component: CreateBangsaSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Bangsa",
    layout: "/admin",
    path: "/settings/data/bangsa/kemaskini/:bangsaId",
    component: EditBangsaSetting,
    secondary: true,
  },
  {
    name: "Tetapan Hubungan",
    layout: "/admin",
    path: "/settings/data/hubungan",
    component: HubunganSetting,
    secondary: true,
  },
  {
    name: "Tambah Hubungan",
    layout: "/admin",
    path: "/settings/data/hubungan/baru",
    component: CreateHubunganSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Hubungan",
    layout: "/admin",
    path: "/settings/data/hubungan/kemaskini/:hubunganId",
    component: EditHubunganSetting,
    secondary: true,
  },
  {
    name: "Tetapan Agama",
    layout: "/admin",
    path: "/settings/data/agama",
    component: AgamaSetting,
    secondary: true,
  },
  {
    name: "Tambah Agama",
    layout: "/admin",
    path: "/settings/data/agama/baru",
    component: CreateAgamaSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Agama",
    layout: "/admin",
    path: "/settings/data/agama/kemaskini/:agamaId",
    component: EditAgamaSetting,
    secondary: true,
  },
  {
    name: "Tetapan Etnik",
    layout: "/admin",
    path: "/settings/data/etnik",
    component: EtnikSetting,
    secondary: true,
  },
  {
    name: "Tambah Etnik",
    layout: "/admin",
    path: "/settings/data/etnik/baru",
    component: CreateEtnikSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Etnik",
    layout: "/admin",
    path: "/settings/data/etnik/kemaskini/:etnikId",
    component: EditEtnikSetting,
    secondary: true,
  },
  {
    name: "Tetapan Status Perkahwinan",
    layout: "/admin",
    path: "/settings/data/status-perkahwinan",
    component: StatusKahwinSetting,
    secondary: true,
  },
  {
    name: "Tambah Status Perkahwinan",
    layout: "/admin",
    path: "/settings/data/status-perkahwinan/baru",
    component: CreateStatusKahwinSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Status Perkahwinan",
    layout: "/admin",
    path: "/settings/data/status-perkahwinan/kemaskini/:statusPernikahanId",
    component: EditStatusKahwinSetting,
    secondary: true,
  },
  {
    name: "Tetapan Tahap Pendidikan",
    layout: "/admin",
    path: "/settings/data/tahap-pendidikan",
    component: TahapPendidikanSetting,
    secondary: true,
  },
  {
    name: "Tambah Tahap Pendidikan",
    layout: "/admin",
    path: "/settings/data/tahap-pendidikan/baru",
    component: CreateTahapPendidikanSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Tahap Pendidikan",
    layout: "/admin",
    path: "/settings/data/tahap-pendidikan/kemaskini/:tahapPendidikanId",
    component: EditTahapPendidikanSetting,
    secondary: true,
  },
  {
    name: "Tetapan Sijil Tertinggi",
    layout: "/admin",
    path: "/settings/data/sijil-tertinggi",
    component: SijilTertinggiSetting,
    secondary: true,
  },
  {
    name: "Tambah Sijil Tertinggi",
    layout: "/admin",
    path: "/settings/data/sijil-tertinggi/baru",
    component: CreateSijilTertinggiSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Sijil Tertinggi",
    layout: "/admin",
    path: "/settings/data/sijil-tertinggi/kemaskini/:sijilTertinggiId",
    component: EditSijilTertinggiSetting,
    secondary: true,
  },
  {
    name: "Tetapan Jenis Milikan",
    layout: "/admin",
    path: "/settings/data/jenis-milikan",
    component: JenisMilikanSetting,
    secondary: true,
  },
  {
    name: "Tambah Jenis Milikan",
    layout: "/admin",
    path: "/settings/data/jenis-milikan/baru",
    component: CreateJenisMilikanSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Jenis Milikan",
    layout: "/admin",
    path: "/settings/data/jenis-milikan/kemaskini/:jenisMilikanId",
    component: EditJenisMilikanSetting,
    secondary: true,
  },
  {
    name: "Tetapan Status Syarikat",
    layout: "/admin",
    path: "/settings/data/status-syarikat",
    component: StatusSyarikatSetting,
    secondary: true,
  },
  {
    name: "Tambah Status Syarikat",
    layout: "/admin",
    path: "/settings/data/status-syarikat/baru",
    component: CreateStatusSyarikatSetting,
    secondary: true,
  },
  {
    name: "Kemaskini Status Syarikat",
    layout: "/admin",
    path: "/settings/data/status-syarikat/kemaskini/:statusSyarikatId",
    component: EditStatusSyarikatSetting,
    secondary: true,
  },
];

export default settingRoutes;
