import React, { useState } from "react";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Flex,
  Text,
  Box,
  Image,
  InputRightElement,
  InputLeftElement,
  Input,
  InputGroup,
  Button,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";

import logoRISDA from "assets/img/layout/logoRISDA.png";

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  
  const currentRoute = window.location.pathname;

  const [searchValues, setSearchValues] = useState({
    search: searchParams.get("search") || "",
  });

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setSearchValues((prevState) => ({ ...prevState, search: value }));
  };

  const updateSearchParams = () => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set("search", searchValues.search);
    history.push(`/guest/katalog?${newSearchParams.toString()}`);
  };

  const resetFilters = () => {
    setSearchValues({ search: "" });
    history.push("/guest/katalog");
  };

  return (
    <Flex
      py={{ base: "10px", md: "14px" }}
      px={{ base: "20px", md: "54px" }}
      justifyContent="space-between"
      alignItems="center"
      gap="10px"
      flexDirection={{ base: "column", md: "row" }}
      position="sticky"
      top="0"
      bg="white"
      zIndex="1000"
    >
      <NavLink to={"/guest"}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"10px"}
          minW={"max-content"}
        >
          <Image src={logoRISDA} alt="Risda Logo" width={10} />
          <Text
            color={"brand.500"}
            fontSize={{ md: "xl", lg: "3xl" }}
            fontWeight="800"
          >
            REDS 3.0
          </Text>
        </Box>
      </NavLink>

      <InputGroup w={{ base: "100%", md: "600px" }}>
        <Input
          value={searchValues.search}
          onChange={handleInputChange}
          variant="search"
          fontSize="sm"
          fontWeight="500"
          placeholder="Cari produk / perkhidmatan"
          _placeholder={{ color: "gray.400", fontSize: "14px" }}
          border={"1px solid #E2E8F0"}
          borderRadius={"8px"}
          _focus={{ borderColor: "green.500" }} 
        />

        {searchValues.search  && (
          <InputLeftElement>
            <Button
              h="100%"
              size="sm"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              onClick={resetFilters}
            >
              <CloseIcon color="gray.500" />
            </Button>
          </InputLeftElement>
        )}

        <InputRightElement width="5.5rem">
          <Button
            h="100%"
            size="sm"
            bg="green.500"
            color="white"
            _hover={{ bg: "green.600" }}
            _active={{
              bg: "green.700",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            borderLeftRadius={"0px"}
            onClick={updateSearchParams}
          >
            <SearchIcon color={"white"} w="15px" h="15px" />

            <Text ml="2">Carian</Text>
          </Button>
        </InputRightElement>
      </InputGroup>

      <Flex mt={{ base: 4, md: 0 }}>
        <Button
          color={"brand.400"}
          fontSize={"lg"}
          fontWeight={currentRoute.includes("katalog") ? "semibold" : "normal"}
          variant="transparent"
          as={Link}
          to={"/guest/katalog"}
        >
          Katalog
        </Button>
        <Button
          color={"brand.400"}
          fontSize={"lg"}
          fontWeight={
            currentRoute.includes("directory") ? "semibold" : "normal"
          }
          variant="transparent"
          as={Link}
          to={"/guest/directory"}
        >
          Direktori
        </Button>
      </Flex>
    </Flex>
  );
};

export default Header;