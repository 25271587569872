import React from "react";
import FormAktiviti from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useAktiviti } from "hooks/admin/aktiviti/useAktiviti";

export default function EditAktiviti() {
  const { aktiviti, isLoading } = useAktiviti();

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <FormAktiviti action="edit" initialData={aktiviti?.data} />;
}
