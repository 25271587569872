import { useQuery } from "@tanstack/react-query";
import { getSubKlusterDataOptions } from "services/setting/general/subKlusterService";

export function useSubKlusterDataSettings(params?: any) {
  const { isLoading, data: subKlusterData, error } = useQuery({
    queryKey: ["subKlusterData", params],
    queryFn: () => getSubKlusterDataOptions(params),
  });

  // OUTPUT
  return {
    subKlusterData,
    isLoading,
    error,
  };
}
