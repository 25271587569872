import { Icon } from "@chakra-ui/react";
import { MdBarChart } from "react-icons/md";

import MainDashboard from "views/admin/dashboard";

const reportRoutes = [
  {
    name: "Laporan",
    layout: "/admin",
    path: "/reports",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Laporan Pendapatan Mengikut Negeri, Daerah, Stesen, Dun, Parlimen",
        layout: "/admin",
        path: "/reports/pendapatan1",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Mengikut Kategori (Tanaman Jangka Pendek, Tanaman Jangka Panjang, Ternakan, Akukultur & Pemasaran)",
        layout: "/admin",
        path: "/reports/pendapatan2",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Mengikut Jenis Insentif/Bantuan",
        layout: "/admin",
        path: "/reports/pendapatan3",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Mengikut Jantina",
        layout: "/admin",
        path: "/reports/pendapatan4",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Menjana Purata Pendapatan x 30%",
        layout: "/admin",
        path: "/reports/pendapatan5",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Mengikut Bulan & Kumulatif",
        layout: "/admin",
        path: "/reports/pendapatan6",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Mengikut Tahun Penerima Insentif/Bantuan",
        layout: "/admin",
        path: "/reports/pendapatan7",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Maklumat Penerima Bantuan",
        layout: "/admin",
        path: "/reports/pendapatan8",
        component: MainDashboard,
      },
      {
        name: "Laporan Pendapatan Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/pendapatan9",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Jenis Tanaman",
        layout: "/admin",
        path: "/reports/hasiljualan1",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Jenis Insentif/Bantuan",
        layout: "/admin",
        path: "/reports/hasiljualan2",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Hasil Jualan, dan Jenis Tanaman Mengikut Negeri, Daerah, Stesen, Dun, Parlimen",
        layout: "/admin",
        path: "/reports/hasiljualan3",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Jantina",
        layout: "/admin",
        path: "/reports/hasiljualan4",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Bulan & Kumulatif",
        layout: "/admin",
        path: "/reports/hasiljualan5",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Mengikut Tahun Penerima Bantuan",
        layout: "/admin",
        path: "/reports/hasiljualan6",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Maklumat Penerima Bantuan",
        layout: "/admin",
        path: "/reports/hasiljualan7",
        component: MainDashboard,
      },
      {
        name: "Laporan Hasil Jualan Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/hasiljualan8",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Jenis Tanaman",
        layout: "/admin",
        path: "/reports/aktiviti1",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Jenis Insentif/Bantuan",
        layout: "/admin",
        path: "/reports/aktiviti2",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Hasil Jualan, dan Jenis Tanaman Mengikut Negeri, Daerah, Stesen, Dun, Parlimen",
        layout: "/admin",
        path: "/reports/aktiviti3",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Jantina",
        layout: "/admin",
        path: "/reports/aktiviti4",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Bulan & Kumulatif",
        layout: "/admin",
        path: "/reports/aktiviti5",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Mengikut Tahun Penerima Bantuan",
        layout: "/admin",
        path: "/reports/aktiviti6",
        component: MainDashboard,
      },
      {
        name: "Laporan Aktiviti Maklumat Penerima Bantuan",
        layout: "/admin",
        path: "/reports/aktiviti7",
        component: MainDashboard,
      },
      {
        name: "Laporan Perbandingan Pendapatan Mengikut Jenis Tanaman dan Penerima Bantuan Dibandingkan Pendapatan Sebelum dan Pendapatan Terkini",
        layout: "/admin",
        path: "/reports/perbandinganpendapatan1",
        component: MainDashboard,
      },
      {
        name: "Laporan Perbandingan Pendapatan Peratusan Peningkatan Pendapatan",
        layout: "/admin",
        path: "/reports/perbandinganpendapatan2",
        component: MainDashboard,
      },
      {
        name: "Laporan Perbandingan Pendapatan Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/perbandinganpendapatan3",
        component: MainDashboard,
      },
      {
        name: "Laporan Stok Tersedia Mengikut Jenis Tanaman dan Penerima Bantuan",
        layout: "/admin",
        path: "/reports/stok1",
        component: MainDashboard,
      },
      {
        name: "Laporan Stok Tersedia Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/stok2",
        component: MainDashboard,
      },
      {
        name: "Laporan Rumusan Mengikut Kumpulan Pelaksana",
        layout: "/admin",
        path: "/reports/rumusan1",
        component: MainDashboard,
      },
      {
        name: "Laporan Rumusan Pelaksana Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/rumusan2",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut Jenis Insentif/Bantuan",
        layout: "/admin",
        path: "/reports/analisisumur1",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut kateogri (Tanaman Jangka Pendek, Tanaman Jangka Panjang, Ternakan, Akukultur & Pemasaran)",
        layout: "/admin",
        path: "/reports/analisisumur2",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut Negeri, Daerah, Stesen, Dun, Parlimen",
        layout: "/admin",
        path: "/reports/analisisumur3",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut Jantina",
        layout: "/admin",
        path: "/reports/analisisumur4",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut Bulan & Kumulatif",
        layout: "/admin",
        path: "/reports/analisisumur5",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Mengikut Julat Umur (18-39, 40-59, 60-100)",
        layout: "/admin",
        path: "/reports/analisisumur6",
        component: MainDashboard,
      },
      {
        name: "Laporan Analisis Umur Jumlah Penerima Bantuan dan Bilangan Projek",
        layout: "/admin",
        path: "/reports/analisisumur7",
        component: MainDashboard,
      },
    ],
  },
];

export default reportRoutes;
