import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useUsahawans } from "hooks/admin/usahawan/useUsahawans";

const columnHelper = createColumnHelper<any>();

const CarianUsahawan = () => {
  const { isLoading, error, usahawans } = useUsahawans();

  const columns = [
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_usahawan", {
      header: () => <Text variant="tableHeading">No Kp Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("pt", {
      header: () => <Text variant="tableHeading">Pusat Tanggungjawab</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("parlimen", {
      header: () => <Text variant="tableHeading">Parlimen</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dun", {
      header: () => <Text variant="tableHeading">DUN</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("is_active", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() == 1 ? "Aktif" : "Tidak Aktif"}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikats", {
      header: () => <Text variant="tableHeading">Nama Syarikat</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("no_daftar_ssms", {
      header: () => <Text variant="tableHeading">No Daftar SSMS</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("nama_perniagaans", {
      header: () => <Text variant="tableHeading">Nama Perniagaan</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("nama_pekebuns", {
      header: () => <Text variant="tableHeading">Nama Pekebun</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("no_kp_pekebuns", {
      header: () => <Text variant="tableHeading">No Kp Pekebun</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("no_tanam_semulas", {
      header: () => <Text variant="tableHeading">No Tanam Semula</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("no_lots", {
      header: () => <Text variant="tableHeading">No Lot</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor("no_gerans", {
      header: () => <Text variant="tableHeading">No Geran</Text>,
      cell: (info: any) => (
        <Box>
          {info.getValue() ? 
          info.getValue().split(",").map((item: string, index: number) => (
            <Text key={item} fontSize="sm" variant="secondary">
              {item}
            </Text>
          ))
          : " "}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/usahawan/kemaskini/${info.row.original.user_id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/usahawan/show/${info.row.original.user_id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={usahawans}
        title={"Senarai Usahawan"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}

export default CarianUsahawan;