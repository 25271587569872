import React from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import KatalogDescription from "./components/katalog/KatalogDescription";
import KatalogAdditional from "./components/katalog/KatalogAdditional";

import FullScreenLoader from "components/loader/FullScreenLoader";

import { useCatalogue } from "hooks/admin/catalogue/useCatalogue";

const KatalogDetails = () => {
  const { catalogue, isLoading, error } = useCatalogue();

  const toast = useToast();
  const history = useHistory();

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (error) {
    toast({
      title: "Error",
      description:
        "Halaman katalog tidak dapat diakses. Sila cuba sebentar lagi.",
      status: "error",
      duration: 1000,
      isClosable: true,
    });

    history.push("/guest/katalog");
  }

  return (
    <Flex flexDirection="column" minH="100vh">
      <Header />
      <Flex
        flexDirection="column"
        gap={10}
        px={{ base: 4, md: 14 }}
        pt={10}
        pb={36}
      >
        <KatalogDescription product={catalogue?.data} />
        <KatalogAdditional userId={catalogue?.data?.user_id} productId={catalogue?.data?.id} />
      </Flex>
      <Footer />
    </Flex>
  );
};

export default KatalogDetails;
