import React from "react";
import FormCatalogue from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useCatalogue } from "hooks/admin/catalogue/useCatalogue";

export default function EditCatalogue() {
  const { catalogue, isLoading, isInitialLoading } = useCatalogue();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormCatalogue action="edit" initialData={catalogue?.data} />;
}
