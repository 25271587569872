import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getPertanianTersedias } from "services/admin/pertanianTersedia/pertanianTersediaService";

export function usePertanianTersedias(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit") ? 10 : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: pertanianTersedias,
    error,
  } = useQuery({
    queryKey: ["pertanianTersedias", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getPertanianTersedias({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, pertanianTersedias };
}