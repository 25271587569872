import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button, Icon, Text, FormControl, FormLabel } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import FileInput from "components/fields/FileInput";
import Dropzone from "views/setting/profile/components/Dropzone";

import { MdUpload } from "react-icons/md";

import useOptions from "hooks/setting/general/useOptions";

import { IAktivitiFormValues } from "entities/admin/aktiviti";
import { useEditAktiviti } from "hooks/admin/aktiviti/useEditAktiviti";
import { formatDate } from "utils/helpers";

interface FormAktivitiProps {
  action: "create" | "edit" | "show";
  initialData?: IAktivitiFormValues;
}

const FormAktiviti: React.FC<FormAktivitiProps> = ({ action, initialData }) => {
  const [photo, setPhoto] = useState(null);
  const [files, setFiles] = useState<File[]>([]);
  const [filePaths, setFilePaths] = useState<any>([]);

  const { editAktiviti, isEditing } = useEditAktiviti();

  const history = useHistory();

  const { tapakProjekOps, jenisAktivitiOps } = useOptions();

  const schema = yup
    .object({
      tapak_projek_id: yup.number().required(),
      jenis_aktiviti_id: yup.number().required(),
      deskripsi: yup.string().required(),
      tarikh_aktiviti: yup.date().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAktivitiFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_aktiviti: formatDate(initialData?.tarikh_aktiviti),
      created_by: initialData?.created_by?.name,
    },
  });

  const handleFileChange = (fileList: FileList) => {
    setFiles(Array.from(fileList));
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "edit" && !isEditing) {
      const { created_by, created_at, updated_at, ...otherData } = data;
      const newData = {
        ...otherData,
        lampiran: files.length > 0 ? files[0] : (null as File | null),
      };

      editAktiviti({
        newAktiviti: newData,
        id: `${data.id}`,
      });
    }
  });

  useEffect(() => {
    if (initialData?.file_path) {
      setFilePaths([{ file_path: initialData?.file_path }]);
    }
  }, [initialData?.file_path]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Aktiviti">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={{ sm: "6px", md: "18px" }}
              >
                <SelectField
                  label={"Tapak Projek"}
                  options={tapakProjekOps}
                  isInvalid={!!errors.tapak_projek_id}
                  errorMessage={errors.tapak_projek_id?.message}
                  readOnly
                  {...register("tapak_projek_id")}
                />

                <SelectField
                  label={"Jenis Aktiviti"}
                  options={jenisAktivitiOps}
                  isInvalid={!!errors.jenis_aktiviti_id}
                  errorMessage={errors.jenis_aktiviti_id?.message}
                  readOnly={action === "show"}
                  {...register("jenis_aktiviti_id")}
                />

                <TextField
                  id="deskripsi"
                  label="Deskripsi"
                  type="textarea"
                  isInvalid={!!errors.deskripsi}
                  errorMessage={errors.deskripsi?.message}
                  readOnly={action === "show"}
                  {...register("deskripsi")}
                />

                <TextField
                  id="tarikh_aktiviti"
                  label="Tarikh Aktiviti"
                  type="date"
                  isInvalid={!!errors.tarikh_aktiviti}
                  errorMessage={errors.tarikh_aktiviti?.message}
                  readOnly={action === "show"}
                  {...register("tarikh_aktiviti")}
                />

                <TextField
                  id="created_by"
                  label="Dibuat oleh"
                  readOnly
                  {...register("created_by")}
                />
              </SimpleGrid>

              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {/* <FormControl>
                  <FormLabel variant="main" mb="8px">
                    Gambar Aktiviti
                  </FormLabel>

                  <Dropzone
                    w={{ base: "100%" }}
                    maxH={{ base: "60%", lg: "50%", "2xl": "268px" }}
                    minH={{ base: "268px", lg: "268px", "2xl": "268px" }}
                    setPhoto={setPhoto}
                    photo={photo}
                    photoUrl={initialData?.file_path}
                    content={
                      <Box>
                        <Icon as={MdUpload} w="80px" h="80px" />
                        <Flex justify="center" mx="auto" mb="12px">
                          <Text fontSize="xl" fontWeight="700">
                            {action === "edit"
                              ? "Kemaskini Gambar"
                              : "Muat Naik Gambar"}
                          </Text>
                        </Flex>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Saiz yang disyorkan: 1280px X 960px
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Jenis Fail: JPG, PNG
                        </Text>
                        {photo && (
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color="brand.500"
                          >
                            {photo?.name ?? photo}
                          </Text>
                        )}
                      </Box>
                    }
                  />
                </FormControl> */}

                <FileInput
                  label="Lampiran"
                  files={files}
                  fileUrls={filePaths}
                  onFileChange={handleFileChange}
                  isDisabled={action === "show"}
                  multiple={false}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/aktiviti");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Aktiviti"
                    : "Daftar Aktiviti Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormAktiviti;
