import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getUsahawans } from "services/admin/usahawan/usahawanService";

export function useUsahawans(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string | string[]> = {};

  for (const [key, value] of searchParams) {
    if (key === 'negeri_id' || key === 'daerah_id' || key === 'pt_id') {
      currentSearchParams[key] = [value];
    } else {
      currentSearchParams[key] = value;
    }
  }

  const currentPage = !searchParams.get("page") ? 1 : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit") ? 10 : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: usahawans,
    error,
  } = useQuery({
    queryKey: ["usahawans", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getUsahawans({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, usahawans };
}