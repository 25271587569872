import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";

const Sorting = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    dibuat_oleh: searchParams.get("dibuat_oleh") || "",
    tarikh_aliran_from: searchParams.get("tarikh_aliran_from") || "",
    tarikh_aliran_end: searchParams.get("tarikh_aliran_end") || "",
    kategori_aliran: searchParams.get("kategori_aliran") || "",
    program_aliran: searchParams.get("program_aliran") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    history.push({ search: searchParams.toString() });
  };


  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      dibuat_oleh: "",
      tarikh_aliran_from: "",
      tarikh_aliran_end: "",
      kategori_aliran: "",
      program_aliran: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <FormLabel variant="main" mb="8px">
            Dibuat Oleh
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.dibuat_oleh }
            onChange={(e: any) => handleOnTextChange(e, "dibuat_oleh")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Mula Aliran
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_aliran_from }
            onChange={(e: any) => handleOnTextChange(e, "tarikh_aliran_from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tarikh Akhir Aliran
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="date"
            fontWeight="500"
            size="md"
            value={searchValues.tarikh_aliran_end }
            onChange={(e: any) => handleOnTextChange(e, "tarikh_aliran_end")}
          />
        </FormControl>

        <SelectField
          label={"Kategori Aliran"}
          value={searchValues.kategori_aliran}
          options={[]}
          onChange={(e: any) => handleOnChange(e, "kategori_aliran")}
        />

        <SelectField
          label={"Program Aliran"}
          value={searchValues.program_aliran}
          options={[]}
          onChange={(e: any) => handleOnChange(e, "program_aliran")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}

export default Sorting;