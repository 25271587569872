import axios from "utils/axiosInstance";

export async function getJenisPerniagaanOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-perniagaan",
    params,
  });
  return data;
}

export async function getJenisPerniagaanDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-perniagaan/all",
    params,
  });
  return data;
}


