import axios from "utils/axiosInstance";

export async function getJenisProjekOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-projek-bpk",
    params,
  });
  return data;
}

export async function getJenisProjekDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/jenis-projek-bpk/all",
    params,
  });
  return data;
}


