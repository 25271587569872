import React from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { usePertanianTersedias } from "hooks/admin/pertanian-tersedia/usePertanianTersedias";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<any>();

export default function PertanianTersedia() {
  const { isLoading, error, pertanianTersedias } = usePertanianTersedias();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_usahawan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("emel_usahawan", {
      id: "emel",
      header: () => <Text variant="tableHeading">Emel</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_fon_usahawan", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_jenis_bantuan", {
      header: () => <Text variant="tableHeading">Jenis Bantuan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_kategori_projek", {
      header: () => <Text variant="tableHeading">Kategori Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_subkategori_projek", {
      header: () => <Text variant="tableHeading">Sub Kategori</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek", {
      header: () => <Text variant="tableHeading">Nama Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_hasil", {
      header: () => <Text variant="tableHeading">Nama Hasil</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tapak", {
      header: () => <Text variant="tableHeading">Tapak</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kuantiti", {
      header: () => <Text variant="tableHeading">Kuantiti</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("unit_ukuran", {
      header: () => <Text variant="tableHeading">Unit Ukuran</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_tersedia", {
      header: () => <Text variant="tableHeading">Tarikh Tersedia</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() ? formatDate(info.getValue(), "dd-MM-yyyy") : null}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink
            to={`/admin/pertanian-tersedia/show/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={pertanianTersedias}
        title={"Senarai Pertanian Tersedia"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
}
