import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "navigation/PrivateRoute";
import AuthLayout from "components/layouts/auth";
import AdminLayout from "components/layouts/admin";
import GuestLayout from "components/layouts/guest";

import "assets/css/App.css";
import "leaflet/dist/leaflet.css";
import theme from "theme/theme";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 3000,
    },
  },
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Router>
          <Switch>
            <PrivateRoute path={`/admin`} component={AdminLayout} />
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/guest`} component={GuestLayout} />
            <Redirect from="/" to="/admin" />
          </Switch>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
