import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Checkbox,
  Input,
  Text,
  VStack,
  InputGroup,
  InputRightElement,
  Button,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";

const stateOptions = [
  { value: "1", label: "Johor" },
  { value: "2", label: "Kedah" },
  { value: "3", label: "Kelantan" },
  { value: "4", label: "Melaka" },
  { value: "5", label: "Negeri Sembilan" },
  { value: "6", label: "Pahang" },
  { value: "7", label: "Pulau Pinang" },
  { value: "8", label: "Perak" },
  { value: "9", label: "Perlis" },
  { value: "10", label: "Selangor" },
  { value: "11", label: "Terengganu" },
  { value: "12", label: "Sabah" },
  { value: "13", label: "Sarawak" },
  { value: "14", label: "Kuala Lumpur" },
  { value: "15", label: "Labuan" },
  { value: "16", label: "Putrajaya" },
];

const DirectoryFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    search: "",
    negeri_id: null,
  });

  useEffect(() => {
    const negeriIds = searchParams.getAll("negeri_id");
    setSearchValues({
      search: searchParams.get("search") || "",
      negeri_id: negeriIds.length > 0 ? negeriIds : null,
    });
  }, [location.search]);

  const handleCheckboxChange = (e: any) => {
    const { value, checked } = e.target;
    setSearchValues((prevState) => {
      const newNegeriId = checked
        ? [...(prevState.negeri_id || []), value]
        : prevState.negeri_id.filter((id: string) => id !== value);
      return { ...prevState, negeri_id: newNegeriId };
    });
  };

  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setSearchValues((prevState) => ({ ...prevState, search: value }));
  };

  const updateSearchParams = () => {
    const newSearchParams = new URLSearchParams();
    if (searchValues.negeri_id) {
      searchValues.negeri_id.forEach((id: string) => newSearchParams.append("negeri_id", id));
    }
    if (searchValues.search) {
      newSearchParams.set("search", searchValues.search);
    }
    history.push({ search: newSearchParams.toString() });
  };

  const resetFilters = () => {
    setSearchValues({ search: "", negeri_id: null });
    history.push({ search: "" });
  };

  return (
    <Box width={{ base: "100%", md: "30%" }} px={4}>
      <InputGroup w={"100%"}>
        <Input
          value={searchValues.search}
          onChange={handleInputChange}
          placeholder="Carian usahawan"
          mb={4}
          fontSize={"sm"}
          _focus={{ borderColor: "green.500" }}
        />
        {searchValues.search  && (
          <InputLeftElement>
            <Button
              h="100%"
              size="sm"
              bg="transparent"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              onClick={resetFilters}
            >
              <CloseIcon color="gray.500" />
            </Button>
          </InputLeftElement>
        )}
        <InputRightElement>
          <Button
            h="100%"
            size="sm"
            bg="green.500"
            color="white"
            _hover={{ bg: "green.600" }}
            _active={{ bg: "green.700", transform: "none", borderColor: "transparent" }}
            _focus={{ boxShadow: "none" }}
            borderLeftRadius={"0px"}
            onClick={updateSearchParams}
          >
            <SearchIcon color={"white"} />
          </Button>
        </InputRightElement>
      </InputGroup>

      <Card border="1px solid #E2E8F0" borderRadius="lg" boxShadow="md" p={6}>
        <Text fontWeight="bold" mb={4}>
          Senarai Negeri
        </Text>
        <VStack align="start">
          {stateOptions.map((state) => (
            <Checkbox
              key={state.value}
              fontSize={"sm"}
              colorScheme="green"
              textColor={"gray.600"}
              value={state.value}
              isChecked={searchValues.negeri_id?.includes(state.value) ?? false}
              onChange={handleCheckboxChange}
            >
              {state.label}
            </Checkbox>
          ))}
        </VStack>
      </Card>
    </Box>
  );
};

export default DirectoryFilter;