import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";

import { formatDate } from "utils/helpers";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";

import { IPerniagaanFormValues } from "entities/admin/usahawan";

interface FormPerniagaanProps {
  action: "create" | "edit" | "show";
  initialData?: IPerniagaanFormValues;
  negeriData?: any;
  daerahData?: any;
  bandarData?: any;
  dunData?: any;
  parlimenData?: any;
}

const schema = yup
  .object({
    nama_perniagaan: yup.string().notRequired(),
  })
  .required();

const FormPerniagaan: React.FC<FormPerniagaanProps> = ({
  action,
  initialData,
  negeriData,
  daerahData,
  bandarData,
  dunData,
  parlimenData,
}) => {
  const [negeriOps, setNegeriOps] = useState<any>(negeriData ?? []);
  const [daerahOps, setDaerahOps] = useState<any>([]);
  const [bandarOps, setBandarOps] = useState<any>([]);
  const [parlimenOps, setParlimenOps] = useState<any>([]);
  const [dunOps, setDunOps] = useState<any>([]);

  const history = useHistory();

  const { statusSyarikatOps } = useOptions();
  const { editUsahawan, isEditing } = useEditUsahawan();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IPerniagaanFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_mula_niaga: formatDate(initialData?.tarikh_mula_niaga),
    },
  });

  const watchNegeri = watch("negeri_id");
  const watchDaerah = watch("daerah_id");
  const watchParlimen = watch("parlimen_id");

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        perniagaans: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "negeri_id" && daerahData) {
        setValue("daerah_id", null);
        setValue("parlimen_id", null);

        const daerah = daerahData
          .filter((daerah: any) => daerah.u_negeri_id == value.negeri_id)
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        setDaerahOps(daerah);
      }

      if (name === "negeri_id" && bandarData) {
        setValue("bandar_id", null);

        const bandar = bandarData.filter(
          (bandar: any) => bandar.negeri_id == value.negeri_id
        );

        setBandarOps(bandar);
      }

      if (name === "negeri_id" && parlimenData) {
        setValue("parlimen_id", null);

        const parlimen = parlimenData.filter(
          (parlimen: any) => parlimen.negeri_id == value.negeri_id
        );

        setParlimenOps(parlimen);
      }

      if (name === "parlimen_id" && dunData) {
        setValue("dun_id", null);

        const dun = dunData.filter(
          (dun: any) => dun.parlimen_id == value.parlimen_id
        );

        setDunOps(dun);
      }
    });

    return () => subscription.unsubscribe();
  }, [negeriData, daerahData, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (daerahData) {
        const daerah = daerahData
          .filter((daerah: any) => daerah.u_negeri_id == initialData.negeri_id)
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        setDaerahOps(daerah);
      }

      if (bandarData) {
        const bandar = bandarData.filter(
          (bandar: any) => bandar.negeri_id == initialData.negeri_id
        );

        setBandarOps(bandar);
      }

      if (parlimenData) {
        const parlimen = parlimenData.filter(
          (parlimen: any) => parlimen.negeri_id == initialData.negeri_id
        );

        setParlimenOps(parlimen);
      }

      if (dunData) {
        const dun = dunData.filter(
          (dun: any) => dun.parlimen_id == initialData.parlimen_id
        );

        setDunOps(dun);
      }
    }
  }, [initialData, daerahData, bandarData, parlimenData, dunData]);

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="nama_perniagaan"
            label="Nama Perniagaan"
            errorMessage={errors.nama_perniagaan?.message}
            isInvalid={!!errors.nama_perniagaan}
            readOnly={action === "show"}
            {...register("nama_perniagaan")}
          />

          <TextField
            id="tarikh_mula_niaga"
            label="Tarikh Mula Niaga"
            type="date"
            errorMessage={errors.tarikh_mula_niaga?.message}
            isInvalid={!!errors.tarikh_mula_niaga}
            readOnly={action === "show"}
            {...register("tarikh_mula_niaga")}
          />

          <TextField
            id="bilangan_pekerja"
            label="Bilangan Pekerja"
            errorMessage={errors.bilangan_pekerja?.message}
            isInvalid={!!errors.bilangan_pekerja}
            readOnly={action === "show"}
            {...register("bilangan_pekerja")}
          />

          <TextField
            id="qty_dropshipper"
            label="Bilangan Dropshipper"
            errorMessage={errors.qty_dropshipper?.message}
            isInvalid={!!errors.qty_dropshipper}
            readOnly={action === "show"}
            {...register("qty_dropshipper")}
          />

          <TextField
            id="qty_ejen"
            label="Bilangan Ejen"
            errorMessage={errors.qty_ejen?.message}
            isInvalid={!!errors.qty_ejen}
            readOnly={action === "show"}
            {...register("qty_ejen")}
          />

          <TextField
            id="qty_stokis"
            label="Bilangan Stokis"
            errorMessage={errors.qty_stokis?.message}
            isInvalid={!!errors.qty_stokis}
            readOnly={action === "show"}
            {...register("qty_stokis")}
          />

          <TextField
            id="qty_premis"
            label="Bilangan Premis"
            errorMessage={errors.qty_premis?.message}
            isInvalid={!!errors.qty_premis}
            readOnly={action === "show"}
            {...register("qty_premis")}
          />

          <TextField
            id="pasaran_domestik"
            label="Pasaran Domestik"
            errorMessage={errors.pasaran_domestik?.message}
            isInvalid={!!errors.pasaran_domestik}
            readOnly={action === "show"}
            {...register("pasaran_domestik")}
          />

          <TextField
            id="pasaran_luar_negara"
            label="Pasaran Luar Negara"
            errorMessage={errors.pasaran_luar_negara?.message}
            isInvalid={!!errors.pasaran_luar_negara}
            readOnly={action === "show"}
            {...register("pasaran_luar_negara")}
          />

          <TextField
            id="pasaran_online"
            label="Pasaran Online"
            errorMessage={errors.pasaran_online?.message}
            isInvalid={!!errors.pasaran_online}
            readOnly={action === "show"}
            {...register("pasaran_online")}
          />

          <TextField
            id="alamat_perniagaan_1"
            label="Alamat 1"
            errorMessage={errors.alamat_perniagaan_1?.message}
            isInvalid={!!errors.alamat_perniagaan_1}
            readOnly={action === "show"}
            {...register("alamat_perniagaan_1")}
          />

          <TextField
            id="alamat_perniagaan_2"
            label="Alamat 2"
            errorMessage={errors.alamat_perniagaan_2?.message}
            isInvalid={!!errors.alamat_perniagaan_2}
            readOnly={action === "show"}
            {...register("alamat_perniagaan_2")}
          />

          <TextField
            id="alamat_perniagaan_3"
            label="Alamat 3"
            errorMessage={errors.alamat_perniagaan_3?.message}
            isInvalid={!!errors.alamat_perniagaan_3}
            readOnly={action === "show"}
            {...register("alamat_perniagaan_3")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            isDisabled={!watchNegeri}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            isDisabled={!watchDaerah}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            isDisabled={!watchNegeri}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            isDisabled={!watchParlimen}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <TextField
            id="no_fon_perniagaan"
            label="No. Fon Perniagaan"
            errorMessage={errors.no_fon_perniagaan?.message}
            isInvalid={!!errors.no_fon_perniagaan}
            readOnly={action === "show"}
            {...register("no_fon_perniagaan")}
          />

          <TextField
            id="no_hp_perniagaan"
            label="No. HP Perniagaan"
            errorMessage={errors.no_hp_perniagaan?.message}
            isInvalid={!!errors.no_hp_perniagaan}
            readOnly={action === "show"}
            {...register("no_hp_perniagaan")}
          />

          <TextField
            id="email_perniagaan"
            label="Email Perniagaan"
            errorMessage={errors.email_perniagaan?.message}
            isInvalid={!!errors.email_perniagaan}
            readOnly={action === "show"}
            {...register("email_perniagaan")}
          />

          <TextField
            id="catatan_perniagaan"
            label="Catatan Perniagaan"
            errorMessage={errors.catatan_perniagaan?.message}
            isInvalid={!!errors.catatan_perniagaan}
            readOnly={action === "show"}
            {...register("catatan_perniagaan")}
          />

          <TextField
            id="koordinat"
            label="Koordinat Perniagaan"
            errorMessage={errors.koordinat?.message}
            isInvalid={!!errors.koordinat}
            readOnly={action === "show"}
            {...register("koordinat")}
          />

          <TextField
            id="facebook"
            label="Facebook"
            errorMessage={errors.facebook?.message}
            isInvalid={!!errors.facebook}
            readOnly={action === "show"}
            {...register("facebook")}
          />

          <TextField
            id="instagram"
            label="Instagram"
            errorMessage={errors.instagram?.message}
            isInvalid={!!errors.instagram}
            readOnly={action === "show"}
            {...register("instagram")}
          />

          <TextField
            id="twitter"
            label="Twitter"
            errorMessage={errors.twitter?.message}
            isInvalid={!!errors.twitter}
            readOnly={action === "show"}
            {...register("twitter")}
          />

          <TextField
            id="laman_web"
            label="Laman Web"
            errorMessage={errors.laman_web?.message}
            isInvalid={!!errors.laman_web}
            readOnly={action === "show"}
            {...register("laman_web")}
          />

          <SelectField
            label={"Status Perniagaan"}
            options={statusSyarikatOps}
            errorMessage={errors.status_syarikat_perniagaan_id?.message}
            isInvalid={!!errors.status_syarikat_perniagaan_id}
            readOnly={action === "show"}
            {...register("status_syarikat_perniagaan_id")}
          />

          <TextField
            id="prefix"
            label="Prefix"
            errorMessage={errors.prefix?.message}
            isInvalid={!!errors.prefix}
            readOnly={action === "show"}
            {...register("prefix")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            isDisabled={isEditing}
          >
            Kemaskini Maklumat Perniagaan
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormPerniagaan;
