import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { publishNotification } from "services/setting/notifikasiService";

export function usePublishNotification() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: publishNotifications, isLoading: isPublishing } = useMutation({
    mutationFn: ({
      id,
    }: {
      id: string;
    }) => publishNotification(id),
    onSuccess: () => {
      toast({
        title: "Notification successfully updated",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["notifikasi"] });
      history.push("/admin/settings/notifications");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isPublishing, publishNotifications };
}
