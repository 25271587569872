import React from "react";
import { Flex, Text, Button, ButtonGroup } from "@chakra-ui/react";

interface SimplePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const SimplePagination: React.FC<SimplePaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      gap={6}
      justifyContent={{ base: "center", md: "end" }}
      alignItems={"center"}
    >
      {/* <Text>
        Papar <Text as="span" fontWeight="bold" color="green.500">1 - 15</Text> dari <Text as="span" fontWeight="bold" color="green.500">200</Text> katalog
      </Text> */}

      <ButtonGroup size="sm" isAttached spacing={4}>
        <Button
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Sebelum
        </Button>
        {[...Array(Math.min(3, totalPages))].map((_, index) => (
          <Button
            key={index}
            colorScheme={currentPage === index + 1 ? "green" : undefined}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
        >
          Seterusnya
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default SimplePagination;
