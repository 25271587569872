import React from "react";
import { Flex } from "@chakra-ui/react";

interface ContainerProps {
  flexDirection?: any;
  children: React.ReactNode;
}

const Container = ({ children, flexDirection = "column" }: ContainerProps) => {
  return (
    <Flex
      flexDirection={flexDirection}
      gap={10}
      px={{ base: 4, md: 14 }}
      pt={10}
      pb={36}
    >
      {children}
    </Flex>
  );
};

export default Container;
