import { useQuery } from "@tanstack/react-query";
import { getJenisPerniagaanDataOptions } from "services/setting/general/jenisPerniagaanService";

export function useJenisPerniagaanDataSettings(params?: any) {
  const { isLoading, data: jenisPerniagaanData, error } = useQuery({
    queryKey: ["jenisPerniagaanData", params],
    queryFn: () => getJenisPerniagaanDataOptions(params),
  });

  // OUTPUT
  return {
    jenisPerniagaanData,
    isLoading,
    error,
  };
}
