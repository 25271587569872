import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getAktiviti } from "services/admin/aktiviti/aktivitiService";

export function useAktiviti() {
  const { aktivitiId } = useParams<{ aktivitiId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: aktiviti,
    error,
  } = useQuery({
    queryKey: ["aktiviti", aktivitiId],
    queryFn: () => getAktiviti(aktivitiId),
    enabled: !!aktivitiId,
  });

  return { isLoading, isInitialLoading, error, aktiviti };
}

