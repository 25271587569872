import axios from "utils/axiosInstance";

export async function getUsahawan(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/usahawan/show/${id}`,
  });
  return data;
}

export async function getUsahawans(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/usahawan",
    params,
  });
  return data;
}

export async function getPublicUsahawans(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/usahawan/guest",
    params,
    public: true,
  });
  return data;
}

export async function createEditUsahawan(
  values: any,
  id: string | number | null
) {
  const apiUrl = id ? `/usahawan/update` : "/usahawan";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}