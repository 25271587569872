import axios from "utils/axiosInstance";

export async function getTindakanLanjutOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/tindakan-lanjut",
    params,
  });
  return data;
}

export async function getTindakanLanjutOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/tindakan-lanjut/${id}`,
  });
  return data;
}
