import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const {
    tahapKebenaranOps,
    negeriOps,
    pusatTanggungjawabOps,
    daerahOps,
    statusUsahawanOps,
  } = useOptions();

  const [searchValues, setSearchValues] = useState({
    role_id: searchParams.get("role_id") || "",
    tahap_kebenaran_id: searchParams.get("tahap_kebenaran_id") || "",
    negeri_id: searchParams.get("negeri_id") || "",
    pusat_tanggungjawab_id: searchParams.get("pusat_tanggungjawab_id") || "",
    daerah_id: searchParams.get("daerah_id") || "",
    nama_pengguna: searchParams.get("nama_pengguna") || "",
    email: searchParams.get("email") || "",
    status_id: searchParams.get("status_id") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      role_id: "",
      tahap_kebenaran_id: "",
      negeri_id: "",
      pusat_tanggungjawab_id: "",
      daerah_id: "",
      nama_pengguna: "",
      email: "",
      status_id: "",
    });

    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Nama Peranan"}
            value={searchValues.role_id}
            options={[]}
            onChange={(e: any) => handleOnChange(e, "role_id")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Tahap Kebenaran"}
            value={searchValues.tahap_kebenaran_id}
            options={tahapKebenaranOps}
            onChange={(e: any) => handleOnChange(e, "tahap_kebenaran_id")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Negeri Kebenaran"}
            value={searchValues.negeri_id}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_id")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab Kebenaran"}
            value={searchValues.pusat_tanggungjawab_id}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pusat_tanggungjawab_id")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah Kebenaran"}
            value={searchValues.daerah_id}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_id")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Pengguna
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_pengguna}
            onChange={(e: any) => handleOnTextChange(e, "nama_pengguna")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Emel
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="ramli@email.com"
            fontWeight="500"
            size="md"
            value={searchValues.email}
            onChange={(e: any) => handleOnTextChange(e, "email")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Status"}
            value={searchValues.status_id}
            options={statusUsahawanOps}
            onChange={(e: any) => handleOnChange(e, "status_id")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
