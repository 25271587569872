import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useJenisInsentifDataSettings } from "hooks/setting/general/jenisInsentif/useJenisInsentifData";
import { useJenisPerniagaanDataSettings } from "hooks/setting/general/jenisPerniagaan/useJenisPerniagaanData";
import { useKlusterDataSettings } from "hooks/setting/general/kluster/useKlusterData";
import { useSubKlusterDataSettings } from "hooks/setting/general/subKluster/useSubKlusterData";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { negeriOps, pusatTanggungjawabOps, daerahOps, parlimenOps, dunOps } =
    useOptions();
  const { jenisInsentifData, isLoading: isLoadingJenisInsentif } = useJenisInsentifDataSettings();
  const { jenisPerniagaanData, isLoading: isLoadingJenisPerniagaan } = useJenisPerniagaanDataSettings();
  const { klusterData, isLoading: isLoadingKluster } = useKlusterDataSettings();
  const { subKlusterData, isLoading: isLoadingSubKluster } = useSubKlusterDataSettings();

  const [searchValues, setSearchValues] = useState({
    jenis_insentif: searchParams.get("jenis_insentif") || "",
    jenis_perniagaan: searchParams.get("jenis_perniagaan") || "",
    kluster: searchParams.get("kluster") || "",
    sub_kluster: searchParams.get("sub_kluster") || "",
    capai_sasaran: searchParams.get("capai_sasaran") || "",
    kategori_usahawan_id: searchParams.get("kategori_usahawan_id") || "",
    negeri_kebenaran: searchParams.get("negeri_kebenaran") || "",
    pt_kebenaran: searchParams.get("pt_kebenaran") || "",
    daerah_kebenaran: searchParams.get("daerah_kebenaran") || "",
    parlimen_kebenaran: searchParams.get("parlimen_kebenaran") || "",
    dun_kebenaran: searchParams.get("dun_kebenaran") || "",
    tahun_bantuan: searchParams.get("tahun_bantuan") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      jenis_insentif: "",
      jenis_perniagaan: "",
      kluster: "",
      sub_kluster: "",
      capai_sasaran: "",
      kategori_usahawan_id: "",
      negeri_kebenaran: "",
      pt_kebenaran: "",
      daerah_kebenaran: "",
      parlimen_kebenaran: "",
      dun_kebenaran: "",
      tahun_bantuan: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  if (isLoadingJenisInsentif || isLoadingJenisPerniagaan || isLoadingKluster || isLoadingSubKluster) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Jenis Insentif"}
            value={searchValues.jenis_insentif}
            options={jenisInsentifData?.data}
            onChange={(e: any) => handleOnChange(e, "jenis_insentif")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Jenis Perniagaan"}
            value={searchValues.jenis_perniagaan}
            options={jenisPerniagaanData?.data}
            onChange={(e: any) => handleOnChange(e, "jenis_perniagaan")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Kluster"}
            value={searchValues.kluster}
            options={klusterData?.data}
            onChange={(e: any) => handleOnChange(e, "kluster")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Sub Kluster"}
            value={searchValues.sub_kluster}
            options={subKlusterData?.data}
            onChange={(e: any) => handleOnChange(e, "sub_kluster")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Capai Sasaran"}
            value={searchValues.capai_sasaran}
            options={[
              {
                value: "1",
                label: "Ya",
              },
              {
                value: "0",
                label: "Tidak",
              },
            ]}
            onChange={(e: any) => handleOnChange(e, "capai_sasaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Kategori Usahawan"}
            value={searchValues.kategori_usahawan_id}
            options={[
              {
                value: "1",
                label: "Ya",
              },
              {
                value: "0",
                label: "Tidak",
              },
            ]}
            onChange={(e: any) => handleOnChange(e, "kategori_usahawan_id")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Negeri"}
            value={searchValues.negeri_kebenaran}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            value={searchValues.pt_kebenaran}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pt_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            value={searchValues.daerah_kebenaran}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Parlimen"}
            value={searchValues.parlimen_kebenaran}
            options={parlimenOps}
            onChange={(e: any) => handleOnChange(e, "parlimen_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Dun"}
            value={searchValues.dun_kebenaran}
            options={dunOps}
            onChange={(e: any) => handleOnChange(e, "dun_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Tahun Bantuan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="number"
            fontWeight="500"
            size="md"
            value={searchValues.tahun_bantuan}
            onChange={(e: any) => handleOnTextChange(e, "tahun_bantuan")}
            onKeyPress={(e: any) => {
              if (e.target.value.length === 4) {
                e.preventDefault();
              }
            }}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
