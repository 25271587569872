import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { ICourseTable } from "entities/admin/kursus";
import { useCourses } from "hooks/admin/kursus/useCourses";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<ICourseTable>();

export default function Kursus() {
  const { isLoading, error, kursuss } = useCourses();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_kursus", {
      header: () => <Text variant="tableHeading">Nama Kursus</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("penganjur", {
      header: () => <Text variant="tableHeading">Penganjur</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_mula", {
      id: "tarikh_mula",
      header: () => <Text variant="tableHeading">Tarikh Mula</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() ? formatDate(info.getValue(), 'dd-MM-yyyy') : null}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>

          <NavLink to={`/admin/kursus/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={kursuss}
        title={"Senarai Kursus"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
