import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getRole } from "services/setting/roleService";

export function useRole() {
  const { roleId } = useParams<{ roleId: string }>();
  const toast = useToast();

  const {
    isLoading,
    data: role,
    error,
  } = useQuery({
    queryKey: ["role", roleId],
    queryFn: () => getRole(roleId),
    onError: (err) => {
      toast({
        title: "Error fetching data",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, role };
}