import { IRoleFormValues } from "entities/setting/role";
import axios from "utils/axiosInstance";

export async function getRole(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/role/show/${id}`,
  });
  return data;
}

export async function getRoles(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/role",
    params,
  });
  return data;
}


export async function createEditRole(
  values: IRoleFormValues,
  id: string | null
) {
  const apiUrl = id ? `/role/update` : "/role";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}
