import React from "react";
import { Box, Text, List, Icon, Badge } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useLawatans } from "hooks/admin/lawatan/useLawatans";
import { ILawatanTable } from "entities/admin/lawatan";

import { formatDate } from "utils/helpers";

const getStatusDescription = (status: string = ''): JSX.Element => {
  switch (String(status).toLowerCase()) {
    case "pengesahan usahawan":
      return <Badge colorScheme="orange">Pengesahan Usahawan</Badge>;
    case "disahkan":
      return <Badge colorScheme="green">Disahkan</Badge>;
    case "pengesahan pegawai":
      return <Badge colorScheme="yellow">Pengesahan Pegawai</Badge>;
    default:
      return <Badge colorScheme="orange">Pengesahan Usahawan</Badge>;
  }
};

const columnHelper = createColumnHelper<ILawatanTable>();

export default function Lawatan() {
  const { isLoading, error, lawatans } = useLawatans();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("pt", {
      header: () => <Text variant="tableHeading">Pusat Tanggungjawab</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kad_pengenalan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pegawai", {
      header: () => <Text variant="tableHeading">Nama Pegawai</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon_pegawai", {
      header: () => <Text variant="tableHeading">No Telefon Pegawai</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_dibuat", {
      header: () => <Text variant="tableHeading">Tarikh Dibuat</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_lawatan", {
      header: () => <Text variant="tableHeading">Tarikh Lawatan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy hh:mm a")}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {getStatusDescription(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("tindakan_lanjut", {
      header: () => <Text variant="tableHeading">Tindakan Lanjut</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/lawatan/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/lawatan/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={lawatans}
        title={"Senarai Lawatan"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
}
