import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getUsahawan } from "services/admin/usahawan/usahawanService";

export function useUsahawan() {
  const { usahawanId } = useParams<{ usahawanId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: usahawan,
    error,
  } = useQuery({
    queryKey: ["usahawan", usahawanId],
    queryFn: () => getUsahawan(usahawanId),
    enabled: !!usahawanId,
  });

  return { isLoading, isInitialLoading, error, usahawan };
}

