import { Redirect, Route, Switch } from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import routes from "navigation/routes";

export default function Guest() {
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: any) => {
      if (route.layout !== "/auth" && route.layout !== "/admin") {
        return route.items ? (
          route.items.map((item) => (
            <Route
              exact
              path={item.layout + item.path}
              component={item.component}
              key={item.path}
            />
          ))
        ) : (
          <Route
            exact
            path={route.layout + route.path}
            component={route.component}
            key={route.path}
          />
        );
      }
      return null;
    });
  };
  const authBg = useColorModeValue("white", "navy.900");
  document.documentElement.dir = "ltr";
  return (
    <Box>
      <Box
        bg={authBg}
        float="right"
        minHeight="100vh"
        height="100%"
        position="relative"
        w="100%"
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        <Box mx="auto" minH="100vh">
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/guest" to="/guest/katalog" />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
}