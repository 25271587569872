import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getPertanianTersedia } from "services/admin/pertanianTersedia/pertanianTersediaService";

export function usePertanianTersedia() {
  const { pertanianTersediaId } = useParams<{ pertanianTersediaId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: pertanianTersedia,
    error,
  } = useQuery({
    queryKey: ["pertanianTersedia", pertanianTersediaId],
    queryFn: () => getPertanianTersedia(pertanianTersediaId),
    enabled: !!pertanianTersediaId,
  });

  return { isLoading, isInitialLoading, error, pertanianTersedia };
}

