import React from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useInsentifBPUs } from "hooks/admin/insentif/bpu/useInsentifBPUs";

import { IInsentifBPU } from "entities/admin/insentif/bpu";

const columnHelper = createColumnHelper<IInsentifBPU>();

const InsentifBPU = () => {
  const { isLoading, error, insentifBPUs } = useInsentifBPUs();

  const columns = [
    columnHelper.accessor("nama", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kad_pengenalan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("emel", {
      header: () => <Text variant="tableHeading">Emel</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_insentif", {
      header: () => <Text variant="tableHeading">Jenis Insentif</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_perniagaan", {
      header: () => <Text variant="tableHeading">Jenis Perniagaan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kluster", {
      header: () => <Text variant="tableHeading">Kluster</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("sub_kluster", {
      header: () => <Text variant="tableHeading">Sub-Kluster</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("capai_sasaran", {
      header: () => <Text variant="tableHeading">Capai Sasaran</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kategori_usahawan", {
      header: () => <Text variant="tableHeading">Kategori Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri_kebenaran", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("pt_kebenaran", {
      header: () => <Text variant="tableHeading">Pusat Tanggungjawab</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("daerah_kebenaran", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("parlimen", {
      header: () => <Text variant="tableHeading">Parlimen</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dun", {
      header: () => <Text variant="tableHeading">Dun</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tahun_bantuan", {
      header: () => <Text variant="tableHeading">Tahun Bantuan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/insentif/bpu/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={insentifBPUs}
        title={"Senarai Insentif BPU"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}

export default InsentifBPU;