import { useQuery } from "@tanstack/react-query";
import { getAudits } from "services/setting/auditService";

export function useAudits() {
  const {
    isLoading,
    data: audits,
    error,
  } = useQuery({
    queryKey: ["audits"],
    queryFn: getAudits,
    refetchOnWindowFocus: false,
  });

  return { isLoading, error, audits };
}
