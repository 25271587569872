import { useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import routes from "navigation/routes";
import Footer from "components/footer/FooterAdmin";
import Navbar from "components/navbar/NavbarAdmin";
import Sidebar from "components/sidebar/Sidebar";

export default function AdminLayout(props: { [x: string]: any }) {
  const { ...rest } = props;
  const [fixed] = useState(false);

  const location = useLocation();
  const currentRoute = location.pathname;

  const getActiveRoute = (routes: RoutesType[]): string => {
    const defaultRoute = "Default Brand Text";

    const activeRoute = routes
      .flatMap((route) => (route.items ? route.items : [route]))
      .find((route) => {
        const routePath = route.layout + route.path;

        if (routePath.includes(":")) {
          const routePathWithoutParam = routePath.replace(/:\w+/g, "");
          return currentRoute.startsWith(routePathWithoutParam);
        }

        return currentRoute === routePath;
      });

    return activeRoute ? activeRoute.name : defaultRoute;
  };

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    const activeNavbar = routes.find((route) => {
      const routePath = route.layout + route.path;
      return currentRoute === routePath;
    });

    return activeNavbar ? activeNavbar.secondary : false;
  };

  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const activeNavbar = routes.find((route) => {
      const routePath = route.layout + route.path;
      return currentRoute === routePath;
    });

    return activeNavbar ? activeNavbar.name : false;
  };

  const getRoutes = (routes: RoutesType[]): JSX.Element[] => {
    return routes.flatMap((route) => {
      if (route.layout !== "/auth" && route.layout !== "/guest") {
        return route.items ? (
          route.items.map((item) => (
            <Route
              exact
              path={item.layout + item.path}
              component={item.component}
              key={item.path}
            />
          ))
        ) : (
          <Route
            exact
            path={route.layout + route.path}
            component={route.component}
            key={route.path}
          />
        );
      }
      return null;
    });
  };

  const { onOpen } = useDisclosure();

  return (
    <Box>
      <Sidebar routes={routes} display="none" {...rest} />
      <Box
        float="right"
        minHeight="100vh"
        height="100%"
        overflow="auto"
        position="relative"
        maxHeight="100%"
        w={{ base: "100%", xl: "calc( 100% - 290px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        transitionTimingFunction="linear, linear, ease"
      >
        <Portal>
          <Box>
            <Navbar
              onOpen={onOpen}
              logoText={"Horizon UI Dashboard PRO"}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              message={getActiveNavbarText(routes)}
              fixed={fixed}
              {...rest}
            />
          </Box>
        </Portal>

        <Box
          mx="auto"
          p={{ base: "20px", md: "30px" }}
          pe="20px"
          minH="100vh"
          pt="50px"
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/" to="/admin/dashboards" />
          </Switch>
        </Box>

        <Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
