import React from "react";
import { Box, Text, List, Icon, Button } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdFileDownload } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from './components/Sorting'

import { useDokumenPerniagaan } from "hooks/admin/kewangan/useDokumenPerniagaan";

import { IDokumenPerniagaan } from "entities/admin/kewangan";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IDokumenPerniagaan>();

const DokumenPerniagaan = () => {
  const { dokumenPerniagaan, isLoading } = useDokumenPerniagaan();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_dokumen", {
      header: () => <Text variant="tableHeading">Jenis Dokumen</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_dokumen", {
      header: () => <Text variant="tableHeading">Tarikh Dokumen</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy hh:mm a")}
        </Text>
      ),
    }),
    columnHelper.accessor("amaun", {
      header: () => <Text variant="tableHeading">Amaun</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikat_perniagaan", {
      header: () => (
        <Text variant="tableHeading">Nama Syarikat/ Perniagaan</Text>
      ),
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          {info?.row?.original?.download_url && (
            <Button
              onClick={() =>
                window.open(`https://${info?.row?.original?.download_url}`, "_blank")
              }
              colorScheme="green"
              variant="outline"
              fontSize="sm"
              leftIcon={
                <Icon w="20px" h="20px" color={"green.500"} as={MdFileDownload} />
              }
            >
              Lampiran
            </Button>
          )}
        </List>
      ),
    }),
  ];

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={dokumenPerniagaan}
        title={"Senarai Janaan Dokumen Perniagaan"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
};

export default DokumenPerniagaan;
