import axios from "utils/axiosInstance";

export async function getKodKumpulanProjekOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kod-kumpulan-projek",
    params,
  });
  return data;
}

export async function getKodKumpulanProjekDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kod-kumpulan-projek/all",
    params,
  });
  return data;
}


