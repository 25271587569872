import { Icon } from "@chakra-ui/react";
import { MdLock } from "react-icons/md";

import WebKatalog from "views/guest";
import WebKatalogDetails from "views/guest/KatalogDetails";
import WebDirectory from "views/guest/Directory";

const guestRoutes = [
  {
    name: "Web Katalog",
    layout: "/guest",
    path: "/katalog",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: WebKatalog,
  },
  {
    name: "Katalog Details",
    layout: "/guest",
    path: "/katalog/:katalogId",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: WebKatalogDetails,
  },
  {
    name: "Web Direktori",
    layout: "/guest",
    path: "/directory",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: WebDirectory,
  },
];

export default guestRoutes;
