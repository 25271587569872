import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Flex,
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import TextField from "components/fields/TextField";
import Section from "components/section/Section";

import { IPassword } from "entities/setting/password";

import { useResetUsahawanPassword } from "hooks/setting/password/useResetUsahawanPassword";

interface FormTetapanProps {
  action: "edit" | "show";
  usahawanId?: number;
  isActive?: string;
}

const schema = yup
  .object({
    status: yup.string().notRequired(),
  })
  .required();

const FormTetapan: React.FC<FormTetapanProps> = ({
  action,
  usahawanId = null,
  isActive = '0',
}) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isMatch, setIsMatch] = useState(false);

  const history = useHistory();
  const { resetCurrentUsahawanPassword, isResetting } =
    useResetUsahawanPassword();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IPassword>({
    resolver: yupResolver(schema) as any,
  });

  const password = watch("new_password");

  const toggleShowPassword = () => setShowPassword(!showPassword);
  const toggleShowCurrentPassword = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const onSubmit = handleSubmit((data) => {
    const passwordData = {
      id: usahawanId,
      ...data,
    };

    if (isMatch) {
      resetCurrentUsahawanPassword({
        newPassword: passwordData,
      });
    }
  });

  useEffect(() => {
    if (passwordConfirmation && passwordConfirmation !== password) {
      setIsMatch(false);
    } else {
      setIsMatch(true);
      setValue("new_password_confirmation", passwordConfirmation);
    }
  }, [passwordConfirmation, password]);

  return (
    <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <Section title="Kata Laluan Baru">
          <SimpleGrid
            columns={{ base: 2 }}
            gap={{ sm: "6px", md: "18px" }}
            mb={10}
          >
            <TextField
              id="current_password"
              label="Kata Laluan Semasa"
              placeholder="Min. 5 characters"
              type="password"
              showPassword={showCurrentPassword}
              toggleShowPassword={toggleShowCurrentPassword}
              isInvalid={!!errors.current_password}
              errorMessage={errors.current_password?.message}
              autoComplete="current-password"
              readOnly={action === 'show' || isActive == '0'}
              {...register("current_password")}
            />

            <TextField
              id="new_password"
              label="Kata Laluan Baru"
              placeholder="Min. 5 characters"
              type="password"
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              isInvalid={!!errors.new_password}
              errorMessage={errors.new_password?.message}
              autoComplete="new-password"
              readOnly={action === 'show' || isActive == '0'}
              {...register("new_password")}
            />

            <FormControl isInvalid={!isMatch}>
              <FormLabel variant="main" mb="8px" htmlFor={"password_confirm"}>
                Pengesahan Kata Laluan Baru
              </FormLabel>

              <InputGroup size="md">
                <Input
                  id={"password_confirm"}
                  variant={"auth"}
                  fontSize={"sm"}
                  ms={{ base: "0px", md: "0px" }}
                  type={"password"}
                  placeholder={"Sahkan Kata Laluan"}
                  fontWeight={"500"}
                  size={"md"}
                  readOnly={action === 'show' || isActive == '0'}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </InputGroup>

              {!isMatch && (
                <FormErrorMessage>Kata laluan tidak sama</FormErrorMessage>
              )}
            </FormControl>
          </SimpleGrid>
        </Section>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          {isActive == '1' && (
            <Button
              variant="brand"
              fontSize={"sm"}
              fontWeight={"500"}
              minW={"183px"}
              type="submit"
              isDisabled={isResetting}
            >
              Kemaskini Tetapan
            </Button>
          )}
        </Flex>
      </form>
    </Flex>
  );
};

export default FormTetapan;
