import React from "react";
import { Flex, Text, Icon, Heading, Button } from "@chakra-ui/react";
import { HSeparator } from "components/separator/Separator";
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaNetworkWired,
} from "react-icons/fa";

interface DirectoryCardProps {
  entrepreneurs: any;
}

const DirectoryList = ({ entrepreneurs = [] }: DirectoryCardProps) => {
  if (entrepreneurs.length === 0)
    return (
      <Flex w={"100%"} h={"100%"} justify="center" align={"center"}>
        <Text fontWeight="500" fontSize="lg" color="gray.500">
          Tiada data buat masa kini
        </Text>
      </Flex>
    );

  return (
    <Flex width={"100%"} flexDirection={"column"} gap={10}>
      {entrepreneurs.map((entrepreneur: any, index: number) => (
        <Flex
          key={index}
          direction={"column"}
          boxShadow="md"
          border="1px solid #E2E8F0"
          borderRadius="lg"
          overflow={"hidden"}
        >
          <Flex flexDirection={"column"} gap={5} px={6} pt={6} pb={5}>
            <Heading as="h3" fontSize={{ base: "xl", md: "2xl" }} fontWeight="bold">
              {entrepreneur?.nama_usahawan}
            </Heading>

            <Flex gap={3} flexWrap="wrap">
              {entrepreneur?.nama_syarikats
                ?.split(",")
                .filter((syarikat: string) => syarikat.trim() !== "")
                .map((syarikat: string, index: number) => (
                  <Button
                    key={index}
                    size="sm"
                    leftIcon={<FaNetworkWired />}
                    colorScheme="green"
                    variant="solid"
                    p={2}
                    w="max-content"
                    fontWeight="semibold"
                    cursor="default"
                    _hover={{
                      bg: "green.500",
                      color: "white",
                    }}
                    _active={{
                      bg: "green.500",
                      color: "white",
                    }}
                  >
                    {syarikat.trim()}
                  </Button>
                ))}
            </Flex>
          </Flex>

          <HSeparator />

          <Flex p={6} gap={6} flexWrap="wrap">
            <Flex alignItems="center" gap={2}>
              <Icon as={FaEnvelope} color="green.500" />
              <Text>{entrepreneur?.email_usahawan ?? "N/A"}</Text>
            </Flex>
            <Flex alignItems="center" gap={2}>
              <Icon as={FaPhone} color="green.500" />
              <Text>{entrepreneur?.no_hp_usahawan ?? "N/A"}</Text>
            </Flex>

            <Flex alignItems="center" gap={2}>
              <Icon as={FaMapMarkerAlt} color="green.500" />
              <Text>{entrepreneur?.negeri ?? "N/A"}</Text>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};

export default DirectoryList;
