import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Box, Heading, SimpleGrid, Flex, Text, Image } from "@chakra-ui/react";

import FullScreenLoader from "components/loader/FullScreenLoader";
import { usePublicCatalogues } from "hooks/admin/catalogue/usePublicCatalogues";
import noImage from "assets/img/catalog/no-image.png";

interface KatalogAdditionalProps {
  userId: number;
  productId: number;
}

const KatalogAdditional = ({ userId, productId }: KatalogAdditionalProps) => {
  const { catalogues, isLoading } = usePublicCatalogues({ user_id: userId });

  const approveCatalogues = useMemo(() => {
    if (!catalogues?.data) return [];
    
    return catalogues.data.filter(
      ({ is_published, is_approved, id }: any) =>
        is_published === 1 && is_approved === 1 && id !== productId
    );
  }, [catalogues, productId]);

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box>
      <Heading as="h4" size="lg" mb={6}>
        Katalog Yang Berkaitan
      </Heading>

      {approveCatalogues.length === 0 ? (
        <Flex height={10} align="center">
          <Text fontWeight="500" fontSize="lg" color="gray.500">
            Tiada data buat masa kini
          </Text>
        </Flex>
      ) : (
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={8}>
          {approveCatalogues.map((product: any) => (
            <NavLink to={`/guest/katalog/${product.id}`} key={product.id}>
              <Flex
                direction="column"
                boxShadow="md"
                border="1px solid #E2E8F0"
                borderRadius="md"
                overflow="hidden"
              >
                <Box>
                  <Image
                    src={product?.gambar_produk_path ? `https://${product?.gambar_produk_path}` : noImage}
                    alt={product?.nama_produk ?? "Gambar Produk"}
                    objectFit="cover"
                    width="100%"
                    height={150}
                  />
                </Box>
                <Box p={4}>
                  <Text fontWeight="500">{product?.nama_produk}</Text>
                </Box>
              </Flex>
            </NavLink>
          ))}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default KatalogAdditional;