import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getLawatan } from "services/admin/lawatan/lawatanService";

export function useLawatan() {
  const { lawatanId } = useParams<{ lawatanId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: lawatan,
    error,
  } = useQuery({
    queryKey: ["lawatan", lawatanId],
    queryFn: () => getLawatan(lawatanId),
    enabled: !!lawatanId,
  });

  return { isLoading, isInitialLoading, error, lawatan };
}

