import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getPermissions } from "services/admin/permission/permissionService";
import { PAGE_SIZE } from "utils/constants";

export function usePermissions(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams = {};

  for (const [key, value] of searchParams) {
    currentSearchParams = {
      [key]: value,
    };
  }

  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? PAGE_SIZE
    : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: permissions,
    error,
  } = useQuery({
    queryKey: ["permissions", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getPermissions({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        limit: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
  });

  return { isLoading, error, permissions };
}
