import { Box, Image } from "@chakra-ui/react";

interface HeroProps {
  image: string;
}

const Hero = ({ image }: HeroProps) => {
  return (
    <Box height={{ base: 200, md: 300 }} width="100%">
      <Image
        src={image}
        alt="Hero Image"
        objectFit="cover"
        width="100%"
        height="100%"
      />
    </Box>
  );
};

export default Hero;
