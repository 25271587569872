import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import {
  FaUsers,
  FaProjectDiagram,
  FaDollarSign,
  FaUserPlus,
} from "react-icons/fa";

import DashboardCard from "./components/DashboardCard";
import DashboardFilter from "./components/DashboardFilter";
import Section from "components/section/Section";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useDashboard } from "hooks/admin/dashboard/useDashboard";

const Dashboard = () => {
  const { isLoading, error, dashboard } = useDashboard();

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "100px", md: "60px", xl: "60px" }}>
      <DashboardFilter />

      {dashboard?.data?.usahawan && (
        <Section title="Usahawan" mb={8} px={4}>
          <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={4}>
            <DashboardCard
              icon={FaUsers}
              label="Bilangan Usahawan"
              value={dashboard?.data?.usahawan?.bilangan_usahawan ?? 0}
            />
            <DashboardCard
              icon={FaProjectDiagram}
              label="Bilangan Projek"
              value={dashboard?.data?.usahawan?.bilangan_projek ?? 0}
            />
            <DashboardCard
              icon={FaDollarSign}
              label="Purata Pendapatan"
              value={dashboard?.data?.usahawan?.purata_pendapatan ?? 0}
            />
            <DashboardCard
              icon={FaUserPlus}
              label="Bilangan Usahawan Baru"
              value={dashboard?.data?.usahawan?.bilangan_usahawan_baru ?? 0}
            />
          </SimpleGrid>
        </Section>
      )}

      {dashboard?.data?.agromakanan && (
        <Section title="Agromakanan" mb={6} px={4}>
          <SimpleGrid columns={{ base: 1, md: 3, lg: 4 }} spacing={4}>
            <DashboardCard
              icon={FaUsers}
              label="Bilangan Usahawan"
              value={dashboard?.data?.agromakanan?.bilangan_usahawan ?? 0}
            />
            <DashboardCard
              icon={FaProjectDiagram}
              label="Bilangan Projek"
              value={dashboard?.data?.agromakanan?.bilangan_projek ?? 0}
            />
            <DashboardCard
              icon={FaDollarSign}
              label="Purata Pendapatan"
              value={dashboard?.data?.agromakanan?.purata_pendapatan ?? 0}
            />
            <DashboardCard
              icon={FaUserPlus}
              label="Bilangan Usahawan Baru"
              value={dashboard?.data?.agromakanan?.bilangan_usahawan_baru ?? 0}
            />
          </SimpleGrid>
        </Section>
      )}

      {!dashboard?.data?.usahawan && !dashboard?.data?.agromakanan && (
        <Text fontSize="18px" fontWeight="bold" textAlign="center" mt={8}>
          No data
        </Text>
      )}
    </Box>
  );
};

export default Dashboard;