import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { usePelaksanas } from "hooks/admin/pelaksana/usePelaksanas";

const columnHelper = createColumnHelper<any>();

const CarianPelaksana = () => {
  const { isLoading, error, pelaksanas } = usePelaksanas();

  const columns = [
    columnHelper.accessor("syarikat_ppk_ids", {
      header: () => <Text variant="tableHeading">ID PPK</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("user_id", {
      header: () => <Text variant="tableHeading">ID Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_syarikats", {
      header: () => <Text variant="tableHeading">Nama Syarikat</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_daftar_ssms", {
      header: () => <Text variant="tableHeading">No Daftar SSM</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pentadbir_syarikat", {
      header: () => <Text variant="tableHeading">Nama Pentadbir Syarikat</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("emel_pentadbir_syarikat", {
      header: () => <Text variant="tableHeading">Emel Pentadbir Syarikat</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/pelaksana/show/${info.row.original.user_id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={pelaksanas}
        title={"Senarai Pelaksana"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}

export default CarianPelaksana;