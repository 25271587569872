import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";

export default function Sorting() {
  const [applicationType, setApplicationType] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [section, setSection] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setType: (e: string) => void
  ) => {
    const selectedApplicationType = e.target.value;
    setType(selectedApplicationType);
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Jenis Bantuan"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Kategori Projek"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Sub Kategori"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Nama Projek"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Negeri"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            options={[]}
            onChange={(e: any) => {}}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
      >
        Cari
      </Button>
    </FormControl>
  );
}
