import React, { useMemo } from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useAktivitis } from "hooks/admin/aktiviti/useAktivitis";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<any>();

export default function Aktiviti() {
  const { isLoading, error, aktivitis } = useAktivitis()
  const { currentUser, isLoading: isLoadingUser } = useCurrentUser();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("insentif_id", {
      header: () => <Text variant="tableHeading">ID Insentif</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_aktiviti", {
      header: () => <Text variant="tableHeading">Tarikh Aktiviti</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_usahawan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_fon_usahawan", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_jenis_bantuan", {
      header: () => <Text variant="tableHeading">Jenis Bantuan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_kategori_projek", {
      header: () => <Text variant="tableHeading">Kategori Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_subkategori_projek", {
      header: () => <Text variant="tableHeading">Sub Kategori</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek", {
      header: () => <Text variant="tableHeading">Nama Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_tapak", {
      header: () => <Text variant="tableHeading">Tapak</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_aktiviti", {
      header: () => <Text variant="tableHeading">Nama Aktiviti</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          {isUpdateAllowed && (
            <NavLink to={`/admin/aktiviti/kemaskini/${info.row.original.id}`}>
              <Icon
                w="24px"
                h="24px"
                me="5px"
                color={"blue.500"}
                as={MdEditNote}
              />
            </NavLink>
          )}

          <NavLink to={`/admin/aktiviti/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  const isUpdateAllowed = useMemo(() => {
    if (currentUser?.data?.roles && Array.isArray(currentUser.data.roles) && currentUser.data.roles.length > 0) {
      const permissions = currentUser.data.roles[0].permissions.map((p: any) => p.name);
      return permissions.includes("update aktiviti");
    }
    
    return false;
  }, [currentUser]);

  if (isLoading || isLoadingUser) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={aktivitis}
        title={"Senarai Aktiviti"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
