import axios from "utils/axiosInstance";

export async function getPTOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/pt",
    params,
  });
  return data;
}

export async function getPTOption(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/general/pt/${id}`,
  });
  return data;
}

export async function getPTDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/pt/all",
    params,
  });
  return data;
}

