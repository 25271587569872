import axios from "utils/axiosInstance";

export async function getDunOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/dun",
    params,
  });
  return data;
}

export async function getDunDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/dun/all",
    params,
  });
  return data;
}


