import { useQuery } from "@tanstack/react-query";
import { getKlusterDataOptions } from "services/setting/general/klusterService";

export function useKlusterDataSettings(params?: any) {
  const { isLoading, data: klusterData, error } = useQuery({
    queryKey: ["klusterData", params],
    queryFn: () => getKlusterDataOptions(params),
  });

  // OUTPUT
  return {
    klusterData,
    isLoading,
    error,
  };
}
