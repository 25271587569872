import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";

import useOptions from "hooks/setting/general/useOptions";

import { IBuletinFormValues } from "entities/admin/buletin";
import { useCreateBuletin } from "hooks/admin/buletin/useCreateBuletin";
import { useEditBuletin } from "hooks/admin/buletin/useEditBuletin";
import Dropzone from "views/setting/profile/components/Dropzone";
import { MdUpload } from "react-icons/md";
import { formatDate } from "utils/helpers";

interface FormBuletinProps {
  action: "create" | "edit" | "show";
  initialData?: IBuletinFormValues;
}

const FormBuletin: React.FC<FormBuletinProps> = ({ action, initialData }) => {
  const [photo, setPhoto] = useState(null);
  const [displayDate, setDisplayDate] = useState(false);
  const [tarikhMula, setTarikhMula] = useState(null);

  const { createBuletin, isCreating } = useCreateBuletin();
  const { editBuletin, isEditing } = useEditBuletin();

  const { statusPaparanOps } = useOptions();

  const history = useHistory();

  const schema = yup
    .object({
      tajuk: yup.string().required(),
      tarikh_mula: yup.date().notRequired(),
      tarikh_akhir: yup.date().notRequired(),
      keterangan: yup.string().required(),
      pautan: yup.string(),
      is_publish: yup.string().required(),
    })
    .required();

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IBuletinFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_mula: formatDate(initialData?.tarikh_mula),
      tarikh_akhir: formatDate(initialData?.tarikh_akhir),
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createBuletin({
        newBuletin: {
          ...data,
          tarikh_mula: formatDate(data.tarikh_mula),
          tarikh_akhir: formatDate(data.tarikh_akhir),
          photo: photo,
          created_by: 1,
          updated_by: 1,
        },
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editBuletin({
        newBuletin: {
          ...data,
          tarikh_mula: formatDate(data.tarikh_mula),
          tarikh_akhir: formatDate(data.tarikh_akhir),
          photo: photo,
          created_by: 1,
          updated_by: 1,
        },
        id: `${data.id}`,
      });
    }
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "tarikh_mula" && value?.tarikh_mula) {
        setTarikhMula(value.tarikh_mula);
      }
      
      if (name === "is_publish" && value?.is_publish == 1) {
        setDisplayDate(true);
      } else if (value?.is_publish == 0) {
        reset({
          tarikh_mula: null,
          tarikh_akhir: null,
        });
        setDisplayDate(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, reset]);

  useEffect(() => {
    if(initialData?.is_publish == 1) {
      setDisplayDate(true);
    }
  }, [initialData?.is_publish])
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Butiran Buletin">
              <SimpleGrid columns={{ base: 2 }} gap={{ sm: "6px", md: "18px" }} mb={10}>
                <TextField
                  id="tajuk"
                  label="Tajuk"
                  placeholder="Masukkan tajuk buletin"
                  isInvalid={!!errors.tajuk}
                  errorMessage={errors.tajuk?.message}
                  readOnly={action === "show"}
                  autoComplete="nope"
                  {...register("tajuk")}
                />

                <TextField
                  id="keterangan"
                  label="Keterangan"
                  type="textarea"
                  isInvalid={!!errors.keterangan}
                  errorMessage={errors.keterangan?.message}
                  readOnly={action === "show"}
                  {...register("keterangan")}
                />

                {displayDate && (
                  <>
                    <TextField
                      id="tarikh_mula"
                      label="Tarikh Mula"
                      type="date"
                      isInvalid={!!errors.tarikh_mula}
                      errorMessage={errors.tarikh_mula?.message}
                      min={formatDate(new Date())}
                      readOnly={action === "show"}
                      {...register("tarikh_mula")}
                    />

                    <TextField
                      id="tarikh_akhir"
                      label="Tarikh Akhir"
                      type="date"
                      isInvalid={!!errors.tarikh_akhir}
                      errorMessage={errors.tarikh_akhir?.message}
                      min={tarikhMula ?? formatDate(tarikhMula)}
                      readOnly={action === "show"}
                      {...register("tarikh_akhir")}
                    />
                  </>
                )}

                <TextField
                  id="pautan"
                  label="Pautan"
                  placeholder="Masukkan pautan buletin jika ada"
                  isInvalid={!!errors.pautan}
                  errorMessage={errors.pautan?.message}
                  readOnly={action === "show"}
                  {...register("pautan")}
                />

                <SelectField
                  label={"Status Paparan"}
                  options={statusPaparanOps}
                  isInvalid={!!errors.is_publish}
                  errorMessage={errors.is_publish?.message}
                  readOnly={action === "show"}
                  {...register("is_publish")}
                />

                <Dropzone
                  w={{ base: "100%" }}
                  maxH={{ base: "60%", lg: "50%", "2xl": "268px" }}
                  minH={{ base: "268px", lg: "268px", "2xl": "268px" }}
                  setPhoto={setPhoto}
                  photo={photo}
                  photoUrl={initialData?.photo}
                  content={
                    <Box>
                      <Icon as={MdUpload} w="80px" h="80px" />
                      <Flex justify="center" mx="auto" mb="12px">
                        <Text fontSize="xl" fontWeight="700">
                          {action === "edit" ? "Kemaskini Gambar" : "Muat Naik Gambar"}
                        </Text>
                      </Flex>
                      <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                        Saiz yang disyorkan: 1280px X 960px
                      </Text>
                      <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                        Jenis Fail: JPG, PNG
                      </Text>
                      {photo && <Text fontSize="sm" fontWeight="500" color="brand.500">{photo?.name ?? photo}</Text>}
                    </Box>
                  }
                  isDisabled={action === "show"}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={action === "edit" ? "button" : "reset"}
                onClick={() => {
                  if (action !== "create") {
                    history.push("/admin/buletin");
                  }
                }}>
                {action !== "show" ? "Batal" : "Kembali"}
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit">
                  {action === "edit" ? "Kemaskini Buletin" : "Tambah Buletin Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormBuletin;
