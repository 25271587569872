import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from "@chakra-ui/react";

import FormPekebunKecil from "./FormPekebunKecil";

interface TabPekebunKecilProps {
  action: "edit" | "show";
  pekebunKecils?: any;
}

const TabPekebunKecil = ({ action, pekebunKecils = [] }: TabPekebunKecilProps) => {
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        {pekebunKecils.length === 0 && <Center py={20}>Tiada Maklumat</Center>}

        {pekebunKecils.map((pekebunKecil: any, index: number) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton bg={"gray.100"}>
                <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                  {pekebunKecil.nama_pekebun}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <FormPekebunKecil action={action} initialData={pekebunKecil} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default TabPekebunKecil;
