import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, FormControl, Flex } from "@chakra-ui/react";

import Card from "components/card/Card";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";

const DashboardFilter = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [ptOps, setPtOps] = useState<any>([]);
  const [daerahOps, setDaerahOps] = useState<any>([]);

  const { negeris, isLoading: isNegeriLoading } = useNegeriSettings({
    is_restricted: 0,
  });
  const { pts, isLoading: isPtLoading } = usePTSettings({
    is_restricted: 0,
  });
  const { daerahs, isLoading: isDaerahLoading } = useDaerahSettings({
    is_restricted: 0,
  });

  const [searchValues, setSearchValues] = useState({
    negeri_id: searchParams.get("negeri_id") || "",
    pt_id: searchParams.get("pt_id") || "",
    daerah_id: searchParams.get("daerah_id") || "",
  });

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      negeri_id: "",
      pt_id: "",
      daerah_id: "",
    });
    
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (searchValues.negeri_id && pts?.data.items) {
      setSearchValues((prevState) => ({
        ...prevState,
        pt_id: "",
      }));

      const ptSelect = document.querySelector("select[id='pt_id']") as HTMLSelectElement;

      if (ptSelect) {
        ptSelect.value = "";
      }

      const pt = pts?.data.items
        .filter((pt: any) => pt.negeri_id == searchValues.negeri_id)
        .map((pt: any) => {
          return {
            label: pt.keterangan,
            value: pt.id,
          };
        });

      setPtOps(pt);
    }
  }, [searchValues.negeri_id, pts]);

  useEffect(() => {
    if (searchValues.pt_id && daerahs?.data.items) {
      setSearchValues((prevState) => ({
        ...prevState,
        daerah_id: "",
      }));
      
      const daerahSelect = document.querySelector("select[id='daerah_id']") as HTMLSelectElement;

      if (daerahSelect) {
        daerahSelect.value = "";
      }

      const daerah = daerahs?.data.items
        .filter(
          (daerah: any) => daerah.pusat_tanggungjawap_id == searchValues.pt_id
        )
        .map((daerah: any) => {
          return {
            label: daerah.daerah,
            value: daerah.id,
          };
        });

      setDaerahOps(daerah);
    }
  }, [searchValues.pt_id, daerahs]);

  if (isNegeriLoading || isPtLoading || isDaerahLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Card background={"transparent"} mb={6} borderRadius="12px">
      <FormControl
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        gap="18px"
      >
        <SelectField
          label={"Negeri"}
          value={searchValues.negeri_id}
          options={negeris?.data?.items}
          onChange={(e: any) => handleOnTextChange(e, "negeri_id")}
          background={"white"}
        />

        <SelectField
          id="pt_id"
          label={"Pusat Tanggungjawab"}
          value={searchValues.pt_id}
          options={ptOps}
          onChange={(e: any) => handleOnTextChange(e, "pt_id")}
          background={"white"}
          isDisabled={!searchValues.negeri_id}
        />

        <SelectField
          id="daerah_id"
          label={"Daerah"}
          value={searchValues.daerah_id}
          options={daerahOps}
          onChange={(e: any) => handleOnTextChange(e, "daerah_id")}
          background={"white"}
          isDisabled={!searchValues.pt_id}
        />

        <Flex gap={2} alignItems="center" alignSelf="flex-end">
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="max-content"
            px="18px"
            onClick={search}
          >
            Cari
          </Button>

          <Button
            fontSize="sm"
            variant="outline"
            fontWeight="500"
            w="max-content"
            onClick={reset}
          >
            Set Semula
          </Button>
        </Flex>
      </FormControl>
    </Card>
  );
};

export default DashboardFilter;
