import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getTunaiMasuk } from "services/admin/kewangan/kewanganService";

export function useTunaiMasuk(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  let currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("limit")
    ? 10
    : Number(searchParams.get("limit"));

  const {
    isLoading,
    data: tunaiMasuk,
    error,
  } = useQuery({
    queryKey: ["tunaiMasuk", currentPage, currentLimit, currentSearchParams],
    queryFn: () =>
      getTunaiMasuk({
        ...params,
        ...currentSearchParams,
        page: currentPage,
        itemsPerPage: currentLimit,
      }),
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
  });

  return { isLoading, error, tunaiMasuk };
}
