import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";

import tableData from "./variables/tableDataLocalities";

const columnHelper = createColumnHelper<any>();

export default function Localities() {
  const columns = [
    columnHelper.accessor("kampung", {
      header: () => <Text variant="tableHeading">Kampung</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("seksyen", {
      header: () => <Text variant="tableHeading">Seksyen</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("mukim", {
      header: () => <Text variant="tableHeading">Mukim</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("stesen", {
      header: () => <Text variant="tableHeading">Stesen</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("stesen_kod", {
      header: () => <Text variant="tableHeading">Stesen Kod</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("daerah", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("pt", {
      header: () => <Text variant="tableHeading">Pusat Tanggungjawab</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kod_pt", {
      header: () => <Text variant="tableHeading">Kod Pusat Tanggungjawab</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("bandar", {
      header: () => <Text variant="tableHeading">Bandar</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("poskod", {
      header: () => <Text variant="tableHeading">Poskod</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("parlimen", {
      header: () => <Text variant="tableHeading">Parlimen</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dun", {
      header: () => <Text variant="tableHeading">Dun</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    // columnHelper.accessor((row) => row.id, {
    //   id: "action",
    //   header: () => <Text variant="tableHeading">Tindakan</Text>,
    //   cell: (info) => (
    //     <List spacing={2}>
    //       <NavLink to={`/admin/settings/users/show/${info.row.original.id}`}>
    //         <Icon
    //           w="24px"
    //           h="24px"
    //           me="5px"
    //           color={"green.500"}
    //           as={MdOutlineRemoveRedEye}
    //         />
    //       </NavLink>
    //     </List>
    //   ),
    // }),
  ];

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Resource
        columns={columns}
        data={tableData}
        title={"Senarai Lokaliti"}
      />
    </Box>
  );
}
