import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from "@chakra-ui/react";

import FormJenisPerniagaan from "./FormJenisPerniagaan";

interface TabJenisPerniagaanProps {
  action: "edit" | "show";
  jenisPerniagaans?: any;
}

const TabJenisPerniagaan = ({ action, jenisPerniagaans = [] }: TabJenisPerniagaanProps) => {
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        {jenisPerniagaans.length === 0 && <Center py={20}>Tiada Maklumat</Center>}
        
        {jenisPerniagaans.map((jenisPerniagaan: any, index: number) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton bg={"gray.100"}>
                <Box as="span" flex="1" textAlign="left" fontWeight={"semibold"}>
                  {jenisPerniagaan?.jenis_insentif?.nama ?? 'Maklumat'} - {jenisPerniagaan?.sub_kluster?.kluster?.jenis_perniagaan?.nama ?? `${index + 1}`}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <FormJenisPerniagaan action={action} initialData={jenisPerniagaan} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default TabJenisPerniagaan;
