import React from "react";
import { NavLink } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";

import noImage from "assets/img/catalog/no-image.png";

interface KatalogListProps {
  products: any;
}

const KatalogList = ({ products = [] }: KatalogListProps) => {
  if(products.length === 0) return (
    <Flex height={"240px"} justify="center" align="center">
      <Text fontWeight="500" fontSize="lg" color="gray.500">Tiada data buat masa kini</Text>
    </Flex>
  )

  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4, xl: 5 }} spacing={8}>
      {products.map((product: any) => (
        <NavLink to={`/guest/katalog/${product.id}`} key={product.id}>
          <Flex
            direction="column"
            boxShadow="md"
            border="1px solid #E2E8F0"
            borderRadius="md"
            overflow="hidden"
          >
            <Box>
              <Image
                src={product?.gambar_produk_path ? `https://${product?.gambar_produk_path}` : noImage}
                alt={product?.nama_produk ?? "Gambar Produk"} 
                objectFit="cover"
                width="100%"
                height={150}
              />
            </Box>
            <Box p={4}>
              <Text fontWeight="500">{product?.nama_produk}</Text>
            </Box>
          </Flex>
        </NavLink>
      ))}
    </SimpleGrid>
  );
};

export default KatalogList;