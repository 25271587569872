import React from "react";
import FormProjekBpk from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useProjekBpk } from "hooks/admin/insentif/bpk/useProjekBpk";

export default function ShowBpk() {
  const { projekBpk, isLoading, isInitialLoading, error  } = useProjekBpk();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormProjekBpk action="show" initialData={projekBpk?.data} />;
}
