import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";

interface FormPelaksanaProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormPelaksana: React.FC<FormPelaksanaProps> = ({ action, initialData }) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      nama_syarikat: initialData?.syarikats?.[0]?.nama_syarikat,
      no_daftar_ssm: initialData?.syarikats?.[0]?.no_daftar_ssm,
      name: initialData?.name,
      email: initialData?.email,
    },
  });


  const onSubmit = handleSubmit((data) => {
    //
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form autoComplete="off">
            <Section title="Maklumat Pelaksana">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama_syarikat"
                  label="Nama Syarikat"
                  readOnly={action === "show"}
                  {...register("nama_syarikat")}
                />

                <TextField
                  id="no_daftar_ssm"
                  label="No. SSM"
                  readOnly={action === "show"}
                  {...register("no_daftar_ssm")}
                />

                <TextField
                  id="name"
                  label="Nama Pentadbir Syarikat"
                  readOnly={action === "show"}
                  {...register("name")}
                />

                <TextField
                  id="name"
                  label="Emel Pentadbir Syarikat"
                  readOnly={action === "show"}
                  {...register("email")}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type="button"
                onClick={() => history.goBack()}
              >
                Kembali
              </Button>
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormPelaksana;
