import axios from "utils/axiosInstance";
import { IPassword } from "entities/setting/password";

export async function resetPassword(
  values: IPassword,
) {
  const apiUrl = "/user/update/password";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}

export async function resetUsahawanPassword(
  values: IPassword,
) {
  const apiUrl = "/usahawan/update/password";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}