import React from "react";
import { Box } from "@chakra-ui/react";

import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";

interface SidebarProps {
  routes: RoutesType[];
  [x: string]: any;
}

function Sidebar(props: SidebarProps) {
  const { routes } = props;

  return (
    <Box display={{ sm: "none", xl: "block" }} position="fixed" minH="100%">
      <Box
        bg={"white"}
        transition={"0.2s linear"}
        w="300px"
        h="100vh"
        m={"0px"}
        minH="100%"
        overflowX="hidden"
        boxShadow={"14px 17px 40px 4px rgba(112, 144, 176, 0.08)"}
      >
        <Scrollbars
          autoHide={false}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                position: "absolute",
                width: "6px",
                transition: "opacity 200ms ease 0s",
                opacity: 1,
                backgroundColor: "transparent",
                top: 2,
                bottom: 2,
                right: 0,
                borderRadius: "3px",
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                borderRadius: "15px",
                backgroundColor: "rgba(222, 222, 222, .1)",
              }}
            />
          )}
          renderView={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                marginBottom: "-22px",
              }}
            />
          )}
        >
          <Content routes={routes} />
        </Scrollbars>
      </Box>
    </Box>
  );
}

export default Sidebar;
