import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getPublicCatalogues } from "services/admin/catalogue/catalogueService";

export function usePublicCatalogues(params?: any) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const toast = useToast();

  let currentSearchParams: Record<string, string> = {};
  
  for (const [key, value] of searchParams) {
    currentSearchParams[key] = value;
  }

  const {
    isLoading,
    data: catalogues,
    error,
  } = useQuery({
    queryKey: ["public-catalogues", currentSearchParams],
    queryFn: () =>
      getPublicCatalogues({
        ...params,
        ...currentSearchParams,
      }),
    staleTime: 0,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: "always",
    onError: (err: any) => {
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    }
  });

  return { isLoading, error, catalogues };
}