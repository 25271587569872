import { useQuery } from "@tanstack/react-query";
import { getJenisInsentifDataOptions } from "services/setting/general/jenisInsentifService";

export function useJenisInsentifDataSettings(params?: any) {
  const { isLoading, data: jenisInsentifData, error } = useQuery({
    queryKey: ["jenisInsentifData", params],
    queryFn: () => getJenisInsentifDataOptions(params),
  });

  // OUTPUT
  return {
    jenisInsentifData,
    isLoading,
    error,
  };
}
