import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getCourse } from "services/admin/kursus/courseService";

export function useCourse() {
  const { kursusId } = useParams<{ kursusId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: kursus,
    error,
  } = useQuery({
    queryKey: ["kursus", kursusId],
    queryFn: () => getCourse(kursusId),
    enabled: !!kursusId,
  });

  return { isLoading, isInitialLoading, error, kursus };
}

