import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
  Text,
} from "@chakra-ui/react";

import FormSyarikat from "./FormSyarikat";

interface TabSyarikatProps {
  action: "edit" | "show";
  syarikats?: any;
  negeriData?: any;
  daerahData?: any;
  bandarData?: any;
  dunData?: any;
  parlimenData?: any;
}

const TabSyarikat = ({
  action,
  syarikats = [],
  negeriData,
  daerahData,
  bandarData,
  dunData,
  parlimenData,
}: TabSyarikatProps) => {
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        {syarikats.length === 0 && <Center py={20}>Tiada Maklumat</Center>}

        {syarikats.map((syarikat: any, index: number) => (
          <AccordionItem key={`${syarikat.id}-${index}`}>
            <h2>
              <AccordionButton bg={"gray.100"}>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"semibold"}
                >
                  <Text>
                    {syarikat.nama_syarikat
                      ? syarikat.nama_syarikat
                      : `Syarikat ${index + 1}`}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <FormSyarikat
                action={action}
                initialData={syarikat}
                negeriData={negeriData}
                daerahData={daerahData}
                bandarData={bandarData}
                dunData={dunData}
                parlimenData={parlimenData}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default TabSyarikat;
