// Chakra imports
import { Button, Flex, useColorModeValue, Box, Image, Link } from "@chakra-ui/react";
import { useCallback } from "react";
// Assets
import { useDropzone } from "react-dropzone";

function Dropzone(props: any) {
  const { content, setPhoto, photo, photoUrl, isDisabled, ...rest } = props;

  const onDrop = useCallback((acceptedFiles: any[]) => {
    console.log(acceptedFiles);
    setPhoto(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Flex flexDirection={"column"} gap={"16px"}>
      <Flex
        align="center"
        justify="center"
        bg={bg}
        border="1px dashed"
        borderColor={borderColor}
        borderRadius="16px"
        w="100%"
        h="max-content"
        minH="100%"
        cursor="pointer"
        {...getRootProps({ className: "dropzone" })}
        {...rest}
      >
        <input {...getInputProps()} />

        <Button isDisabled={isDisabled} variant="no-effects">{content}</Button>
      </Flex>

      {photoUrl && (
        <Link href={"https://" + photoUrl} isExternal>
          <Box overflow={"hidden"} maxWidth={"180px"} maxHeight={"180px"}>
            <Image
              objectFit={"cover"}
              src={"https://" + photoUrl}
              alt="Horizon UI"
            />
          </Box>
        </Link>
      )}
    </Flex>
  );
}

export default Dropzone;
