import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useJenisBantuanDataSettings } from "hooks/setting/general/jenisBantuan/useJenisBantuanData";
import { useKategoriProjekDataSettings } from "hooks/setting/general/kategoriProjek/useKategoriProjekData";
import { useSubKategoriProjekDataSettings } from "hooks/setting/general/subKategoriProjek/useSubKategoriProjekData";
import { useNamaProjekDataSettings } from "hooks/setting/general/namaProjek/useNamaProjekData";
import { useNamaHasilDataSettings } from "hooks/setting/general/namaHasil/useNamaProjekData";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { negeriOps, daerahOps, pusatTanggungjawabOps } = useOptions();
  const { jenisBantuanData, isLoading: jenisBantuanLoading } =
    useJenisBantuanDataSettings();
  const { kategoriProjekData, isLoading: kategoriProjekLoading } =
    useKategoriProjekDataSettings();
  const { subKategoriProjekData, isLoading: subKategoriProjekLoading } =
    useSubKategoriProjekDataSettings();
  const { namaProjekData, isLoading: namaProjekLoading } =
    useNamaProjekDataSettings();
  const { namaHasilData, isLoading: namaHasilLoading } =
    useNamaHasilDataSettings();

  const [searchValues, setSearchValues] = useState({
    negeri_kebenaran: searchParams.get("negeri_kebenaran") || "",
    daerah_kebenaran: searchParams.get("daerah_kebenaran") || "",
    pt_kebenaran: searchParams.get("pt_kebenaran") || "",
    jenis_bantuan: searchParams.get("jenis_bantuan") || "",
    kategori_projek: searchParams.get("kategori_projek") || "",
    sub_kategori: searchParams.get("sub_kategori") || "",
    nama_projek: searchParams.get("nama_projek") || "",
    nama_hasil: searchParams.get("nama_hasil") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();
    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();
    
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      negeri_kebenaran: "",
      daerah_kebenaran: "",
      pt_kebenaran: "",
      jenis_bantuan: "",
      kategori_projek: "",
      sub_kategori: "",
      nama_projek: "",
      nama_hasil: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  if (
    jenisBantuanLoading ||
    kategoriProjekLoading ||
    subKategoriProjekLoading ||
    namaProjekLoading ||
    namaHasilLoading
  ) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Negeri"}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_kebenaran")}
            value={searchValues.negeri_kebenaran}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pt_kebenaran")}
            value={searchValues.pt_kebenaran}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_kebenaran")}
            value={searchValues.daerah_kebenaran}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Jenis Bantuan"}
            options={jenisBantuanData?.data}
            onChange={(e: any) => handleOnChange(e, "jenis_bantuan")}
            value={searchValues.jenis_bantuan}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Kategori"}
            options={kategoriProjekData?.data}
            onChange={(e: any) => handleOnChange(e, "kategori_projek")}
            value={searchValues.kategori_projek}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Sub Kategori"}
            options={subKategoriProjekData?.data}
            onChange={(e: any) => handleOnChange(e, "sub_kategori")}
            value={searchValues.sub_kategori}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Nama Projek"}
            options={namaProjekData?.data}
            onChange={(e: any) => handleOnChange(e, "nama_projek")}
            value={searchValues.nama_projek}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Nama Hasil"}
            options={namaHasilData?.data}
            onChange={(e: any) => handleOnChange(e, "nama_hasil")}
            value={searchValues.nama_hasil}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
