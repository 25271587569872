import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";

import { IPekebunKecilFormValues } from "entities/admin/usahawan";

import { formatDate } from "utils/helpers";

interface FormPekebunKecilProps {
  action: "edit" | "show";
  initialData?: IPekebunKecilFormValues;
}

const schema = yup
  .object({
    nama_pekebun: yup.string().notRequired(),
  })
  .required();

const FormPekebunKecil: React.FC<FormPekebunKecilProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();

  const { negeriOps, daerahOps, bandarOps, parlimenOps, dunOps } =
    useOptions();
  const { editUsahawan, isEditing } = useEditUsahawan();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPekebunKecilFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
    },
  });

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        pekebun_kecils: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="nama_pekebun"
            label="Nama Pekebun"
            errorMessage={errors.nama_pekebun?.message}
            isInvalid={!!errors.nama_pekebun}
            readOnly={action === "show"}
            {...register("nama_pekebun")}
          />

          <TextField
            id="no_kp"
            label="No. Kad Pengenalan"
            errorMessage={errors.no_kp?.message}
            isInvalid={!!errors.no_kp}
            readOnly={action === "show"}
            {...register("no_kp")}
          />

          <TextField
            id="np_tanam_semula_sic"
            label="No. Daftar Tanam Semula SIC"
            errorMessage={errors.np_tanam_semula_sic?.message}
            isInvalid={!!errors.np_tanam_semula_sic}
            readOnly={action === "show"}
            {...register("np_tanam_semula_sic")}
          />

          <TextField
            id="no_geran"
            label="No. Geran"
            errorMessage={errors.no_geran?.message}
            isInvalid={!!errors.no_geran}
            readOnly={action === "show"}
            {...register("no_geran")}
          />

          <TextField
            id="no_lot"
            label="No. Lot"
            errorMessage={errors.no_lot?.message}
            isInvalid={!!errors.no_lot}
            readOnly={action === "show"}
            {...register("no_lot")}
          />

          <TextField
            id="alamat_kebun_1"
            label="Alamat 1"
            errorMessage={errors.alamat_kebun_1?.message}
            isInvalid={!!errors.alamat_kebun_1}
            readOnly={action === "show"}
            {...register("alamat_kebun_1")}
          />

          <TextField
            id="alamat_kebun_2"
            label="Alamat 2"
            errorMessage={errors.alamat_kebun_2?.message}
            isInvalid={!!errors.alamat_kebun_2}
            readOnly={action === "show"}
            {...register("alamat_kebun_2")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <TextField
            id="keluasan_m2"
            label="Keluasan (m2)"
            type="number"
            step="any"
            errorMessage={errors.keluasan_m2?.message}
            isInvalid={!!errors.keluasan_m2}
            readOnly={action === "show"}
            {...register("keluasan_m2")}
          />

          <TextField
            id="keluasan_ekar"
            label="Keluasan (Ekar)"
            type="number"
            step="any"
            errorMessage={errors.keluasan_ekar?.message}
            isInvalid={!!errors.keluasan_ekar}
            readOnly={action === "show"}
            {...register("keluasan_ekar")}
          />

          <TextField
            id="keluasan_hektar"
            label="Keluasan (Hektar)"
            type="number"
            step="any"
            errorMessage={errors.keluasan_hektar?.message}
            isInvalid={!!errors.keluasan_hektar}
            readOnly={action === "show"}
            {...register("keluasan_hektar")}
          />

          <TextField
            id="jenis_tanaman_kebun"
            label="Jenis Tanaman Kebun"
            errorMessage={errors.jenis_tanaman_kebun?.message}
            isInvalid={!!errors.jenis_tanaman_kebun}
            readOnly={action === "show"}
            {...register("jenis_tanaman_kebun")}
          />

          <SelectField
            label={"Status Pekebun"}
            options={[]}
            errorMessage={errors.status_pekebun_id?.message}
            isInvalid={!!errors.status_pekebun_id}
            readOnly={action === "show"}
            {...register("status_pekebun_id")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
          >
            Kemaskini Maklumat Pekebun Kecil
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormPekebunKecil;
