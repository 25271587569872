import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import SwitchField from "components/fields/SwitchField";

import useOptions from "hooks/setting/general/useOptions";

import { IRoleFormValues } from "entities/setting/role";
import { useEditRole } from "hooks/setting/role/useEditRole";
import { useCreateRole } from "hooks/setting/role/useCreateRole";
import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";
import CreatableSelectField from "components/fields/CreatableSelectField";
import { usePermissions } from "hooks/admin/permission/usePermissions";

interface FormRoleProps {
  action: "create" | "edit" | "show";
  initialData?: IRoleFormValues;
}

const FormRole: React.FC<FormRoleProps> = ({ action, initialData }) => {
  const [negeriOptions, setNegeriOptions] = useState<any>([]);
  const [ptOptions, setPtOptions] = useState<any>([]);
  const [daerahOptions, setDaerahOptions] = useState<any>([]);

  const [showPT, setShowPT] = useState(false);
  const [showDaerah, setShowDaerah] = useState(false);

  const { pts } = usePTSettings({ paginate: false });
  const { daerahs } = useDaerahSettings({ paginate: false });

  const { isLoading: isLoadingPermission, error, permissions } = usePermissions();

  const { createRole, isCreating } = useCreateRole();
  const { editRole, isEditing } = useEditRole();

  const history = useHistory();

  const schema = yup
    .object({
      name: yup.string().required(),
      tahap_kebenaran: yup.string().required(),
      role_pusat_tanggungjawaps: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
      role_daerahs: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
      role_negeris: yup.lazy((value) =>
        Array.isArray(value) ? yup.array().of(yup.string()).required() : yup.string().required()
      ),
      permissions: yup.lazy((value) =>
        Array.isArray(value)
          ? yup.array().of(yup.string()).notRequired()
          : yup.string().notRequired()
      ),
    })
    .required();

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IRoleFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      role_daerahs: initialData?.role_daerahs?.map((rN) => rN.daerah_id) as any[],
      role_negeris: initialData?.role_negeris?.map((rN) => rN.negeri_id) as any[],
      role_pusat_tanggungjawaps: initialData?.role_pusat_tanggungjawaps?.map(
        (rN) => rN.pusat_tanggungjawap_id
      ) as any[],
      permissions: initialData?.permissions?.map(
        (rN) => rN.name
      ) as any[],
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createRole({
        newRole: data,
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editRole({
        newRole: data,
        id: `${data.id}`,
      });
    }
  });

  const { tahapKebenaranOps, negeriOps } = useOptions();

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "tahap_kebenaran") {
        if (value.tahap_kebenaran) {
          if (value.tahap_kebenaran == 2) {
            setShowPT(true);
            setShowDaerah(false);
          } else if (value.tahap_kebenaran == 3) {
            setShowPT(true);
            setShowDaerah(true);
          } else {
            setShowPT(false);
            setShowDaerah(false);
          }
        }
      }

      if (name === "role_negeris" && pts?.data) {
        setValue("role_pusat_tanggungjawaps", null);

        const pt = pts?.data.items
          .filter((pt: any) => {
            return value.role_negeris
              ?.map((i: any) => (typeof i === "object" ? parseInt(i.id) : parseInt(i)))
              .includes(pt.negeri_id);
          })
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });

        setPtOptions(pt);
      } else if (name === "role_pusat_tanggungjawaps" && daerahs?.data) {
        setValue("role_daerahs", null);

        const daerah = daerahs?.data.items
          .filter((daerah: any) => {
            return value.role_pusat_tanggungjawaps
              ?.map((i: any) => (typeof i === "object" ? parseInt(i.id) : parseInt(i)))
              .includes(daerah.pusat_tanggungjawap_id);
          })
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });
        setDaerahOptions(daerah);
      }
    });

    return () => subscription.unsubscribe();
  }, [daerahs, pts, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (initialData.tahap_kebenaran) {
        if (initialData.tahap_kebenaran == 2) {
          setShowPT(true);
          setShowDaerah(false);
        } else if (initialData.tahap_kebenaran == 3) {
          setShowPT(true);
          setShowDaerah(true);
        } else {
          setShowPT(false);
          setShowDaerah(false);
        }
      }

      if (pts?.data) {
        const pt = pts?.data.items
          .filter((pt: any) => initialData.role_negeris?.map((rN) => rN.id).includes(pt.negeri_id))
          .map((pt: any) => {
            return {
              label: pt.keterangan,
              value: pt.id,
            };
          });
        setPtOptions(pt);
      }

      if (daerahs?.data) {
        const daerah = daerahs?.data.items
          .filter((daerah: any) =>
            initialData.role_pusat_tanggungjawaps?.map((rD) => rD.id).includes(daerah.pt_id)
          )
          .map((daerah: any) => {
            return {
              label: daerah.nama,
              value: daerah.id,
            };
          });
        setDaerahOptions(daerah);
      }
    }
  }, [initialData, daerahs, pts]);


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Peranan">
              <SimpleGrid columns={{ base: 2 }} gap={{ sm: "6px", md: "18px" }} mb={10}>
                <TextField
                  id="name"
                  label="Nama Peranan"
                  placeholder="Pentadbir"
                  readOnly={action === "show"}
                  autoComplete="nope"
                  isInvalid={!!errors.name}
                  errorMessage={errors.name?.message}
                  {...register("name")}
                />
                <SelectField
                  label={"Tahap Kebenaran"}
                  options={tahapKebenaranOps}
                  readOnly={action === "show"}
                  isInvalid={!!errors.tahap_kebenaran}
                  errorMessage={errors.tahap_kebenaran?.message}
                  {...register("tahap_kebenaran")}
                />
              </SimpleGrid>
            </Section>

            <Section title="Maklumat Tahap Kebenaran">
              <SimpleGrid columns={{ base: 2 }} gap={{ sm: "6px", md: "18px" }} mb={10}>
                <CreatableSelectField
                  label={"Negeri"}
                  defaultOptions={negeriOps}
                  defaultValue={initialData?.role_negeris}
                  isMulti
                  onChange={(value) => {
                    setValue("role_negeris", value);
                  }}
                  isInvalid={!!errors.role_negeris}
                  errorMessage={errors.role_negeris?.message}
                  readOnly={action === "show"}
                />

                {showPT && (
                  <CreatableSelectField
                    label={"Pusat Tanggungjawab"}
                    defaultOptions={ptOptions}
                    defaultValue={initialData?.role_pusat_tanggungjawaps}
                    isMulti
                    onChange={(value) => {
                      console.log(value);
                      setValue("role_pusat_tanggungjawaps", value);
                    }}
                    isInvalid={!!errors.role_pusat_tanggungjawaps}
                    errorMessage={errors.role_pusat_tanggungjawaps?.message}
                    readOnly={action === "show"}
                  />
                )}

                {showDaerah && (
                  <CreatableSelectField
                    label={"Daerah"}
                    defaultOptions={daerahOptions}
                    defaultValue={initialData?.role_daerahs}
                    isMulti
                    onChange={(value) => {
                      setValue("role_daerahs", value);
                    }}
                    isInvalid={!!errors.role_daerahs}
                    errorMessage={errors.role_daerahs?.message}
                    readOnly={action === "show"}
                  />
                )}
              </SimpleGrid>
            </Section>

            <Section title="Maklumat Akses Peranan">
              <SimpleGrid columns={{ base: 2 }} gap={{ sm: "6px", md: "18px" }} mb={10}>
                {permissions?.data?.map((permission: any, i: number) => (
                  <SwitchField
                    reversed={true}
                    isChecked={initialData?.permissions?.map((p) => p.id).includes(permission.id)}
                    fontSize="sm"
                    mb="20px"
                    key={permission.id}
                    id={permission.id}
                    label={permission.name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValue(`permissions.${i}`, permission.name);
                      }
                    }}
                  />
                ))}
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/aktiviti");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit">
                  {action === "edit" ? "Kemaskini Peranan" : "Daftar Peranan Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormRole;
