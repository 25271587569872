import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useJenisInsentifDataSettings } from "hooks/setting/general/jenisInsentif/useJenisInsentifData";
import { useJenisPerniagaanDataSettings } from "hooks/setting/general/jenisPerniagaan/useJenisPerniagaanData";
import { useKlusterDataSettings } from "hooks/setting/general/kluster/useKlusterData";
import { useSubKlusterDataSettings } from "hooks/setting/general/subKluster/useSubKlusterData";


interface FormInsentifBPUProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormInsentifBPU: React.FC<FormInsentifBPUProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();
  const { capaiSasaranOps, negeriOps, pusatTanggungjawabOps, daerahOps, parlimenOps, dunOps } = useOptions();
  const { jenisInsentifData, isLoading: isLoadingJenisInsentif } = useJenisInsentifDataSettings();
  const { jenisPerniagaanData, isLoading: isLoadingJenisPerniagaan } = useJenisPerniagaanDataSettings();
  const { klusterData, isLoading: isLoadingKluster } = useKlusterDataSettings();
  const { subKlusterData, isLoading: isLoadingSubKluster } = useSubKlusterDataSettings();

  const schema = yup
    .object({
      nama: yup.string().notRequired(),
    })
    .required();

  const {
    register,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  if (isLoadingJenisInsentif || isLoadingJenisPerniagaan || isLoadingKluster || isLoadingSubKluster) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form autoComplete="off">
            <Section title="Maklumat Pengguna">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="nama"
                  label="Nama Usahawan"
                  readOnly
                  {...register("nama")}
                />

                <TextField
                  id="no_kad_pengenalan"
                  label="No. Kad Pengenalan"
                  readOnly
                  {...register("no_kad_pengenalan")}
                />

                <TextField
                  id="emel"
                  label="Emel"
                  readOnly
                  {...register("emel")}
                />

                <TextField
                  id="no_telefon"
                  label="No. Telefon"
                  readOnly
                  {...register("no_telefon")}
                />

                <SelectField
                  label={"Jenis Insentif"}
                  options={jenisInsentifData?.data}
                  readOnly
                  {...register("jenis_insentif")}
                />

                <SelectField
                  label={"Jenis Perniagaan"}
                  options={jenisPerniagaanData?.data}
                  readOnly
                  {...register("jenis_perniagaan")}
                />

                <SelectField
                  label={"Kluster"}
                  options={klusterData?.data}
                  readOnly
                  {...register("kluster")}
                />

                <SelectField
                  label={"Sub Kluster"}
                  options={subKlusterData?.data}
                  readOnly
                  {...register("sub_kluster")}
                />

                <SelectField
                  label={"Capai Sasaran"}
                  options={capaiSasaranOps}
                  readOnly
                  {...register("capai_sasaran")}
                />

                <SelectField
                  label={"Kategori Usahawan"}
                  options={capaiSasaranOps}
                  readOnly
                  {...register("kategori_usahawan")}
                />

                <SelectField
                  label={"Negeri Kebenaran"}
                  options={negeriOps}
                  readOnly
                  {...register("negeri_kebenaran")}
                />

                <SelectField
                  label={"PT Kebenaran"}
                  options={pusatTanggungjawabOps}
                  readOnly
                  {...register("pt_kebenaran")}
                />

                <SelectField
                  label={"Daerah Kebenaran"}
                  options={daerahOps}
                  readOnly
                  {...register("daerah_kebenaran")}
                />

                <SelectField
                  label={"Parlimen"}
                  options={parlimenOps}
                  readOnly
                  {...register("parlimen")}
                />

                <SelectField
                  label={"Dun"}
                  options={dunOps}
                  readOnly
                  {...register("dun")}
                />

                <TextField
                  id="tahun_bantuan"
                  label="Tahun Bantuan"
                  readOnly
                  {...register("tahun_bantuan")}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.goBack();
                }}
              >
                Kembali
              </Button>
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormInsentifBPU;
