import axios from "utils/axiosInstance";

export async function getKaedahPelaksanaOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kaedah-pelaksana",
    params,
  });
  return data;
}

export async function getKaedahPelaksanaDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/kaedah-pelaksana/all",
    params,
  });
  return data;
}


