import React, { useState, useMemo } from "react";
import { Flex } from "@chakra-ui/react";

import Header from "./components/layout/Header";
import Hero from "./components/layout/Hero";
import Footer from "./components/layout/Footer";
import Container from "./components/shared/Container";
import SimplePagination from "./components/shared/SimplePagination";
import FullScreenLoader from "components/loader/FullScreenLoader";
import KatalogFilter from "./components/katalog/KatalogFilter";
import KatalogList from "./components/katalog/KatalogList";

import { usePublicCatalogues } from "hooks/admin/catalogue/usePublicCatalogues";

import heroRisda from "assets/img/hero/hero.png";

const Katalog = () => {
  const { catalogues, isLoading } = usePublicCatalogues();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const approveCatalogues = useMemo(() => {
    if (!catalogues?.data) return [];

    return catalogues?.data?.filter(
      ({ is_published, is_approved }: any) =>
        is_published === 1 && is_approved === 1
    );
  }, [catalogues]);

  const paginatedCatalogues = useMemo(() => {
    if (!approveCatalogues) return [];
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return approveCatalogues?.slice(startIndex, endIndex);
  }, [approveCatalogues, currentPage, itemsPerPage]);

  const totalPages = Math.ceil(approveCatalogues?.length / itemsPerPage) || 0;

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  if (isLoading) return <FullScreenLoader />;

  return (
    <Flex flexDirection="column" minH="100vh">
      <Header />
      <Hero image={heroRisda} />
      <Container>
        <KatalogFilter />
        <KatalogList products={paginatedCatalogues} />
        {totalPages > 0 && (
          <SimplePagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </Container>
      <Footer />
    </Flex>
  );
};

export default Katalog;
