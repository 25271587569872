import React from "react";
import FormRole from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useRole } from "hooks/setting/role/useRole";

export default function EditRole() {
  const { role, isLoading } = useRole();

  if (isLoading) {
    return <FullScreenLoader />
  }

  return <FormRole action="edit" initialData={role?.data}/>;
}
