import axios from "utils/axiosInstance";
import { IBuletinFormValues } from "entities/admin/buletin";

export async function getBuletin(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/buletin/show/${id}`,
  });
  return data;
}

export async function getBuletins(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/buletin",
    params,
  });
  return data;
}

export async function getBuletinActive(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/buletin/active",
    params,
  });
  return data;
}

export async function createEditBuletin(
  values: IBuletinFormValues,
  id: string | number | null
) {
  const apiUrl = id ? `/buletin/update` : "/buletin";
  const formData = values;

  const { data } = await axios.call({
    customContentType: "multipart/form-data",
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}

export async function deleteBuletin(
  values: IBuletinFormValues,
  id: string | number | null
) {
  const apiUrl = `/buletin/delete`;
  const formData = values;

  const { data } = await axios.call({
    method: "post",
    url: apiUrl,
    data: formData,
  });
  return data;
}
