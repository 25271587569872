import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { negeriOps, daerahOps, pusatTanggungjawabOps, parlimenOps, dunOps, statusUsahawanOps } =
    useOptions();

  const [searchValues, setSearchValues] = useState({
    negeri_id: searchParams.get("negeri_id") || "",
    daerah_id: searchParams.get("daerah_id") || "",
    pt_id: searchParams.get("pt_id") || "",
    parlimen_id: searchParams.get("parlimen_id") || "",
    dun_id: searchParams.get("dun_id") || "",
    status: searchParams.get("status") || "",
    nama: searchParams.get("nama") || "",
    no_kp: searchParams.get("no_kp") || "",
    email: searchParams.get("email") || "",
    nama_syarikat: searchParams.get("nama_syarikat") || "",
    no_ssm: searchParams.get("no_ssm") || "",
    nama_perniagaan: searchParams.get("nama_perniagaan") || "",
    nama_pekebun_kecil: searchParams.get("nama_pekebun_kecil") || "",
    no_kp_pekebun_kecil: searchParams.get("no_kp_pekebun_kecil") || "",
    no_tanam_semula: searchParams.get("no_tanam_semula") || "",
    no_geran: searchParams.get("no_geran") || "",
    no_lot: searchParams.get("no_lot") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      negeri_id: "",
      daerah_id: "",
      pt_id: "",
      parlimen_id: "",
      dun_id: "",
      status: "",
      nama: "",
      no_kp: "",
      email: "",
      nama_syarikat: "",
      no_ssm: "",
      nama_perniagaan: "",
      nama_pekebun_kecil: "",
      no_kp_pekebun_kecil: "",
      no_tanam_semula: "",
      no_geran: "",
      no_lot: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Negeri"}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_id")}
            value={searchValues.negeri_id}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pt_id")}
            value={searchValues.pt_id}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_id")}
            value={searchValues.daerah_id}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            onChange={(e: any) => handleOnChange(e, "parlimen_id")}
            value={searchValues.parlimen_id}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Dun"}
            options={dunOps}
            onChange={(e: any) => handleOnChange(e, "dun_id")}
            value={searchValues.dun_id}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Status"}
            options={statusUsahawanOps}
            onChange={(e: any) => handleOnChange(e, "status")}
            value={searchValues.status}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="Ramli Saad"
            fontWeight="500"
            size="md"
            value={searchValues.nama}
            onChange={(e: any) => handleOnTextChange(e, "nama")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Kad Pengenalan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_kp}
            onChange={(e: any) => handleOnTextChange(e, "no_kp")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Email
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder="ramli@gmail.com"
            fontWeight="500"
            size="md"
            value={searchValues.email}
            onChange={(e: any) => handleOnTextChange(e, "email")}
            autoComplete="nope"
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Syarikat
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.nama_syarikat}
            onChange={(e: any) => handleOnTextChange(e, "nama_syarikat")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No. SSM
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_ssm}
            onChange={(e: any) => handleOnTextChange(e, "no_ssm")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Perniagaan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.nama_perniagaan}
            onChange={(e: any) => handleOnTextChange(e, "nama_perniagaan")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Pekebun Kecil
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.nama_pekebun_kecil}
            onChange={(e: any) => handleOnTextChange(e, "nama_pekebun_kecil")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No. Kad Pengenalan Pekebun Kecil
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_kp_pekebun_kecil}
            onChange={(e: any) => handleOnTextChange(e, "no_kp_pekebun_kecil")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Tanam Semula
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_tanam_semula}
            onChange={(e: any) => handleOnTextChange(e, "no_tanam_semula")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Geran
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_geran}
            onChange={(e: any) => handleOnTextChange(e, "no_geran")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No Lot
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="100897"
            fontWeight="500"
            size="md"
            value={searchValues.no_lot}
            onChange={(e: any) => handleOnTextChange(e, "no_lot")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
