import React from "react";
import {
  Box,
  FormControl,
  Flex,
  Button,
  Text,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";

import Card from "components/card/Card";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

interface LocationState {
  nama?: string;
  no_kp_ssm?: string;
}

const testingData = {
  pdf: "https://pdfobject.com/pdf/sample.pdf",
};

const JanaanBukuTunai = () => {
  const history = useHistory();
  const { state } = useLocation<LocationState>();
  const { bulanOps, tahunOps } = useOptions();

  if (!state?.nama && !state?.no_kp_ssm) {
    history.push("/admin/kewangan/buku-tunai");
  }

  if (false) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card flexDirection="column" w="100%" p={"28px"}>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="18px"
          mb={"20px"}
        >
          <FormControl>
            <FormLabel variant="main" mb="8px">
              Nama
            </FormLabel>

            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              fontWeight="500"
              size="md"
              value={state?.nama}
              readOnly
            />
          </FormControl>

          <FormControl>
            <FormLabel variant="main" mb="8px">
              No. KP/ SSM
            </FormLabel>

            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              fontWeight="500"
              size="md"
              value={state?.no_kp_ssm}
              readOnly
            />
          </FormControl>
        </Flex>

        <Flex
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          gap="18px"
          mb={"38px"}
        >
          <SelectField label={"Bulan"} options={bulanOps} />

          <SelectField label={"Tahun"} options={tahunOps} />

          <Flex gap={2} alignItems="center" alignSelf="flex-end">
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="max-content"
              px="18px"
            >
              Jana
            </Button>

            <Button
              fontSize="sm"
              variant="outline"
              fontWeight="500"
              w="max-content"
            >
              Set Semula
            </Button>
          </Flex>
        </Flex>

        <Flex alignItems={"center"} pb={"20px"}>
          {testingData?.pdf ? (
            <Box
              as="iframe"
              src={testingData.pdf}
              height={"600px"}
              width="100%"
            />
          ) : (
            <Text fontWeight="bold" fontSize={{ sm: "14px" }}>
              Tiada Janaan Buku Tunai
            </Text>
          )}
        </Flex>
      </Card>
    </Box>
  );
};

export default JanaanBukuTunai;
