import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";

import { formatDate } from "utils/helpers";
import SelectField from "components/fields/SelectField";
import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";

import { ISyarikatFormValues } from "entities/admin/usahawan";

interface FormSyarikatProps {
  action: "edit" | "show";
  initialData?: ISyarikatFormValues;
  negeriData?: any;
  daerahData?: any;
  bandarData?: any;
  dunData?: any;
  parlimenData?: any;
}

const schema = yup
  .object({
    nama_syarikat: yup.string().notRequired(),
  })
  .required();

const FormSyarikat: React.FC<FormSyarikatProps> = ({
  action,
  initialData,
  negeriData,
  daerahData,
  bandarData,
  dunData,
  parlimenData,
}) => {
  const [negeriOps, setNegeriOps] = useState<any>(negeriData ?? []);
  const [daerahOps, setDaerahOps] = useState<any>([]);
  const [bandarOps, setBandarOps] = useState<any>([]);
  const [dunOps, setDunOps] = useState<any>([]);
  const [parlimenOps, setParlimenOps] = useState<any>([]);

  const history = useHistory();

  const { statusBumiputeraOps, statusSyarikatOps } = useOptions();
  const { editUsahawan, isEditing } = useEditUsahawan();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ISyarikatFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...initialData,
      tarikh_mula_mof: formatDate(initialData?.tarikh_mula_mof),
      tarikh_tamat_mof: formatDate(initialData?.tarikh_tamat_mof),
      tarikh_daftar_ssm: formatDate(initialData?.tarikh_daftar_ssm),
    },
  });

  const watchNegeri = watch("negeri_id");
  const watchDaerah = watch("daerah_id");
  const watchParlimen = watch("parlimen_id");

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        syarikats: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === "negeri_id" && daerahData) {
        setValue("daerah_id", null);
        setValue("parlimen_id", null);

        const daerah = daerahData
          .filter((daerah: any) => daerah.u_negeri_id == value.negeri_id)
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        const parlimen = bandarData.filter(
          (bandar: any) => bandar.negeri_id == value.negeri_id
        );

        setDaerahOps(daerah);
      }

      if (name === "negeri_id" && bandarData) {
        setValue("bandar_id", null);

        const bandar = bandarData.filter(
          (bandar: any) => bandar.negeri_id == value.negeri_id
        );

        setBandarOps(bandar);
      }

      if (name === "negeri_id" && parlimenData) {
        setValue("parlimen_id", null);

        const parlimen = parlimenData.filter(
          (parlimen: any) => parlimen.negeri_id == value.negeri_id
        );

        setParlimenOps(parlimen);
      }

      if (name === "parlimen_id" && dunData) {
        setValue("dun_id", null);

        const dun = dunData.filter(
          (dun: any) => dun.parlimen_id == value.parlimen_id
        );

        setDunOps(dun);
      }
    });

    return () => subscription.unsubscribe();
  }, [negeriData, daerahData, watch, setValue]);

  useEffect(() => {
    if (initialData) {
      if (daerahData) {
        const daerah = daerahData
          .filter((daerah: any) => daerah.u_negeri_id == initialData.negeri_id)
          .map((daerah: any) => {
            return {
              label: daerah.daerah,
              value: daerah.id,
            };
          });

        setDaerahOps(daerah);
      }

      if (bandarData) {
        const bandar = bandarData
          .filter((bandar: any) => bandar.negeri_id == initialData.negeri_id);

        setBandarOps(bandar);
      }

      if (parlimenData) {
        const parlimen = parlimenData
          .filter((parlimen: any) => parlimen.negeri_id == initialData.negeri_id);
          
        setParlimenOps(parlimen);
      }

      if (dunData) {
        const dun = dunData
          .filter((dun: any) => dun.parlimen_id == initialData.parlimen_id);

        setDunOps(dun);
      }
    }
  }, [initialData, daerahData, bandarData, parlimenData, dunData]);

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <TextField
            id="nama_syarikat"
            label="Nama Syarikat"
            errorMessage={errors.nama_syarikat?.message}
            isInvalid={!!errors.nama_syarikat}
            readOnly={action === "show"}
            {...register("nama_syarikat")}
          />

          <TextField
            id="no_daftar_ssm"
            label="No. Daftar SSM"
            errorMessage={errors.no_daftar_ssm?.message}
            isInvalid={!!errors.no_daftar_ssm}
            readOnly={action === "show"}
            {...register("no_daftar_ssm")}
          />

          <TextField
            id="no_daftar_pbt"
            label="No. Daftar PBT"
            errorMessage={errors.no_daftar_pbt?.message}
            isInvalid={!!errors.no_daftar_pbt}
            readOnly={action === "show"}
            {...register("no_daftar_pbt")}
          />

          <TextField
            id="no_sijil_halal"
            label="No. Daftar Sijil Halal"
            errorMessage={errors.no_sijil_halal?.message}
            isInvalid={!!errors.no_sijil_halal}
            readOnly={action === "show"}
            {...register("no_sijil_halal")}
          />

          <TextField
            id="no_daftar_mesti"
            label="No. Daftar Sijil Mesti"
            errorMessage={errors.no_daftar_mesti?.message}
            isInvalid={!!errors.no_daftar_mesti}
            readOnly={action === "show"}
            {...register("no_daftar_mesti")}
          />

          <TextField
            id="tahun_mula_operasi"
            label="Tahun Mula Operasi"
            errorMessage={errors.tahun_mula_operasi?.message}
            isInvalid={!!errors.tahun_mula_operasi}
            readOnly={action === "show"}
            {...register("tahun_mula_operasi")}
          />

          <TextField
            id="bilangan_pekerja"
            label="Bilangan Pekerja"
            errorMessage={errors.bil_pekerja?.message}
            isInvalid={!!errors.bil_pekerja}
            readOnly={action === "show"}
            {...register("bil_pekerja")}
          />

          <TextField
            id="tarikh_mula_mof"
            label="Tarikh Mula MOF"
            type="date"
            errorMessage={errors.tarikh_mula_mof?.message}
            isInvalid={!!errors.tarikh_mula_mof}
            readOnly={action === "show"}
            {...register("tarikh_mula_mof")}
          />

          <TextField
            id="tarikh_tamat_mof"
            label="Tarikh Tamat MOF"
            type="date"
            errorMessage={errors.tarikh_tamat_mof?.message}
            isInvalid={!!errors.tarikh_tamat_mof}
            readOnly={action === "show"}
            {...register("tarikh_tamat_mof")}
          />

          <TextField
            id="tarikh_daftar_ssm"
            label="Tarikh Daftar SSM"
            type="date"
            errorMessage={errors.tarikh_daftar_ssm?.message}
            isInvalid={!!errors.tarikh_daftar_ssm}
            readOnly={action === "show"}
            {...register("tarikh_daftar_ssm")}
          />

          <TextField
            id="alamat_syarikat_1"
            label="Alamat 1"
            errorMessage={errors.alamat_syarikat_1?.message}
            isInvalid={!!errors.alamat_syarikat_1}
            readOnly={action === "show"}
            {...register("alamat_syarikat_1")}
          />

          <TextField
            id="alamat_syarikat_2"
            label="Alamat 2"
            errorMessage={errors.alamat_syarikat_2?.message}
            isInvalid={!!errors.alamat_syarikat_2}
            readOnly={action === "show"}
            {...register("alamat_syarikat_2")}
          />

          <TextField
            id="alamat_syarikat_3"
            label="Alamat 3"
            errorMessage={errors.alamat_syarikat_3?.message}
            isInvalid={!!errors.alamat_syarikat_3}
            readOnly={action === "show"}
            {...register("alamat_syarikat_3")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            isDisabled={!watchNegeri}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            isDisabled={!watchDaerah}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            isDisabled={!watchNegeri}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            isDisabled={!watchParlimen}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <SelectField
            label={"Status Bumiputera"}
            options={statusBumiputeraOps}
            errorMessage={errors.status_bumiputera?.message}
            isInvalid={!!errors.status_bumiputera}
            readOnly={action === "show"}
            {...register("status_bumiputera")}
          />

          <TextField
            id="no_fon_syarikat"
            label="No. Fon Syarikat"
            errorMessage={errors.no_fon_syarikat?.message}
            isInvalid={!!errors.no_fon_syarikat}
            readOnly={action === "show"}
            {...register("no_fon_syarikat")}
          />

          <TextField
            id="no_hp_syarikat"
            label="No. HP Syarikat"
            errorMessage={errors.no_hp_syarikat?.message}
            isInvalid={!!errors.no_hp_syarikat}
            readOnly={action === "show"}
            {...register("no_hp_syarikat")}
          />

          <TextField
            id="email_syarikat"
            label="Email Syarikat"
            errorMessage={errors.email_syarikat?.message}
            isInvalid={!!errors.email_syarikat}
            readOnly={action === "show"}
            {...register("email_syarikat")}
          />

          <TextField
            id="catatan_syarikat"
            label="Catatan Syarikat"
            errorMessage={errors.catatan_syarikat?.message}
            isInvalid={!!errors.catatan_syarikat}
            readOnly={action === "show"}
            {...register("catatan_syarikat")}
          />

          <TextField
            id="koordinat_syarikat"
            label="Koordinat Syarikat"
            errorMessage={errors.koordinat?.message}
            isInvalid={!!errors.koordinat}
            readOnly={action === "show"}
            {...register("koordinat")}
          />

          <TextField
            id="facebook"
            label="Facebook"
            errorMessage={errors.facebook?.message}
            isInvalid={!!errors.facebook}
            readOnly={action === "show"}
            {...register("facebook")}
          />

          <TextField
            id="instagram"
            label="Instagram"
            errorMessage={errors.instagram?.message}
            isInvalid={!!errors.instagram}
            readOnly={action === "show"}
            {...register("instagram")}
          />

          <TextField
            id="twitter"
            label="Twitter"
            errorMessage={errors.twitter?.message}
            isInvalid={!!errors.twitter}
            readOnly={action === "show"}
            {...register("twitter")}
          />

          <TextField
            id="laman_web"
            label="Laman Web"
            errorMessage={errors.laman_web?.message}
            isInvalid={!!errors.laman_web}
            readOnly={action === "show"}
            {...register("laman_web")}
          />

          <SelectField
            label={"Status Syarikat"}
            options={statusSyarikatOps}
            errorMessage={errors.status_syarikat_perniagaan_id?.message}
            isInvalid={!!errors.status_syarikat_perniagaan_id}
            readOnly={action === "show"}
            {...register("status_syarikat_perniagaan_id")}
          />

          <TextField
            id="prefix"
            label="Prefix"
            errorMessage={errors.prefix?.message}
            isInvalid={!!errors.prefix}
            readOnly={action === "show"}
            {...register("prefix")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            isDisabled={isEditing}
          >
            Kemaskini Maklumat Syarikat
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormSyarikat;
