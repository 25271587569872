import React, { useState, useEffect } from "react";
import { Flex, Text, Button, ButtonGroup } from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";

interface PaginationApiProps {
  currentPage: number;
  lastPage: number;
}

const PaginationApi: React.FC<PaginationApiProps> = ({
  currentPage = 1,
  lastPage = 1,
}) => {
  const [pages, setPages] = useState([]);

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const changePage = (page: number) => {
    searchParams.set("page", page.toString());
    history.push({ search: searchParams.toString() });
  };

  const renderPageNumber = () => {
    if (pages.length > 0) {
      return pages.map((page, index) => {
        if (page === "...") {
          return (
            <Button key={index + page} isDisabled>
              ...
            </Button>
          );
        }

        return (
          <Button
            key={index}
            colorScheme={page === currentPage ? "green" : undefined}
            onClick={() => changePage(page)}
          >
            {page}
          </Button>
        );
      });
    }

    return null;
  };

  useEffect(() => {
    const newPages = [];

    for (let page = 1; page <= lastPage; page++) {
      newPages.push(page);
    }

    if (newPages.length > 6) {
      const first = newPages.slice(0, 1);
      const last = newPages.slice(-1);
      let middle: (number | string)[] = [];

      if (currentPage <= 3) {
        middle = [2, 3, 4, '...'];
      } else if (currentPage >= lastPage - 2) {
        middle = ['...', lastPage - 3, lastPage - 2, lastPage - 1];
      } else {
        middle = ['...', currentPage - 1, currentPage, '...'];
      }

      setPages([...first, ...middle, ...last]);
    } else {
      setPages(newPages);
    }
  }, [currentPage, lastPage]);

  return (
    <Flex
      flexDirection={{ base: "column", md: "row" }}
      gap={6}
      justifyContent={{ base: "center", md: "end" }}
      alignItems="center"
    >
      <ButtonGroup size="sm" isAttached spacing={4}>
        <Button
          onClick={() => changePage(currentPage - 1)}
          isDisabled={currentPage === 1}
        >
          Sebelum
        </Button>

        {renderPageNumber()}

        <Button
          onClick={() => changePage(currentPage + 1)}
          isDisabled={currentPage === lastPage}
        >
          Seterusnya
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default PaginationApi;