import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { negeriOps, daerahOps, pusatTanggungjawabOps,tahapKebenaranOps, roleOps } = useOptions();

  const [searchValues, setSearchValues] = useState({
    role_id: searchParams.get("role_id") || "",
    tahap_kebenaran: searchParams.get("tahap_kebenaran") || "",
    negeri_kebenaran: searchParams.get("negeri_kebenaran") || "",
    pt_kebenaran: searchParams.get("pt_kebenaran") || "",
    daerah_kebenaran: searchParams.get("daerah_kebenaran") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      role_id: "",
      tahap_kebenaran: "",
      negeri_kebenaran: "",
      pt_kebenaran: "",
      daerah_kebenaran: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Nama Peranan"}
            value={searchValues.role_id}
            options={roleOps}
            onChange={(e: any) => handleOnChange(e, "role_id")}
            
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Tahap Kebenaran"}
            value={searchValues.tahap_kebenaran}
            options={tahapKebenaranOps}
            onChange={(e: any) => handleOnChange(e, "tahap_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Negeri"}
            value={searchValues.negeri_kebenaran}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            value={searchValues.pt_kebenaran}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pt_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            value={searchValues.daerah_kebenaran}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_kebenaran")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
