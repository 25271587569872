import React from "react";
import FormNotification from "./components/Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useNotification } from "hooks/setting/notifikasi/useNotification";

export default function EditNotification() {
  const { notifikasi, isLoading, isInitialLoading } = useNotification();

  if (isLoading || isInitialLoading) {
    return <FullScreenLoader />
  }

  return <FormNotification action="edit" initialData={notifikasi?.data} />;
}
