import { useEffect } from 'react';
import Cookies from "js-cookie";

const useAuthTokenExpiry = () => {
  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get('userToken');
      if (!token) {
        Cookies.remove('userToken');
      }
    }, 300000);

    return () => clearInterval(interval);
  }, []);
};

export function useAuth() {
  useAuthTokenExpiry();
  
  const storeAuthToken = (authToken: string, rememberMe: boolean) => {
    Cookies.set("userToken", authToken, {
      // Set the expiration of the cookie to 1 day if 'rememberMe' is true, otherwise set it to 1 hour
      expires: rememberMe ? 1 : 1/24,
      // Ensure the cookie is sent only over HTTPS
      secure: true,
      // Restrict the cookie from being sent with cross-site requests
      sameSite: "Strict",
    });
  };

  const getAuthToken = () => {
    return Cookies.get('userToken');
  };

  const clearAuthToken = () => {
    Cookies.remove('userToken');
  };

  return { storeAuthToken, getAuthToken, clearAuthToken };
}
