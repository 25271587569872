import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { FormControl, FormLabel, Select, Flex } from "@chakra-ui/react";

const KatalogFilter = () => {
  const [searchValues, setSearchValues] = useState({
    negeri_id: "",
    sort: "",
  });

  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => ({
      ...prevState,
      [fieldName]: selectedValue,
    }));
    
    if (fieldName === "negeri_id" && selectedValue === "semua") {
      searchParams.delete("negeri_id");
    } else if (selectedValue) {
      searchParams.set(fieldName, selectedValue);
    } else {
      searchParams.delete(fieldName);
    }
    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    setSearchValues({
      negeri_id: searchParams.get('negeri_id') || '',
      sort: searchParams.get('sort') || '',
    });
  }, []);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection={{ base: "column", md: "row" }}
    >
      <FormControl
        as={Flex}
        flexDirection="row"
        alignItems="center"
        width="max-content"
        mb={{ base: 4, md: 0 }}
      >
        <FormLabel fontSize="lg" color="gray.500">
          Paparan
        </FormLabel>
        <Select
          value={searchValues.negeri_id}
          onChange={(e) => handleOnChange(e, "negeri_id")}
          fontSize="sm"
          variant="main"
          fontWeight="600"
          width="180px"
        >
          <option value="semua">Semua</option>
          <option value="1">Johor</option>
          <option value="2">Kedah</option>
          <option value="3">Kelantan</option>
          <option value="4">Melaka</option>
          <option value="5">Negeri Sembilan</option>
          <option value="6">Pahang</option>
          <option value="7">Pulau Pinang</option>
          <option value="8">Perak</option>
          <option value="9">Perlis</option>
          <option value="10">Selangor</option>
          <option value="11">Terengganu</option>
          <option value="12">Sabah</option>
          <option value="13">Sarawak</option>
          <option value="14">Kuala Lumpur</option>
          <option value="15">Labuan</option>
          <option value="16">Putrajaya</option>
        </Select>
      </FormControl>

      <FormControl
        as={Flex}
        flexDirection="row"
        alignItems="center"
        width="max-content"
      >
        <FormLabel fontSize="lg" color="gray.500">
          Susunan
        </FormLabel>
        <Select
          value={searchValues.sort}
          onChange={(e) => handleOnChange(e, "sort")}
          fontSize="sm"
          variant="main"
          fontWeight="600"
          width="180px"
        >
          <option value="" disabled>
            Pilihan
          </option>
          <option value="desc">Terbaru</option>
          <option value="asc">Terdahulu</option>
        </Select>
      </FormControl>
    </Flex>
  );
};

export default KatalogFilter;