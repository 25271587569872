import axios from "utils/axiosInstance";

export async function getNotification(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/notifikasi/show/${id}`,
  });
  return data;
}

export async function getNotifications(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/notifikasi",
    params,
  });
  return data;
}


export async function createEditNotification(
  values: any,
  id: string | null
) {
  const apiUrl = id ? `/notifikasi/update` : "/notifikasi";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}

export async function publishNotification(id: string) {
  const { data } = await axios.call({
    method: "post",
    url: `/notifikasi/publish/${id}`,
  });
  return data;
}
