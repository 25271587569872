import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";

import useOptions from "hooks/setting/general/useOptions";

import { IPertanianTersediaFormValues } from "entities/admin/pertanian-tersedia";
import { useCreatePertanianTersedia } from "hooks/admin/pertanian-tersedia/useCreatePertanianTersedia";
import { useEditPertanianTersedia } from "hooks/admin/pertanian-tersedia/useEditPertanianTersedia";
import { formatDate } from "utils/helpers";

interface FormPertanianTersediaProps {
  action: "create" | "edit" | "show";
  initialData?: IPertanianTersediaFormValues;
}

const FormPertanianTersedia: React.FC<FormPertanianTersediaProps> = ({
  action,
  initialData,
}) => {
  const { createPertanianTersedia, isCreating } = useCreatePertanianTersedia();
  const { editPertanianTersedia, isEditing } = useEditPertanianTersedia();

  const { tapakProjekOps, namaHasilOps } = useOptions();

  const history = useHistory();

  const schema = yup
    .object({
      tapak_projek_id: yup.number().required(),
      kuantiti: yup.string().required(),
      nama_hasil_id: yup.number().required(),
      catatan: yup.string().required(),
      tarikh_tersedia: yup.date().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IPertanianTersediaFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createPertanianTersedia({
        newPertanianTersedia: {
          ...data,
          tarikh_tersedia: formatDate(data.tarikh_tersedia),
        },
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editPertanianTersedia({
        newPertanianTersedia: {
          ...data,
          tarikh_tersedia: formatDate(data.tarikh_tersedia),
        },
        id: `${data.id}`,
      });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Pertanian Tersedia">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <SelectField
                  label={"Tapak Projek"}
                  options={tapakProjekOps}
                  isInvalid={!!errors.tapak_projek_id}
                  errorMessage={errors.tapak_projek_id?.message}
                  readOnly={action === "show"}
                  {...register("tapak_projek_id")}
                />

                <TextField
                  id="kuantiti"
                  label="Kuantiti"
                  isInvalid={!!errors.kuantiti}
                  errorMessage={errors.kuantiti?.message}
                  readOnly={action === "show"}
                  {...register("kuantiti")}
                />

                <SelectField
                  label={"Nama Hasil"}
                  options={namaHasilOps}
                  isInvalid={!!errors.nama_hasil_id}
                  errorMessage={errors.nama_hasil_id?.message}
                  readOnly={action === "show"}
                  {...register("nama_hasil_id")}
                />

                <TextField
                  id="catatan"
                  label="Catatan"
                  isInvalid={!!errors.catatan}
                  errorMessage={errors.catatan?.message}
                  readOnly={action === "show"}
                  type="textarea"
                  {...register("catatan")}
                />

                <TextField
                  id="tarikh_tersedia"
                  label="Tarikh Tersedia"
                  type="date"
                  isInvalid={!!errors.tarikh_tersedia}
                  errorMessage={errors.tarikh_tersedia?.message}
                  readOnly={action === "show"}
                  {...register("tarikh_tersedia")}
                />
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/pertanian-tersedia");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Pertanian Tersedia"
                    : "Tambah Pertanian Tersedia Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormPertanianTersedia;
