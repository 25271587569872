import axios from "utils/axiosInstance";

export async function getAudit(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/audit-trail/show/${id}`,
  });
  return data;
}

export async function getAudits() {
  const { data } = await axios.call({
    method: "get",
    url: "/audit-trail",
  });
  return data;
}
