import React from "react";
import { NavLink } from "react-router-dom";
import {
  Flex,
  Image,
  Text,
  SimpleGrid,
  Heading,
  Button,
  Icon,
  Box,
  Link,
} from "@chakra-ui/react";
import {
  FaDownload,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaUserCircle,
} from "react-icons/fa";
import Card from "components/card/Card";

import noimage from "assets/img/catalog/no-image.png";

interface KatalogDescriptionProps {
  product: any;
}

const KatalogDescription = ({ product }: KatalogDescriptionProps) => {
  return (
    <Flex flexDirection="column" gap={10}>
      <Card border="1px solid #E2E8F0" boxShadow="md" p={8}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Image
            src={
              !product?.gambar_produk_path
                ? noimage
                : `https://${product?.gambar_produk_path}`
            }
            alt={product?.nama_produk || "Gambar Produk"}
            objectFit="cover"
            width="100%"
            height={450}
            borderRadius="10px"
            boxShadow="md"
          />
          <Flex flexDirection="column" gap={6}>
            <Heading as="h3" size="xl">
              {product?.nama_produk}
            </Heading>
            <Flex flexDirection="column" gap="2px">
              <Text fontSize="base" fontWeight="bold">
                Keterangan Produk
              </Text>
              <Text fontSize="base">{product?.deskripsi_produk}</Text>
            </Flex>
            <Flex flexDirection="column" gap="2px">
              <Text fontSize="base" fontWeight="bold">
                Keterangan Harga
              </Text>
              <Text fontSize="base">{product?.keterangan_harga}</Text>
            </Flex>

            {product?.download_url && (
              <Button
                rightIcon={<FaDownload />}
                variant="solid"
                fontSize="md"
                fontWeight="600"
                colorScheme="green"
                width="max-content"
                as={Link}
                href={`https://${product?.download_url}`}
                isExternal
                mt={4}
              >
                Muat Turun Katalog
              </Button>
            )}
          </Flex>
        </SimpleGrid>
      </Card>

      {product?.user && (
        <Card border="1px solid #E2E8F0" boxShadow="md" p={6}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap={6}>
              <Icon as={FaUserCircle} w={12} h={12} color="green.500" />
              <Flex flexDirection="column" gap={1}>
                <Text fontWeight="bold" fontSize="lg">
                  {product?.user?.name}
                </Text>
                <Flex gap={6} flexWrap="wrap">
                  <Flex alignItems="center" gap={2}>
                    <Icon as={FaEnvelope} color="green.500" />
                    <Text>{product?.user?.email}</Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <Icon as={FaPhone} color="green.500" />
                    <Text>{product?.user?.phone_no}</Text>
                  </Flex>
                  {/* <Flex alignItems="center" gap={2}>
                  <Icon as={FaMapMarkerAlt} color="green.500" />
                  <Text>Negeri Sembilan</Text>
                </Flex> */}
                </Flex>
              </Flex>
            </Flex>
            {/* <Button variant="outline" colorScheme="green">
            Lain-Lain Katalog
          </Button> */}
          </Flex>
        </Card>
      )}
    </Flex>
  );
};

export default KatalogDescription;
