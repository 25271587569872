import React from "react";
import QRCode from "qrcode.react";
import { Flex, Text } from "@chakra-ui/react";

interface QrDisplayProps {
  qrCode?: string;
}

const QrDisplay: React.FC<QrDisplayProps> = ({
  // qrCode = "https://www.example.com/join_activity?id=12345",
  qrCode,
}) => {
  const downloadQR = () => {
    const canvas = document.getElementById(qrCode) as HTMLCanvasElement;
    const jpegUrl = canvas.toDataURL("image/png");

    let downloadLink = document.createElement("a");
    downloadLink.href = jpegUrl.replace(
      /^data:image\/[^;]/,
      "data:application/octet-stream"
    );
    downloadLink.download = "QR Code.jpeg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const openQRInNewTab = () => {
    const canvas = document.getElementById(qrCode) as HTMLCanvasElement;
    const jpegUrl = canvas.toDataURL("image/png");

    const newTab = window.open();
    if (newTab) {
      newTab.document.body.innerHTML = `
      <img src="${jpegUrl}" alt="QR Code" style="width: 100%; height: auto; max-width: 400px; display: block;" />
    `;
    }
  };

  if (!qrCode) {
    return (
      <Text pl="4px" fontSize="sm" fontWeight="500">
        Belum Dijana
      </Text>
    );
  }

  return (
    <Flex flexDirection={"column"} gap={"10px"}>
      {qrCode.includes("data:image") ? (
        <img
          src={qrCode}
          width="200px"
          style={{
            cursor: "pointer",
          }}
          onClick={openQRInNewTab}
        />
      ) : (
        <QRCode
          id={qrCode}
          value={qrCode}
          size={200}
          level={"H"}
          includeMargin={true}
          style={{
            cursor: "pointer",
          }}
          onClick={openQRInNewTab}
        />
      )}
    </Flex>
  );
};

export default QrDisplay;
