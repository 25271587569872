import React from "react";
import FormBangsaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";

export default function EditBangsaSetting() {
  if (false) {
    return <FullScreenLoader />;
  }

  return <FormBangsaSetting action="edit" initialData={null} />;
}
