import { useMutation } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import {
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
} from "services/auth/authService";
import {
  IForgotPasswordFormValues,
  IResetPasswordFormValues,
} from "entities/auth/auth";
import { useAuth } from "hooks/auth/useAuth";

export function useForgotPassword() {
  const history = useHistory();
  const toast = useToast();

  const { mutate: forgotPassword, isLoading } = useMutation({
    mutationFn: ({ email }: IForgotPasswordFormValues) =>
      forgotPasswordApi({ email }),
    onSuccess: ({ data }, variables) => {
      history.push("/auth/sign-in");
      toast({
        title:
          "Emel berjaya dihantar. Sila periksa emel untuk arahan selanjutnya.",
        status: "success",
        isClosable: true,
      });
    },
    onError: (err: any) => {
      console.log("ERROR", err);

      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { forgotPassword, isLoading };
}

export function useResetPassword() {
  const history = useHistory();
  const toast = useToast();

  const { mutate: resetPassword, isLoading } = useMutation({
    mutationFn: ({
      email,
      password,
      password_confirmation,
      token,
    }: IResetPasswordFormValues) =>
      resetPasswordApi({ email, password, password_confirmation, token }),
    onSuccess: ({ data }, variables) => {
      history.push("/auth/sign-in");
      toast({
        title:
          "Kata laluan telah berjaya ditetapkan semula. Sila log masuk menggunakan kata laluan yang baru.",
        status: "success",
        isClosable: true,
      });
    },
    onError: (err: any) => {
      console.log("ERROR", err);

      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { resetPassword, isLoading };
}
