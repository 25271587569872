import React, { useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    negeri_id: searchParams.get("negeri_id") || "",
    pt_id: searchParams.get("pt_id") || "",
    daerah_id: searchParams.get("daerah_id") || "",
    nama_usahawan: searchParams.get("nama_usahawan") || "",
    nama_produk: searchParams.get("nama_produk") || "",
    status: searchParams.get("status") || "",
    status_direktori: searchParams.get("status_direktori") || "",
  });

  const { statusPaparanKatalogOps } = useOptions();
  const { negeris, isLoading: isLoadingNegeri } = useNegeriSettings();
  const { pts, isLoading: isLoadingPT } = usePTSettings();
  const { daerahs, isLoading: isLoadingDaerah } = useDaerahSettings();

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      negeri_id: "",
      pt_id: "",
      daerah_id: "",
      nama_usahawan: "",
      nama_produk: "",
      status: "",
      status_direktori: "",
    });

    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  const ptOptions = useMemo(() => {
    if (!pts?.data?.items) return [];

    return pts?.data?.items.map((item: any) => ({
      label: item.keterangan,
          value: item.id,
    }));
  }, [pts]);


  const daerahOptions = useMemo(() => {
    if (!daerahs?.data?.items) return [];

    return daerahs?.data?.items.map((item: any) => ({
      label: item.daerah,
          value: item.id,
    }));
  }, [daerahs]);

  if (isLoadingNegeri || isLoadingPT || isLoadingDaerah) {
    return <FullScreenLoader />;
  }

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <SelectField
          label={"Negeri"}
          value={searchValues.negeri_id}
          options={negeris?.data?.items}
          onChange={(e: any) => handleOnChange(e, "negeri_id")}
        />

        <SelectField
          label={"Pusat Tanggungjawab"}
          value={searchValues.pt_id}
          options={ptOptions}
          onChange={(e: any) => handleOnChange(e, "pt_id")}
        />

        <SelectField
          label={"Daerah"}
          value={searchValues.daerah_id}
          options={daerahOptions}
          onChange={(e: any) => handleOnChange(e, "daerah_id")}
        />

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Usahawan
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_usahawan}
            onChange={(e: any) => handleOnTextChange(e, "nama_usahawan")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Produk
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_produk}
            onChange={(e: any) => handleOnTextChange(e, "nama_produk")}
          />
        </FormControl>

        <SelectField
          label={"Status"}
          value={searchValues.status}
          options={[
            { value: "2", label: "Draf" },
            { value: "0", label: "Menunggu Pengesahan" },
            { value: "1", label: "Disahkan" },
          ]}
          onChange={(e: any) => handleOnChange(e, "status")}
        />

        <SelectField
          label={"Status Direktori"}
          value={searchValues.status_direktori}
          options={statusPaparanKatalogOps}
          onChange={(e: any) => handleOnChange(e, "status_direktori")}
        />
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
