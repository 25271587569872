import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Text,
  Spinner,
} from "@chakra-ui/react";

import DefaultAuth from "components/layouts/auth/Default";
import TextField from "components/fields/TextField";
import illustration from "assets/img/auth/auth.png";

import {
  IForgotPasswordFormValues,
  IResetPasswordFormValues,
} from "entities/auth/auth";
import {
  useForgotPassword,
  useResetPassword,
} from "hooks/auth/useForgotPassword";

const forgotPasswordSchema = yup
  .object({
    email: yup.string().required(),
  })
  .required();

const resetPasswordSchema = yup
  .object({
    email: yup.string().required(),
    password: yup.string().required("Password is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState("");

  const { forgotPassword, isLoading } = useForgotPassword();
  const { resetPassword, isLoading: isLoadingResetPassword } =
    useResetPassword();

  const location = useLocation();

  useEffect(() => {
    // Retrieve query parameters
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    setResetPasswordToken(token);
  }, [location.search]);

  const {
    register: registerForgotPassword,
    handleSubmit: handleSubmitForgotPassword,
    formState: { errors: forgotPasswordErrors },
  } = useForm<IForgotPasswordFormValues>({
    resolver: yupResolver(forgotPasswordSchema) as any,
  });

  const {
    register: registerResetPassword,
    handleSubmit: handleSubmitResetPassword,
    formState: { errors: resetPasswordErrors },
  } = useForm<IResetPasswordFormValues>({
    resolver: yupResolver(resetPasswordSchema) as any,
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = handleSubmitForgotPassword((data) => {
    forgotPassword(data);
  });

  const onSubmitResetPassword = handleSubmitResetPassword((data) => {
    resetPassword({
      token: resetPasswordToken,
      ...data
    });
  });

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      {resetPasswordToken ? (
        <form onSubmit={onSubmitResetPassword}>
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "16vh" }}
            float="right"
            flexDirection="column"
          >
            <Box me="auto">
              <Heading color={"navy.700"} fontSize="36px" mb="10px">
                Lupa kata laluan?
              </Heading>
              <Text mb="72px" ms="4px" variant="subtitle" fontSize="md">
                Sila tetapkan semula kata laluan anda!
              </Text>
            </Box>

            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <TextField
                id="email"
                label="Emel"
                placeholder="Masukkan Emel"
                isInvalid={!!resetPasswordErrors.email}
                errorMessage={resetPasswordErrors.email?.message}
                {...registerResetPassword("email")}
              />

              <TextField
                id="password"
                label="Kata laluan"
                placeholder="Min. 5 characters"
                type="password"
                marginTopForm="24px"
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                isInvalid={!!resetPasswordErrors.password}
                errorMessage={resetPasswordErrors.password?.message}
                {...registerResetPassword("password")}
              />

              <TextField
                id="password_confirmation"
                label="Sahkan Kata laluan"
                placeholder="Min. 5 characters"
                type="password"
                marginTopForm="24px"
                showPassword={showPassword}
                toggleShowPassword={toggleShowPassword}
                isInvalid={!!resetPasswordErrors.password_confirmation}
                errorMessage={resetPasswordErrors.password_confirmation?.message}
                {...registerResetPassword("password_confirmation")}
              />

              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                mt="24px"
                disabled={isLoadingResetPassword}
              >
                {!isLoadingResetPassword ? "Hantar" : <Spinner />}
              </Button>

              <Flex justifyContent="space-between" align="center" mb="24px">
                <NavLink to="/auth/sign-in">
                  <Text fontSize="sm" w="124px" variant="link">
                    Kembali
                  </Text>
                </NavLink>
              </Flex>
            </Flex>
          </Flex>
        </form>
      ) : (
        <form onSubmit={onSubmit}>
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "40px", md: "16vh" }}
            float="right"
            flexDirection="column"
          >
            <Box me="auto">
              <Heading color={"navy.700"} fontSize="36px" mb="10px">
                Lupa kata laluan?
              </Heading>
              <Text mb="72px" ms="4px" variant="subtitle" fontSize="md">
                Masukkan emel yang telah didaftarkan untuk set semula kata
                laluan anda!
              </Text>
            </Box>

            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <TextField
                id="email"
                label="Emel"
                placeholder="Masukkan Emel"
                isInvalid={!!forgotPasswordErrors.email}
                errorMessage={forgotPasswordErrors.email?.message}
                {...registerForgotPassword("email")}
              />

              <Button
                type="submit"
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                mt="24px"
                disabled={isLoading}
              >
                {!isLoading ? "Hantar" : <Spinner />}
              </Button>

              <Flex justifyContent="space-between" align="center" mb="24px">
                <NavLink to="/auth/sign-in">
                  <Text fontSize="sm" w="124px" variant="link">
                    Kembali
                  </Text>
                </NavLink>
              </Flex>
            </Flex>
          </Flex>
        </form>
      )}
    </DefaultAuth>
  );
};

export default ForgotPassword;
