import React from "react";
import FormTetapan from "./FormTetapan";

interface TabUsahawanProps {
  action: "edit" | "show";
  usahawanId?: number;
  isActive?: string;
}

const TabUsahawan = ({ action, usahawanId, isActive }: TabUsahawanProps) => {
  return (
    <FormTetapan action={action} usahawanId={usahawanId} isActive={isActive} />
  );
};

export default TabUsahawan;
