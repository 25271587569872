import React, { useRef } from "react";
import {
  Flex,
  FormLabel,
  Input,
  FormControl,
  Link,
  Box,
  Text,
} from "@chakra-ui/react";

interface FileInputProps {
  id?: string;
  label: string;
  files: File[];
  fileUrls: string[];
  onFileChange: (files: FileList) => void;
  isDisabled?: boolean;
  multiple?: boolean;
}

const FileInput: React.FC<FileInputProps> = ({
  id,
  label,
  files = [],
  fileUrls = [],
  onFileChange,
  isDisabled = false,
  multiple = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Flex direction="column" mb={"30px"}>
      <FormControl>
        <FormLabel
          display="flex"
          ms="10px"
          htmlFor={id}
          fontSize="sm"
          color={"secondaryGray.900"}
          fontWeight="bold"
          _hover={{ cursor: "pointer" }}
        >
          {label}
        </FormLabel>

        <Box
          border="1px dashed #cccccc"
          borderRadius="16px"
          padding="20px"
          textAlign="center"
          cursor={isDisabled ? "not-allowed" : "pointer"}
          onClick={isDisabled ? () => {} : handleButtonClick}
        >
          <Input
            type="file"
            id={id}
            accept="application/pdf"
            onChange={(e) => onFileChange(e.target.files)}
            display="none"
            ref={inputRef}
            multiple={multiple}
          />

          <Text fontSize="xl" fontWeight="700" mb="12px">
            Muat Naik Fail
          </Text>

          <Text
            fontSize="sm"
            fontWeight="500"
            color="secondaryGray.500"
            mt="12px"
          >
            Saiz yang disyorkan: Maksimum 10MB
            <br />
            Jenis fail: PDF
            <br />
            <br />
            Letak atau klik untuk pilih fail yang dikehendaki
          </Text>

          <Box
            display="flex"
            flexDirection={"column"}
            gap="4px"
            marginTop="20px"
          >
            {files.map((file, index) => (
              <Text
                key={index}
                fontSize={"sm"}
                fontWeight="500"
                color={"brand.500"}
              >
                {file.name}
              </Text>
            ))}
          </Box>
        </Box>
      </FormControl>

      {fileUrls.length > 0 && (
        <Box>
          <Text pt={"12px"} pb={"8px"} pl={"8px"} fontSize="sm">
            Fail dimuat naik:
          </Text>

          <Flex direction={"column"} gap="4px">
            {fileUrls.map((file: any, index: number) => (
              <Link
                key={index}
                fontSize={"sm"}
                fontWeight="500"
                color={"brand.500"}
                href={`https://${file.file_path}`}
                isExternal
              >
                {file.file_path}
              </Link>
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default FileInput;