import guestRoutes from "./mappings/guestRoutes";
import mainRoutes from "./mappings/mainRoutes";
import reportRoutes from "./mappings/reportRoutes";
import settingRoutes from "./mappings/settingRoutes";

const routes = [
  ...mainRoutes,
  ...reportRoutes,
  ...settingRoutes,
  ...guestRoutes,
];

export default routes;
