import { useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getTindakanLanjutOptions } from "services/setting/general/tindakanLanjutService";
import { PAGE_SIZE } from "utils/constants";

export function useTindakanLanjutSettings(params?: any) {
  const queryClient = useQueryClient();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 50
    : Number(searchParams.get("itemsPerPage"));

  useEffect(() => {
    queryClient.prefetchQuery({
      queryKey: ["tindakan-lanjuts", currentPage, currentLimit],
      queryFn: () => getTindakanLanjutOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
    });
  }, [currentPage, currentLimit, params, queryClient]);

  const { isLoading, data: tindakanLanjuts } = useQuery({
    queryKey: ["tindakan-lanjuts", currentPage, currentLimit],
    queryFn: () => getTindakanLanjutOptions({ ...params, page: currentPage, itemsPerPage: currentLimit }),
  });

  // OUTPUT
  return {
    tindakanLanjuts,
    isLoading,
  };
}
