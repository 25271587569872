import axios from "utils/axiosInstance";

export async function getSubKategoriProjekOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/sub-kategori-projek-bpk",
    params,
  });
  return data;
}

export async function getSubKategoriProjekDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/sub-kategori-projek-bpk/all",
    params,
  });
  return data;
}


