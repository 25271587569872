import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const [searchValues, setSearchValues] = useState({
    nama_syarikat: searchParams.get("nama_syarikat") || "",
    no_ssm: searchParams.get("no_ssm") || "",
    emel_pentadbir_syarikat: searchParams.get("emel_pentadbir_syarikat") || "",
  });

  const handleOnTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      nama_syarikat: "",
      no_ssm: "",
      emel_pentadbir_syarikat: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <FormLabel variant="main" mb="8px">
            Nama Syarikat
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            fontWeight="500"
            size="md"
            value={searchValues.nama_syarikat}
            onChange={(e: any) => handleOnTextChange(e, "nama_syarikat")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            No. SSM
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="text"
            placeholder="571312106785"
            fontWeight="500"
            size="md"
            value={searchValues.no_ssm}
            onChange={(e: any) => handleOnTextChange(e, "no_ssm")}
          />
        </FormControl>

        <FormControl>
          <FormLabel variant="main" mb="8px">
            Emel Pentadbir Syarikat
          </FormLabel>

          <Input
            variant="auth"
            fontSize="sm"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            fontWeight="500"
            size="md"
            autoComplete="nope"
            value={searchValues.emel_pentadbir_syarikat}
            onChange={(e: any) => handleOnTextChange(e, "emel_pentadbir_syarikat")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>
      
      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
