import { IRoleFormValues } from "entities/setting/role";
import axios from "utils/axiosInstance";

export async function getProjekBpk(id: string) {
  const { data } = await axios.call({
    method: "get",
    url: `/projek-bpk/show/${id}`,
  });
  return data;
}

export async function getProjekBpks(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/projek-bpk",
    params,
  });
  return data;
}

export async function createEditProjekBpks(
  values: IRoleFormValues,
  id: string | null
) {
  const apiUrl = id ? `/projek-bpk/update` : "/projek-bpk";
  const apiMethod = "post";

  const { data } = await axios.call({
    method: apiMethod,
    url: apiUrl,
    data: values,
  });
  return data;
}
