import React from "react";
import { Box, Text, List, Icon, Button } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdFileDownload } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useTunaiMasuk } from "hooks/admin/kewangan/useTunaiMasuk";

import { ITunaiMasuk } from "entities/admin/kewangan";

import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<ITunaiMasuk>();

const TunaiMasuk = () => {
  const { tunaiMasuk, isLoading } = useTunaiMasuk();

  const columns = [
    columnHelper.accessor("kategori_aliran", {
      header: () => <Text variant="tableHeading">Kategori Aliran</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("aliran_khas", {
      header: () => <Text variant="tableHeading">Aliran Khas</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("program_aliran", {
      header: () => <Text variant="tableHeading">Program Aliran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("dibuat_oleh", {
      header: () => <Text variant="tableHeading">Nama Usahawan (Dibuat Oleh)</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("amaun_masuk", {
      header: () => <Text variant="tableHeading">Amaun Masuk</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("amaun_keluar", {
      header: () => <Text variant="tableHeading">Amaun Keluar</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_aliran", {
      header: () => <Text variant="tableHeading">Tarikh Aliran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {formatDate(info.getValue(), "dd-MM-yyyy")}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek_atau_jenisinsentif", {
      header: () => <Text variant="tableHeading">Nama Projek/ Insentif</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    // columnHelper.accessor((row) => row.id, {
    //   id: "action",
    //   header: () => <Text variant="tableHeading">Tindakan</Text>,
    //   cell: (info) => (
    //     <List minW="max-content" spacing={2}>
    //       <Button
    //         onClick={() =>
    //           window.open(info?.row?.original?.download_url, "_blank")
    //         }
    //         colorScheme="green"
    //         variant="outline"
    //         fontSize="sm"
    //         leftIcon={
    //           <Icon w="20px" h="20px" color={"green.500"} as={MdFileDownload} />
    //         }
    //       >
    //         Lampiran
    //       </Button>
    //     </List>
    //   ),
    // }),
  ];

  if (isLoading) return <FullScreenLoader />;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={tunaiMasuk}
        title={"Senarai Tunai Masuk Keluar"}
        resourceSortingChildren={<Sorting />}
        allowPagination
      />
    </Box>
  );
};

export default TunaiMasuk;
