import axios from "utils/axiosInstance";

export async function getTeknologiProjekOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/teknologi-projek-bpk",
    params,
  });
  return data;
}

export async function getTeknologiProjekDataOptions(params?: any) {
  const { data } = await axios.call({
    method: "get",
    url: "/general/teknologi-projek-bpk/all",
    params,
  });
  return data;
}


