import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Icon,
  Text,
} from "@chakra-ui/react";
import { MdUpload } from "react-icons/md";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import ImageDropzone from "components/fields/ImageDropzone";
import FileInput from "components/fields/FileInput";
import FullScreenLoader from "components/loader/FullScreenLoader";

import { useCreateLawatan } from "hooks/admin/lawatan/useCreateLawatan";
import { useEditLawatan } from "hooks/admin/lawatan/useEditLawatan";
import { ILawatanFormValues } from "entities/admin/lawatan";
import { useTindakanLanjutSettings } from "hooks/setting/general/tindakanLanjut/useTindakanLanjuts";
import useOptions from "hooks/setting/general/useOptions";

import { formatDate } from "utils/helpers";

interface FormLawatanProps {
  action: "create" | "edit" | "show";
  initialData?: ILawatanFormValues;
}

const FormLawatan: React.FC<FormLawatanProps> = ({ action, initialData }) => {
  const [images, setImages] = useState<string | string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const { createLawatan, isCreating } = useCreateLawatan();
  const { editLawatan, isEditing } = useEditLawatan();
  const { tindakanLanjuts, isLoading } = useTindakanLanjutSettings();
  const { statusLawatanOps } = useOptions();

  const history = useHistory();

  const schema = yup
    .object({
      tajuk_lawatan: yup.string().required(),
      tarikh_lawatan: yup.date().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILawatanFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      nama_usahawan:
        initialData?.user?.name || initialData?.profil_pelaksana?.nama_syarikat,
      no_kad_pengenalan:
        initialData?.user?.nric || initialData?.profil_pelaksana?.ssm_no,
      created_by: initialData?.created_by?.name,
      updated_by: initialData?.updated_by?.name,
      tarikh_lawatan: formatDate(
        initialData?.tarikh_lawatan,
        "yyyy-MM-dd HH:mm"
      ),
    },
  });

  const handleImagesSelected = (selectedImages: string | string[]) => {
    setImages(selectedImages);
  };

  const handleFileChange = (fileList: FileList) => {
    setFiles(Array.from(fileList));
  };

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createLawatan({
        newLawatan: {
          ...data,
          tarikh_lawatan: formatDate(
            data.tarikh_lawatan,
            "yyyy-MM-dd HH:mm:ss"
          ),
        },
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editLawatan({
        newLawatan: {
          ...data,
          tarikh_lawatan: formatDate(
            data.tarikh_lawatan,
            "yyyy-MM-dd HH:mm:ss"
          ),
          gambar: images,
          lampiran: files,
        },
        id: `${data.id}`,
      });
    }
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Butiran Lawatan">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {action !== "create" && (
                  <>
                    <TextField
                      id="nama_usahawan"
                      label={
                        initialData?.profil_pelaksana?.nama_syarikat
                          ? "Nama Syarikat"
                          : "Nama Usahawan"
                      }
                      type="text"
                      readOnly
                      {...register("nama_usahawan")}
                    />

                    <TextField
                      id="no_kad_pengenalan"
                      label={
                        initialData?.profil_pelaksana?.ssm_no
                          ? "No Daftar SSM"
                          : "No Kad Pengenalan"
                      }
                      type="text"
                      readOnly
                      {...register("no_kad_pengenalan")}
                    />
                  </>
                )}

                <TextField
                  id="tajuk_lawatan"
                  label="Tajuk Lawatan"
                  type="text"
                  isInvalid={!!errors.tajuk_lawatan}
                  errorMessage={errors.tajuk_lawatan?.message}
                  readOnly={action === "show"}
                  {...register("tajuk_lawatan")}
                />

                {action !== "create" && (
                  <>
                    <SelectField
                      label={"Status"}
                      options={statusLawatanOps}
                      isInvalid={!!errors.status}
                      errorMessage={errors.status?.message}
                      readOnly={action === "show"}
                      {...register("status")}
                    />

                    <TextField
                      id="created_by"
                      label="Dibuat Oleh Pegawai"
                      type="text"
                      readOnly
                      {...register("created_by")}
                    />

                    <TextField
                      id="updated_by"
                      label="Kemaskini Terkini Oleh"
                      type="text"
                      readOnly
                      {...register("updated_by")}
                    />
                  </>
                )}

                <SelectField
                  label={"Tindakan Lanjut"}
                  options={tindakanLanjuts?.data?.items}
                  isInvalid={!!errors.tindakan_lanjut_id}
                  errorMessage={errors.tindakan_lanjut_id?.message}
                  readOnly={action === "show"}
                  {...register("tindakan_lanjut_id")}
                />

                <TextField
                  id="tarikh_lawatan"
                  label="Tarikh Lawatan"
                  type="datetime-local"
                  isInvalid={!!errors.tarikh_lawatan}
                  errorMessage={errors.tarikh_lawatan?.message}
                  readOnly={action === "show"}
                  {...register("tarikh_lawatan")}
                />

                {action !== "create" && initialData?.status == "1" && (
                  <TextField
                    id="komen_lawatan"
                    label="Komen Lawatan"
                    type="textarea"
                    isInvalid={!!errors.komen_lawatan}
                    errorMessage={errors.komen_lawatan?.message}
                    readOnly={action === "show"}
                    {...register("komen_lawatan")}
                  />
                )}
              </SimpleGrid>

              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {action !== "create" && (
                  <>
                    <FormControl>
                      <FormLabel variant="main" mb="8px">
                        Gambar Lawatan
                      </FormLabel>

                      <ImageDropzone
                        onImagesSelected={handleImagesSelected}
                        imageUrl={initialData?.lawatan_gambars}
                        isMultiple
                        isDisabled={action === "show"}
                      />
                    </FormControl>

                    <FileInput
                      label="Lampiran"
                      files={files}
                      fileUrls={initialData?.lawatan_lampirans}
                      onFileChange={handleFileChange}
                      isDisabled={action === "show"}
                      multiple
                    />
                  </>
                )}
              </SimpleGrid>
            </Section>

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/lawatan");
                }}
              >
                Kembali
              </Button>

              {action !== "show" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Lawatan"
                    : "Tambah Lawatan Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormLawatan;
