import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";
import { useJenisBantuanDataSettings } from "hooks/setting/general/jenisBantuan/useJenisBantuanData";
import { useKategoriProjekDataSettings } from "hooks/setting/general/kategoriProjek/useKategoriProjekData";
import { useSubKategoriProjekDataSettings } from "hooks/setting/general/subKategoriProjek/useSubKategoriProjekData";
import { useNamaProjekDataSettings } from "hooks/setting/general/namaProjek/useNamaProjekData";
import { useTeknologiProjekDataSettings } from "hooks/setting/general/teknologiProjek/useTeknologiProjekData";
import { useJenisProjekDataSettings } from "hooks/setting/general/jenisProjek/useJenisProjekData";
import { useKodKumpulanProjekDataSettings } from "hooks/setting/general/kodKumpulanProjek/useKodKumpulanProjekData";
import { useKategoriPelaksanaDataSettings } from "hooks/setting/general/kategoriPelaksana/useKategoriPelaksanaData";
import { useKaedahPelaksanaDataSettings } from "hooks/setting/general/kaedahPelaksana/useKaedahPelaksanaData";
import { useKumpulanPelaksanaDataSettings } from "hooks/setting/general/kumpulanPelaksana/useKumpulanPelaksanaData"; 

import { IKodProjekFormValues } from "entities/admin/usahawan";

import { formatDate } from "utils/helpers";

interface FormKodProjekProps {
  action: "edit" | "show";
  initialData?: IKodProjekFormValues;
}

const schema = yup
  .object({
    alamat_projek_1: yup.string().notRequired(),
  })
  .required();

const FormKodProjek: React.FC<FormKodProjekProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();

  const {
    negeriOps,
    pusatTanggungjawabOps,
    daerahOps,
    bandarOps,
    parlimenOps,
    dunOps,
    mukimOps,
    seksyenOps,
    kampungOps,
    stesenOps,
    statusProjekOps,
  } = useOptions();
  const { editUsahawan, isEditing } = useEditUsahawan();
  const { jenisBantuanData, isLoading: jenisBantuanLoading } =
    useJenisBantuanDataSettings();
  const { kategoriProjekData, isLoading: kategoriProjekLoading } =
    useKategoriProjekDataSettings();
  const { subKategoriProjekData, isLoading: subKategoriProjekLoading } =
    useSubKategoriProjekDataSettings();
  const { namaProjekData, isLoading: namaProjekLoading } =
    useNamaProjekDataSettings();
  const { teknologiProjekData, isLoading: teknologiProjekLoading } =
    useTeknologiProjekDataSettings();
  const { jenisProjekData, isLoading: jenisProjekLoading } =
    useJenisProjekDataSettings();
  const { kodKumpulanProjekData, isLoading: kodKumpulanProjekLoading } =
    useKodKumpulanProjekDataSettings();
  const { kategoriPelaksanaData, isLoading: kategoriPelaksanaLoading } =
    useKategoriPelaksanaDataSettings();
  const { kaedahPelaksanaData, isLoading: kaedahPelaksanaLoading } =
    useKaedahPelaksanaDataSettings();
  const { kumpulanPelaksanaData, isLoading: kumpulanPelaksanaLoading } =
    useKumpulanPelaksanaDataSettings();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IKodProjekFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
      tarikh_mula_projek: formatDate(initialData?.tarikh_mula_projek),
      tarikh_mula_berhasil: formatDate(initialData?.tarikh_mula_berhasil),
    },
  });

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        projek_bpks: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  if (jenisBantuanLoading || kategoriProjekLoading || subKategoriProjekLoading || namaProjekLoading || teknologiProjekLoading || jenisProjekLoading || kodKumpulanProjekLoading || kategoriPelaksanaLoading || kaedahPelaksanaLoading || kumpulanPelaksanaLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <SelectField
            label={"Jenis Bantuan"}
            options={jenisBantuanData?.data}
            errorMessage={errors.jenis_bantuan_bpk_id?.message}
            isInvalid={!!errors.jenis_bantuan_bpk_id}
            readOnly={action === "show"}
            {...register("jenis_bantuan_bpk_id")}
          />

          <SelectField
            label={"Kategori Projek"}
            options={kategoriProjekData?.data}
            errorMessage={errors.kategori_projek_bpk_id?.message}
            isInvalid={!!errors.kategori_projek_bpk_id}
            readOnly={action === "show"}
            {...register("kategori_projek_bpk_id")}
          />

          <SelectField
            label={"Sub Kategori Projek"}
            options={subKategoriProjekData?.data}
            errorMessage={errors.sub_kategori_projek_bpk_id?.message}
            isInvalid={!!errors.sub_kategori_projek_bpk_id}
            readOnly={action === "show"}
            {...register("sub_kategori_projek_bpk_id")}
          />

          <SelectField
            label={"Nama Projek"}
            options={namaProjekData?.data}
            errorMessage={errors.nama_projek_bpk_id?.message}
            isInvalid={!!errors.nama_projek_bpk_id}
            readOnly={action === "show"}
            {...register("nama_projek_bpk_id")}
          />

          <SelectField
            label={"Teknologi Projek"}
            options={teknologiProjekData?.data}
            errorMessage={errors.teknologi_projek_bpk_id?.message}
            isInvalid={!!errors.teknologi_projek_bpk_id}
            readOnly={action === "show"}
            {...register("teknologi_projek_bpk_id")}
          />

          <SelectField
            label={"Jenis Projek"}
            options={jenisProjekData?.data}
            errorMessage={errors.jenis_projek_bpk_id?.message}
            isInvalid={!!errors.jenis_projek_bpk_id}
            readOnly={action === "show"}
            {...register("jenis_projek_bpk_id")}
          />

          <SelectField
            label={"Kod Kumpulan Projek"}
            options={kodKumpulanProjekData?.data}
            errorMessage={errors.kod_kumpulan_projek_id?.message}
            isInvalid={!!errors.kod_kumpulan_projek_id}
            readOnly={action === "show"}
            {...register("kod_kumpulan_projek_id")}
          />

          <SelectField
            label={"Kategori Pelaksana"}
            options={kategoriPelaksanaData?.data}
            errorMessage={errors.kategori_pelaksana_id?.message}
            isInvalid={!!errors.kategori_pelaksana_id}
            readOnly={action === "show"}
            {...register("kategori_pelaksana_id")}
          />

          <SelectField
            label={"Kaedah Pelaksana"}
            options={kaedahPelaksanaData?.data}
            errorMessage={errors.kaedah_pelaksana_id?.message}
            isInvalid={!!errors.kaedah_pelaksana_id}
            readOnly={action === "show"}
            {...register("kaedah_pelaksana_id")}
          />

          <SelectField
            label={"Kumpulan Pelaksana"}
            options={kumpulanPelaksanaData?.data}
            errorMessage={errors.kumpulan_pelaksana_id?.message}
            isInvalid={!!errors.kumpulan_pelaksana_id}
            readOnly={action === "show"}
            {...register("kumpulan_pelaksana_id")}
          />

          <TextField
            id="alamat_projek_1"
            label="Alamat 1"
            errorMessage={errors.alamat_projek_1?.message}
            isInvalid={!!errors.alamat_projek_1}
            readOnly={action === "show"}
            {...register("alamat_projek_1")}
          />

          <TextField
            id="alamat_projek_2"
            label="Alamat 2"
            errorMessage={errors.alamat_projek_2?.message}
            isInvalid={!!errors.alamat_projek_2}
            readOnly={action === "show"}
            {...register("alamat_projek_2")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Pusat Tanggungjawab"}
            options={pusatTanggungjawabOps}
            errorMessage={errors.pt_id?.message}
            isInvalid={!!errors.pt_id}
            readOnly={action === "show"}
            {...register("pt_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Mukim"}
            options={mukimOps}
            errorMessage={errors.mukim_id?.message}
            isInvalid={!!errors.mukim_id}
            readOnly={action === "show"}
            {...register("mukim_id")}
          />

          <SelectField
            label={"Seksyen"}
            options={seksyenOps}
            errorMessage={errors.seksyen_id?.message}
            isInvalid={!!errors.seksyen_id}
            readOnly={action === "show"}
            {...register("seksyen_id")}
          />

          <SelectField
            label={"Kampung"}
            options={kampungOps}
            errorMessage={errors.kampung_id?.message}
            isInvalid={!!errors.kampung_id}
            readOnly={action === "show"}
            {...register("kampung_id")}
          />

          <SelectField
            label={"Stesen"}
            options={stesenOps}
            errorMessage={errors.stesen_id?.message}
            isInvalid={!!errors.stesen_id}
            readOnly={action === "show"}
            {...register("stesen_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <TextField
            id="luas_asal_projek_hektar"
            label="Luas Asal Projek (Hektar)"
            errorMessage={errors.luas_asal_projek_hektar?.message}
            isInvalid={!!errors.luas_asal_projek_hektar}
            readOnly={action === "show"}
            {...register("luas_asal_projek_hektar")}
          />

          <TextField
            id="tarikh_mula_projek"
            label="Tarikh Mula Projek"
            type="date"
            errorMessage={errors.tarikh_mula_projek?.message}
            isInvalid={!!errors.tarikh_mula_projek}
            readOnly={action === "show"}
            {...register("tarikh_mula_projek")}
          />

          <TextField
            id="tarikh_mula_berhasil"
            label="Tarikh Mula Berhasil"
            type="date"
            errorMessage={errors.tarikh_mula_berhasil?.message}
            isInvalid={!!errors.tarikh_mula_berhasil}
            readOnly={action === "show"}
            {...register("tarikh_mula_berhasil")}
          />

          <TextField
            id="koordinat_projek"
            label="Koordinat Projek"
            errorMessage={errors.koordinat_projek?.message}
            isInvalid={!!errors.koordinat_projek}
            readOnly={action === "show"}
            {...register("koordinat_projek")}
          />

          <SelectField
            label={"Status Projek"}
            options={statusProjekOps}
            readOnly={action === "show"}
            errorMessage={errors.status_projek?.message}
            isInvalid={!!errors.status_projek}
            {...register("status_projek")}
          />

          <SelectField
            label={"Ketua Pelaksana"}
            options={[]}
            readOnly={action === "show"}
            {...register("ketua_pelaksana_id")}
          />

          <TextField
            id="catatan_pegawai"
            label="Catatan Pegawai"
            errorMessage={errors.catatan_pegawai?.message}
            isInvalid={!!errors.catatan_pegawai}
            readOnly={action === "show"}
            {...register("catatan_pegawai")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            isDisabled={isEditing}
          >
            Kemaskini Maklumat Pekebun Kecil
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormKodProjek;
