import React from "react";
import { Box, Text, List, Icon, Badge } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useCatalogues } from "hooks/admin/catalogue/useCatalogues";

const getStatusDescription = (status: number) => {
  switch (status) {
    case 99:
      return <Badge colorScheme="red">Draf</Badge>;
    case 2:
      return <Badge colorScheme="red">Draf</Badge>;
    case 1:
      return <Badge colorScheme="green">Disahkan</Badge>;
    case 0:
      return <Badge colorScheme="yellow">Menunggu Pengesahan</Badge>;
    default:
      return <Badge colorScheme="red">Draf</Badge>;
  }
};

const columnHelper = createColumnHelper<any>();

export default function Katalog() {
  const { isLoading, error, catalogues } = useCatalogues();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Produk</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_usahawan", {
      header: () => <Text variant="tableHeading">Nama Usahawan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kp_usahawan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_produk", {
      header: () => <Text variant="tableHeading">Nama Produk</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("is_approved", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {getStatusDescription(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("is_published", {
      header: () => <Text variant="tableHeading">Status Direktori</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() == 1 ? "Papar" : "Tidak Papar"}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/katalog/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/katalog/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={catalogues}
        title={"Senarai Katalog"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
