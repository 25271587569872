import React from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from "@chakra-ui/react";

import FormPerniagaan from "./FormPerniagaan";

interface TabPerniagaanProps {
  action: "edit" | "show";
  perniagaans?: any;
  negeriData?: any;
  daerahData?: any;
  bandarData?: any;
  dunData?: any;
  parlimenData?: any;
}

const TabPerniagaan = ({
  action,
  perniagaans = [],
  negeriData,
  daerahData,
  bandarData,
  dunData,
  parlimenData,
}: TabPerniagaanProps) => {
  return (
    <Box px="25px">
      <Accordion variant="main" allowToggle>
        {perniagaans.length === 0 && <Center py={20}>Tiada Maklumat</Center>}

        {perniagaans.map((perniagaan: any, index: number) => (
          <AccordionItem key={`${perniagaan.id}-${index}`}>
            <h2>
              <AccordionButton bg={"gray.100"}>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"semibold"}
                >
                  {perniagaan.nama_perniagaan}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              <FormPerniagaan
                action={action}
                initialData={perniagaan}
                negeriData={negeriData}
                daerahData={daerahData}
                bandarData={bandarData}
                dunData={dunData}
                parlimenData={parlimenData}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default TabPerniagaan;
