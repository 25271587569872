import React from 'react'
import FormUsahawan from './FormUsahawan'

interface TabUsahawanProps {
  action: "edit" | "show";
  usahawans?: any;
}

const TabUsahawan = ({
  action,
  usahawans,
}: TabUsahawanProps) => {
  return (
    <FormUsahawan
      action={action}
      initialData={usahawans}
    />
  );
};

export default TabUsahawan;

