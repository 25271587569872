import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import Resource from "components/resource/Resource";
import Sorting from "./components/Sorting";

import { useRoles } from "hooks/setting/role/useRoles";
import ResourceTwo from "components/resourceTwo/ResourceTwo";

const columnHelper = createColumnHelper<any>();

export default function Role() {
  const { isLoading, error, roles } = useRoles();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID Peranan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("name", {
      header: () => <Text variant="tableHeading">Nama Peranan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.tahap_kebenaran, {
      id: "tahap_kebenaran",
      header: () => <Text variant="tableHeading">Tahap Kebenaran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() === 1
            ? "Negeri"
            : info.getValue() === 2
            ? "Pusat Tanggungjawab"
            : "Daerah"}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.negeri_kebenaran, {
      id: "negeri_kebenaran",
      header: () => <Text variant="tableHeading">Negeri Kebenaran</Text>,
      cell: (info) => (
        <Box>
          {info.getValue() ? (
            info
              .getValue()
              .split(",")
              .map((item: string, index: number) => (
                <Text key={item} fontSize="sm" variant="secondary">
                  {item}
                </Text>
              ))
          ) : (
            <Text fontSize="sm" variant="secondary">
              Semua
            </Text>
          )}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.pt_kebenaran, {
      id: "pt_kebenaran",
      header: () => <Text variant="tableHeading">PT Kebenaran</Text>,
      cell: (info) => (
        <Box>
          {info.getValue() ? (
            info
              .getValue()
              .split(",")
              .map((item: string, index: number) => (
                <Text key={item} fontSize="sm" variant="secondary">
                  {item}
                </Text>
              ))
          ) : (
            <Text fontSize="sm" variant="secondary">
              Semua
            </Text>
          )}
        </Box>
      ),
    }),
    columnHelper.accessor((row) => row.daerah_kebenaran, {
      id: "daerah_kebenaran",
      header: () => <Text variant="tableHeading">Daerah Kebenaran</Text>,
      cell: (info) => (
        <Box>
          {info.getValue() ? (
            info
              .getValue()
              .split(",")
              .map((item: string, index: number) => (
                <Text key={item} fontSize="sm" variant="secondary">
                  {item}
                </Text>
              ))
          ) : (
            <Text fontSize="sm" variant="secondary">
              Semua
            </Text>
          )}
        </Box>
      ),
    }),
    columnHelper.accessor("count", {
      header: () => <Text variant="tableHeading">Bilangan Pengguna</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink
            to={`/admin/settings/roles/kemaskini/${info.row.original.id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/settings/roles/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={roles}
        title={"Senarai Peranan"}
        createTitle="Daftar Peranan"
        onCreate={() => history.push("/admin/settings/roles/baru")}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
