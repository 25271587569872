import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { IPassword } from "entities/setting/password";
import { resetUsahawanPassword } from "services/setting/passwordService";

export function useResetUsahawanPassword() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const { mutate: resetCurrentUsahawanPassword, isLoading: isResetting } = useMutation({
    mutationFn: ({newPassword}: {newPassword: IPassword}) => resetUsahawanPassword(newPassword),
    onSuccess: () => {
      toast({
        title: "Usahawan password successfully edited",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["usahawanPassword"] });
      history.push("/admin/usahawan");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  return { isResetting, resetCurrentUsahawanPassword };
}