import React from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";

import { useBuletins } from "hooks/admin/buletin/useBuletins";
import { IBuletinTable } from "entities/admin/buletin";
import { formatDate } from "utils/helpers";

const columnHelper = createColumnHelper<IBuletinTable>();

export default function Buletin() {
  const { isLoading, error, buletins } = useBuletins();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tajuk", {
      header: () => <Text variant="tableHeading">Tajuk</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_mula", {
      id: "tarikh_mula",
      header: () => <Text variant="tableHeading">Tarikh Mula Papar</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() ? formatDate(info.getValue(), "dd-MM-yyyy") : null}
        </Text>
      ),
    }),
    columnHelper.accessor("tarikh_akhir", {
      id: "tarikh_akhir",
      header: () => <Text variant="tableHeading">Tarikh Tamat Papar</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() ? formatDate(info.getValue(), "dd-MM-yyyy") : null}
        </Text>
      ),
    }),
    columnHelper.accessor("is_publish", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue() === 0 ? "Draf" : "Diterbitkan"}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/buletin/kemaskini/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink to={`/admin/buletin/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={buletins}
        title={"Senarai Buletin"}
        createTitle="Tambah Buletin"
        onCreate={() => history.push("/admin/buletin/baru")}
        allowPagination
      />
    </Box>
  );
}
